import moment from 'moment'
import { ajuste2 } from './ajuste'
import { Months } from './months'

export const newFormatData = (data) => {
  const now = moment(new Date())
  const past = moment(data)
  let str
  const diff = moment.duration(now.diff(past))
  if (diff.asSeconds() >= 0) {
    str = `${ajuste2(diff.asSeconds(), 0)} s`
  }
  if (diff.asMinutes() >= 1) {
    str = `${ajuste2(diff.asMinutes(), 0)} min`
  }
  if (diff.asHours() >= 1) {
    str = `${ajuste2(diff.asHours(), 0)} h`
  }
  if (diff.asDays() >= 1) {
    str = `${ajuste2(diff.asDays(), 0)} d`
  }
  if (diff.asDays() > 15) {
    const day = past.date()
    const month = past.month()
    str = `${day} ${Months[month]}`
  }
  return str
}
