import React from 'react'
import { MdChevronRight } from 'react-icons/md'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { checkStatusCode } from '../../utils'

import './styles.css'
import './darkmode.css'

function NewDepoiment({
  formatName,
  data,
  setChange,
  memberId,
  api,
  darkmode
}) {
  const dispatch = useDispatch()

  const [disabled, setDisabled] = React.useState(false)
  const [porcentage, setPorcentage] = React.useState(0)
  const [depoiment, setDepoiment] = React.useState('')
  const [showProgress, setShowProgress] = React.useState(false)

  const checkDeopimentLength = (value) => {
    value.length < 250 ? setDisabled(false) : setDisabled(true)
  }

  const handleSetDepoiment = (value) => {
    const porcent = (value.length / 250) * 100
    setDepoiment(value)
    setPorcentage(porcent)
    checkDeopimentLength(value)
  }

  const handleDepoiment = async (id) => {
    await api
      .post('/depoiments', { userId: id, memberId, depoiment })
      .then((res) => {
        setDepoiment('')
        setChange(true)
        setChange(false)
        setPorcentage(0)
      })
      .catch((reject) => checkStatusCode(reject, dispatch))
  }

  const colorBlue = darkmode ? '#40C9F3' : '00B7EF'
  const colorRed = darkmode ? '#FF796B' : '#E74C3C'

  return (
    <div className="user-depoiments">
      <span>Depoimentos</span>
      <div className="depoiments-text-area">
        <label>{`Deixe aqui seu depoimento para ${
          data.name ? formatName(data.name) : ''
        }:`}</label>
        <textarea
          className="text-depoiments"
          value={depoiment}
          onChange={(e) => handleSetDepoiment(e.target.value)}
          maxLength={250}
          onFocus={() => setShowProgress(true)}
          onBlur={() => setShowProgress(false)}
        />
        <div className="button-and-progress">
          <div className="progress-circular">
            {showProgress && (
              <CircularProgressbar
                value={porcentage}
                styles={buildStyles({
                  pathColor: porcentage === 100 ? colorRed : colorBlue
                })}
              />
            )}
          </div>

          <button
            className="button-primary"
            type="button"
            onClick={() => handleDepoiment(data.ownerId)}
            disabled={disabled}
          >
            Enviar
            <MdChevronRight size={20} />
          </button>
        </div>
      </div>
    </div>
  )
}

NewDepoiment.defaultProps = {
  data: {}
}

NewDepoiment.propTypes = {
  formatName: PropTypes.func.isRequired,
  data: PropTypes.oneOfType([PropTypes.object]),
  setChange: PropTypes.func.isRequired,
  memberId: PropTypes.string.isRequired,
  api: PropTypes.func.isRequired,
  darkmode: PropTypes.bool.isRequired
}

export default NewDepoiment
