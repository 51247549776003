export const banks = [
  { code: '001', value: '001-Banco do Brasil S.A.' },
  { code: '003', value: '003-Banco da Amazônia S.A.' },
  { code: '004', value: '004-Banco do Nordeste do Brasil S.A.' },
  {
    code: '007',
    value: '007-Banco Nacional de Desenvolvimento Econômico e Social BNDES'
  },
  { code: '010', value: '010-Credicoamo Crédito Rural Cooperativa' },
  {
    code: '011',
    value: '011-Credit Suisse Hedging-Griffo Corretora de Valores S.A.'
  },
  { code: '012', value: '012-Banco Inbursa S.A.' },
  { code: '014', value: '014-Natixis Brasil S.A. Banco Múltiplo' },
  {
    code: '015',
    value:
      '015-UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A.'
  },
  {
    code: '016',
    value:
      '016-Coop de Créd. Mútuo dos Despachantes de Trânsito de SC e Rio Grande do Sul'
  },
  { code: '017', value: '017-BNY Mellon Banco S.A.' },
  { code: '018', value: '018-Banco Tricury S.A.' },
  {
    code: '021',
    value: '021-Banestes S.A. Banco do Estado do Espírito Santo'
  },
  { code: '024', value: '024-Banco Bandepe S.A.' },
  { code: '025', value: '025-Banco Alfa S.A.' },
  { code: '029', value: '029-Banco Itaú Consignado S.A.' },
  { code: '033', value: '033-Banco Santander (Brasil) S. A.' },
  { code: '036', value: '036-Banco Bradesco BBI S.A.' },
  { code: '037', value: '037-Banco do Estado do Pará S.A.' },
  { code: '040', value: '040-Banco Cargill S.A.' },
  { code: '041', value: '041-Banco do Estado do Rio Grande do Sul S.A.' },
  { code: '047', value: '047-Banco do Estado de Sergipe S.A.' },
  { code: '060', value: '060-Confidence Corretora de Câmbio S.A.' },
  { code: '062', value: '062-Hipercard Banco Múltiplo S.A.' },
  { code: '063', value: '063-Banco Bradescard S.A.' },
  {
    code: '064',
    value: '064-Goldman Sachs do Brasil  Banco Múltiplo S. A.'
  },
  { code: '065', value: '065-Banco AndBank (Brasil) S.A.' },
  { code: '066', value: '066-Banco Morgan Stanley S. A.' },
  { code: '069', value: '069-Banco Crefisa S.A.' },
  { code: '070', value: '070-Banco de Brasília S.A.' },
  { code: '074', value: '074-Banco J. Safra S.A.' },
  { code: '075', value: '075-Banco ABN Amro S.A.' },
  { code: '076', value: '076-Banco KDB do Brasil S.A.' },
  { code: '077', value: '077-Banco Inter S.A.' },
  {
    code: '078',
    value: '078-Haitong Banco de Investimento do Brasil S.A.'
  },
  { code: '079', value: '079-Banco Original do Agronegócio S.A.' },
  { code: '080', value: '080-BT Corretora de Câmbio Ltda.' },
  { code: '081', value: '081-BBN Banco Brasileiro de Negocios S.A.' },
  { code: '082', value: '082-Banco Topazio S.A.' },
  { code: '083', value: '083-Banco da China Brasil S.A.' },
  {
    code: '084',
    value: '084-Uniprime Norte do Paraná - Cooperativa de Crédito Ltda.'
  },
  {
    code: '085',
    value: '085-Cooperativa Central de Crédito Urbano - Cecred'
  },
  {
    code: '089',
    value: '089-Cooperativa de Crédito Rural da Região da Mogiana'
  },
  {
    code: '091',
    value:
      '091-Central de Cooperativas de Economia e Crédito Mútuo do Est RS - Unicred'
  },
  {
    code: '092',
    value: '092-BRK S.A. Crédito, Financiamento e Investimento'
  },
  {
    code: '093',
    value:
      '093-Pólocred Sociedade de Crédito ao Microempreendedor e à Empresa de Pequeno Porte'
  },
  { code: '094', value: '094-Banco Finaxis S.A.' },
  { code: '095', value: '095-Banco Confidence de Câmbio S.A.' },
  {
    code: '096',
    value: '096-Banco BMFBovespa de Serviços de Liquidação e Custódia S/A'
  },
  {
    code: '097',
    value:
      '097-Cooperativa Central de Crédito Noroeste Brasileiro Ltda - CentralCredi'
  },
  { code: '098', value: '098-Credialiança Cooperativa de Crédito Rural' },
  {
    code: '099',
    value:
      '099-Uniprime Central – Central Interestadual de Cooperativas de Crédito Ltda.'
  },
  { code: '100', value: '100-Planner Corretora de Valores S.A.' },
  {
    code: '101',
    value: '101-Renascença Distribuidora de Títulos e Valores Mobiliários Ltda.'
  },
  {
    code: '102',
    value:
      '102-XP Investimentos Corretora de Câmbio Títulos e Valores Mobiliários S.A.'
  },
  { code: '104', value: '104-Caixa Econômica Federal' },
  {
    code: '105',
    value: '105-Lecca Crédito, Financiamento e Investimento S/A'
  },
  { code: '107', value: '107-Banco Bocom BBM S.A.' },
  {
    code: '108',
    value: '108-PortoCred S.A. Crédito, Financiamento e Investimento'
  },
  {
    code: '111',
    value:
      '111-Oliveira Trust Distribuidora de Títulos e Valores Mobiliários S.A.'
  },
  {
    code: '113',
    value: '113-Magliano S.A. Corretora de Cambio e Valores Mobiliarios'
  },
  {
    code: '114',
    value:
      '114-Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP'
  },
  { code: '117', value: '117-Advanced Corretora de Câmbio Ltda.' },
  {
    code: '118',
    value: '118-Standard Chartered Bank (Brasil) S.A. Banco de Investimento'
  },
  { code: '119', value: '119-Banco Western Union do Brasil S.A.' },
  { code: '120', value: '120-Banco Rodobens SA' },
  { code: '121', value: '121-Banco Agibank S.A.' },
  { code: '122', value: '122-Banco Bradesco BERJ S.A.' },
  { code: '124', value: '124-Banco Woori Bank do Brasil S.A.' },
  { code: '125', value: '125-Brasil Plural S.A. Banco Múltiplo' },
  { code: '126', value: '126-BR Partners Banco de Investimento S.A.' },
  { code: '127', value: '127-Codepe Corretora de Valores e Câmbio S.A.' },
  { code: '128', value: '128-MS Bank S.A. Banco de Câmbio' },
  { code: '129', value: '129-UBS Brasil Banco de Investimento S.A.' },
  {
    code: '130',
    value: '130-Caruana S.A. Sociedade de Crédito, Financiamento e Investimento'
  },
  {
    code: '131',
    value: '131-Tullett Prebon Brasil Corretora de Valores e Câmbio Ltda.'
  },
  { code: '132', value: '132-ICBC do Brasil Banco Múltiplo S.A.' },
  {
    code: '133',
    value:
      '133-Confederação Nacional das Cooperativas Centrais de Crédito e Economia Familiar e'
  },
  {
    code: '134',
    value:
      '134-BGC Liquidez Distribuidora de Títulos e Valores Mobiliários Ltda.'
  },
  {
    code: '135',
    value: '135-Gradual Corretora de Câmbio, Títulos e Valores Mobiliários S.A.'
  },
  {
    code: '136',
    value:
      '136-Confederação Nacional das Cooperativas Centrais Unicred Ltda – Unicred do Brasil'
  },
  { code: '137', value: '137-Multimoney Corretora de Câmbio Ltda' },
  { code: '138', value: '138-Get Money Corretora de Câmbio S.A.' },
  {
    code: '139',
    value: '139-Intesa Sanpaolo Brasil S.A. - Banco Múltiplo'
  },
  { code: '140', value: '140-Easynvest - Título Corretora de Valores SA' },
  { code: '142', value: '142-Broker Brasil Corretora de Câmbio Ltda.' },
  { code: '143', value: '143-Treviso Corretora de Câmbio S.A.' },
  { code: '144', value: '144-Bexs Banco de Câmbio S.A.' },
  {
    code: '145',
    value: '145-Levycam - Corretora de Câmbio e Valores Ltda.'
  },
  { code: '146', value: '146-Guitta Corretora de Câmbio Ltda.' },
  {
    code: '149',
    value: '149-Facta Financeira S.A. - Crédito Financiamento e Investimento'
  },
  {
    code: '157',
    value: '157-ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda.'
  },
  {
    code: '159',
    value: '159-Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor'
  },
  { code: '163', value: '163-Commerzbank Brasil S.A. - Banco Múltiplo' },
  { code: '169', value: '169-Banco Olé Bonsucesso Consignado S.A.' },
  { code: '172', value: '172-Albatross Corretora de Câmbio e Valores S.A' },
  {
    code: '173',
    value: '173-BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.'
  },
  {
    code: '174',
    value:
      '174-Pernambucanas Financiadora S.A. Crédito, Financiamento e Investimento'
  },
  {
    code: '177',
    value: '177-Guide Investimentos S.A. Corretora de Valores'
  },
  {
    code: '180',
    value:
      '180-CM Capital Markets Corretora de Câmbio, Títulos e Valores Mobiliários Ltda.'
  },
  {
    code: '182',
    value:
      '182-Dacasa Financeira S/A - Sociedade de Crédito, Financiamento e Investimento'
  },
  {
    code: '183',
    value: '183-Socred S.A. - Sociedade de Crédito ao Microempreendedor'
  },
  { code: '184', value: '184-Banco Itaú BBA S.A.' },
  {
    code: '188',
    value: '188-Ativa Investimentos S.A. Corretora de Títulos Câmbio e Valores'
  },
  {
    code: '189',
    value: '189-HS Financeira S/A Crédito, Financiamento e Investimentos'
  },
  {
    code: '190',
    value:
      '190-Cooperativa de Economia e Crédito Mútuo dos Servidores Públicos Estaduais do Rio'
  },
  {
    code: '191',
    value: '191-Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.'
  },
  {
    code: '194',
    value: '194-Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda.'
  },
  { code: '196', value: '196-Fair Corretora de Câmbio S.A.' },
  { code: '197', value: '197-Stone Pagamentos S.A.' },
  { code: '204', value: '204-Banco Bradesco Cartões S.A.' },
  { code: '208', value: '208-Banco BTG Pactual S.A.' },
  { code: '212', value: '212-Banco Original S.A.' },
  { code: '213', value: '213-Banco Arbi S.A.' },
  { code: '217', value: '217-Banco John Deere S.A.' },
  { code: '218', value: '218-Banco BS2 S.A.' },
  { code: '222', value: '222-Banco Credit Agrícole Brasil S.A.' },
  { code: '224', value: '224-Banco Fibra S.A.' },
  { code: '233', value: '233-Banco Cifra S.A.' },
  { code: '237', value: '237-Banco Bradesco S.A.' },
  { code: '241', value: '241-Banco Clássico S.A.' },
  { code: '243', value: '243-Banco Máxima S.A.' },
  { code: '246', value: '246-Banco ABC Brasil S.A.' },
  { code: '249', value: '249-Banco Investcred Unibanco S.A.' },
  { code: '250', value: '250-BCV - Banco de Crédito e Varejo S/A' },
  { code: '253', value: '253-Bexs Corretora de Câmbio S/A' },
  { code: '254', value: '254-Parana Banco S. A.' },
  { code: '260', value: '260-Nu Pagamentos S.A.' },
  { code: '265', value: '265-Banco Fator S.A.' },
  { code: '266', value: '266-Banco Cédula S.A.' },
  { code: '268', value: '268-Barigui Companhia Hipotecária' },
  { code: '269', value: '269-HSBC Brasil S.A. Banco de Investimento' },
  {
    code: '271',
    value: '271-IB Corretora de Câmbio, Títulos e Valores Mobiliários Ltda.'
  },
  { code: '300', value: '300-Banco de la Nacion Argentina' },
  { code: '318', value: '318-Banco BMG S.A.' },
  {
    code: '320',
    value: '320-China Construction Bank (Brasil) Banco Múltiplo S/A'
  },
  { code: '341', value: '341-Itaú Unibanco  S.A.' },
  { code: '366', value: '366-Banco Société Générale Brasil S.A.' },
  { code: '370', value: '370-Banco Mizuho do Brasil S.A.' },
  { code: '376', value: '376-Banco J. P. Morgan S. A.' },
  { code: '389', value: '389-Banco Mercantil do Brasil S.A.' },
  { code: '394', value: '394-Banco Bradesco Financiamentos S.A.' },
  { code: '399', value: '399-Kirton Bank S.A. - Banco Múltiplo' },
  { code: '412', value: '412-Banco Capital S. A.' },
  { code: '422', value: '422-Banco Safra S.A.' },
  { code: '456', value: '456-Banco MUFG Brasil S.A.' },
  { code: '464', value: '464-Banco Sumitomo Mitsui Brasileiro S.A.' },
  { code: '473', value: '473-Banco Caixa Geral - Brasil S.A.' },
  { code: '477', value: '477-Citibank N.A.' },
  { code: '479', value: '479-Banco ItauBank S.A.' },
  { code: '487', value: '487-Deutsche Bank S.A. - Banco Alemão' },
  { code: '488', value: '488-JPMorgan Chase Bank, National Association' },
  { code: '492', value: '492-ING Bank N.V.' },
  { code: '494', value: '494-Banco de La Republica Oriental del Uruguay' },
  { code: '495', value: '495-Banco de La Provincia de Buenos Aires' },
  { code: '505', value: '505-Banco Credit Suisse (Brasil) S.A.' },
  {
    code: '545',
    value: '545-Senso Corretora de Câmbio e Valores Mobiliários S.A.'
  },
  { code: '600', value: '600-Banco Luso Brasileiro S.A.' },
  { code: '604', value: '604-Banco Industrial do Brasil S.A.' },
  { code: '610', value: '610-Banco VR S.A.' },
  { code: '611', value: '611-Banco Paulista S.A.' },
  { code: '612', value: '612-Banco Guanabara S.A.' },
  { code: '613', value: '613-Omni Banco S.A.' },
  { code: '623', value: '623-Banco Pan S.A.' },
  { code: '626', value: '626-Banco Ficsa S. A.' },
  { code: '630', value: '630-Banco Intercap S.A.' },
  { code: '633', value: '633-Banco Rendimento S.A.' },
  { code: '634', value: '634-Banco Triângulo S.A.' },
  { code: '637', value: '637-Banco Sofisa S. A.' },
  { code: '641', value: '641-Banco Alvorada S.A.' },
  { code: '643', value: '643-Banco Pine S.A.' },
  { code: '652', value: '652-Itaú Unibanco Holding S.A.' },
  { code: '653', value: '653-Banco Indusval S. A.' },
  { code: '654', value: '654-Banco A. J. Renner S.A.' },
  { code: '655', value: '655-Banco Votorantim S.A.' },
  { code: '707', value: '707-Banco Daycoval S.A.' },
  { code: '712', value: '712-Banco Ourinvest S.A.' },
  {
    code: '719',
    value: '719-Banif - Bco Internacional do Funchal (Brasil) S.A.'
  },
  { code: '735', value: '735-Banco Neon S.A.' },
  { code: '739', value: '739-Banco Cetelem S.A.' },
  { code: '741', value: '741-Banco Ribeirão Preto S.A.' },
  { code: '743', value: '743-Banco Semear S.A.' },
  { code: '745', value: '745-Banco Citibank S.A.' },
  { code: '746', value: '746-Banco Modal S.A.' },
  { code: '747', value: '747-Banco Rabobank International Brasil S.A.' },
  { code: '748', value: '748-Banco Cooperativo Sicredi S. A.' },
  { code: '751', value: '751-Scotiabank Brasil S.A. Banco Múltiplo' },
  { code: '752', value: '752-Banco BNP Paribas Brasil S.A.' },
  {
    code: '753',
    value: '753-Novo Banco Continental S.A. - Banco Múltiplo'
  },
  { code: '754', value: '754-Banco Sistema S.A.' },
  {
    code: '755',
    value: '755-Bank of America Merrill Lynch Banco Múltiplo S.A.'
  },
  { code: '756', value: '756-Banco Cooperativo do Brasil S/A - Bancoob' },
  { code: '757', value: '757-Banco Keb Hana do Brasil S. A.' }
]
