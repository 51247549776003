/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { MdAddAlarm, MdAlarmOff } from 'react-icons/md'

import './style.css'
import './darkmode.css'

function ButtonCheckin({ working, ...rest }) {
  return (
    <button
      type="button"
      className={
        working ? 'button-checkin btn-checkout' : 'button-checkin btn-checkin'
      }
      {...rest}
    >
      {working ? (
        <MdAlarmOff size={20} style={{ marginRight: '5px' }} />
      ) : (
        <MdAddAlarm size={24} style={{ marginRight: '5px' }} />
      )}
      <span className="btn-check">
        {working ? 'Registrar check-out' : 'Registrar check-in'}
      </span>
    </button>
  )
}

export default ButtonCheckin

ButtonCheckin.propTypes = {
  working: PropTypes.bool.isRequired
}
