/* eslint-disable import/export */
import asyncSearch from './AsyncSearch'
import blocoHours from './BlocoHours'
import buttonCheckIn from './ButtonCheckin'
import buttonPDF from './ButtonsPDF'
import buttonsTopSelect from './ButtonsTopSelect'
import cardTeam from './CardTeam'
import checkItem from './CheckItem'
import crop from './Crop'
import depoiments from './Depoiments'
import dropDown from './DropDown'
import emptyWindown from './EmptyWindown'
import errorMessages from './ErrorMessages'
import feedbackButton from './FeebackButton'
import galeryCard from './GaleryCard'
import header from './Header'
import headerMenu from './HeaderMenu'
import inputAutoComplete from './InputAutoComplete'
import inputFiles from './InputFiles'
import listCourses from './ListCourses'
import listFiles from './ListFiles'
import listFeedbacks from './ListFeedbacks'
import modalAlert from './ModalAlert'
import modalCancel from './ModalCancelRegister'
import modalGalery from './ModalGalery'
import moreInfos from './MoreInfos'
import navHome from './NavHome'
import navProfile from './NavProfile'
import newDepoiment from './NewDepoiment'
import newMember from './NewMember'
import pagination from './Pagination'
import paginationBtn from './PaginationBTN'
import pdfComponent from './PDFComponent'
import pdfThumb from './PDFThumb'
import poppover from './Popover'
import rankingCard from './RankingCard'
import rankingComponent from './RankingComponent'
import searchInput from './SearchInput'
import sendTerms from './SendTerms'
import stamps from './Stamps'
import stampsModal from './StampsModal'
import styledBasicInput from './StyledBasicInput'
import styledInput from './StyledInput'
import styledRadio from './StyledRadio'
import styledSelect from './StyledSelect'
import styledSelect2 from './StyledSelect2'
import styledCheckBox from './StyledCheckBox'
import subNav from './SubNav'
import tags from './Tags'
import team from './Team'
import teamList from './TeamsList'
import timer from './Timer'
import toastContainer from './ToastContainer'
import userInfos from './UserInfos'
import userInfosModal from './UserInfosModal'
import userTrajectory from './UserTrajectory'
import videoThumb from './VideoThumbnail'
import viewDOC from './ViewDOC'
import workingHours from './WorkingHours'
import newModal from './NewModal'

// import oldHours from './OldHoursItem'
import oldAccordionHours from './OldAccordionHours'

export const AsyncSearch = asyncSearch

export const BlocoHours = blocoHours

export const ButtonCheckin = buttonCheckIn
export const ButtonsPDF = buttonPDF
export const ButtonsTopSelect = buttonsTopSelect
export const CardTeam = cardTeam
export const CheckItem = checkItem
export const Crop = crop
export const Depoiments = depoiments
export const DropDown = dropDown
export const EmptyWindown = emptyWindown
export const ErrorMessages = errorMessages
export const FeedbackButton = feedbackButton
export const GaleryCard = galeryCard
export const Header = header
export const HeaderMenu = headerMenu
export const AutoComplete = inputAutoComplete
export const InputFiles = inputFiles
export const ListCourses = listCourses
export const ListFiles = listFiles
export const ListFeedbacks = listFeedbacks
export const ModalAlert = modalAlert
export const ModalCancelRegister = modalCancel
export const ModalGalery = modalGalery
export const MoreInfos = moreInfos
export const NavHome = navHome
export const NavProfile = navProfile
export const NewDepoiment = newDepoiment
export const NewUser = newMember
export const Pagination = pagination
export const PaginationBTN = paginationBtn
export const PDFComponent = pdfComponent
export const PDFThumb = pdfThumb
export const Poppover = poppover
export const RankingCard = rankingCard
export const RankingComponent = rankingComponent
export const SearchInput = searchInput
export const SendTerms = sendTerms
export const Stamps = stamps
export const StampsModal = stampsModal
export const StyledBasicInput = styledBasicInput
export const StyledInput = styledInput
export const StyledRadio = styledRadio
export const StyledSelect = styledSelect
export const StyledSelect2 = styledSelect2
export const SubNav = subNav
export const Tags = tags
export const Team = team
export const TeamList = teamList
export const Timer = timer
export const ToastContainer = toastContainer
export const UserInfos = userInfos
export const UserInfosModal = userInfosModal
export const UserTrajectory = userTrajectory
export const VideoThumbnail = videoThumb
export const ViewDOC = viewDOC
// export const WorkingHours = workingHours

export const WorkingHours = oldAccordionHours

export const StyledCheckbox = styledCheckBox
export const NewModal = newModal
