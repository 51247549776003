export const bloodTypes = [
  { key: 0, value: 'Pendente', label: 'Pendente' },
  { key: 1, value: 'A+', label: 'A+' },
  { key: 2, value: 'A-', label: 'A-' },
  { key: 3, value: 'B+', label: 'B+' },
  { key: 4, value: 'B-', label: 'B-' },
  { key: 5, value: 'AB+', label: 'AB+' },
  { key: 6, value: 'AB-', label: 'AB-' },
  { key: 7, value: 'O+', label: 'O+' },
  { key: 8, value: 'O-', label: 'O-' }
]
