import { toast } from 'react-toastify'
import { api } from '../../../services'

const handleDelete = async ({ id, index, depoiments, setDepoiments }) => {
  await api.get(`/depoiments/delete/${id}`).then(async () => {
    toast.success('Depoimento excluído com sucesso!')
    const arr = [...depoiments]
    arr.splice(index, 1)
    setDepoiments(arr)
  })
}

export { handleDelete }
