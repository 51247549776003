import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MdCheck, MdNavigateBefore, MdChevronRight } from 'react-icons/md'

import {
  StyledBasicInput,
  Header,
  SubNav,
  ModalCancelRegister,
  NavProfile
} from '../../../../components'
import { PisValidator, onlyNumbers } from '../../../../utils'
import { updateUser } from '../../../../store/modules/user/actions'

import { history } from '../../../../services'

import './styles.css'

function LaborData() {
  const dispatch = useDispatch()

  const [ctps, setCtps] = React.useState('')
  const [ctpsSerie, setCtpsSerie] = React.useState('')
  const [uf, setUf] = React.useState('')
  const [pis, setPis] = React.useState('')

  const [errorPis, setErrorPis] = React.useState(false)
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const [disabled, setDisabled] = React.useState(true)

  const profile = useSelector((select) => select.user.profile)
  const darkmode = useSelector((state) => state.user.darkmode)

  const { contractingModel } = profile

  React.useEffect(() => {
    const checkEmptyInputs = () => {
      if (
        pis !== '' &&
        ctps !== '' &&
        ctpsSerie !== '' &&
        uf !== '' &&
        !errorPis
      ) {
        setDisabled(false)
      } else {
        setDisabled(true)
      }
    }
    checkEmptyInputs()
  }, [pis, ctps, ctpsSerie, uf, errorPis])

  const completeTo = async (userData) => {
    setCtps(userData.ctps)
    setCtpsSerie(userData.ctpsSerie)
    setPis(userData.pis)
    setUf(userData.uf)
  }

  React.useEffect(() => {
    let user = JSON.parse(sessionStorage.getItem('user'))
    completeTo(user || profile)
  }, [profile])

  const validatePis = () => {
    const isValid = PisValidator(pis)
    isValid ? setErrorPis(false) : setErrorPis(true)
  }

  const inputs = [
    {
      label: 'Número da CTPS',
      value: ctps,
      setValue: setCtps,
      onlyNumbers: true
    },
    {
      label: 'Série da CTPS',
      value: ctpsSerie,
      setValue: setCtpsSerie,
      onlyNumbers: true
    },
    { label: 'UF', value: uf, setValue: setUf },
    {
      label: 'PIS',
      mask: '999.99999.99-9',
      value: pis,
      setValue: setPis,
      error: errorPis,
      errorMessage: 'PIS inválido',
      function: validatePis
    }
  ]

  const generateUser = () => {
    let user = JSON.parse(sessionStorage.getItem('user'))
    user = {
      ...user,
      ctps,
      ctpsSerie,
      ufWork: uf,
      pis
    }
    sessionStorage.setItem('user', JSON.stringify(user))
  }

  const handleCancel = () => {
    setModalIsOpen(false)
    sessionStorage.removeItem('user')
    history.push('/')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await generateUser()
    if (profile.finalized) {
      const userStorage = JSON.parse(sessionStorage.getItem('user'))
      dispatch(updateUser(userStorage))
    } else {
      history.push('/profile/docs')
    }
  }

  const buttons = [
    {
      label: 'Cancelar',
      class: 'button-secondary',
      type: 'button',
      function: () => setModalIsOpen(true),
      containIcon: false
    },
    {
      label: profile.finalized ? 'Enviar' : 'Avançar',
      class: 'button-primary',
      type: 'submit',
      iconFinalized: profile.finalized,
      function: handleSubmit,
      disabled,
      containIcon: true
    }
  ]

  return (
    <div className={`${darkmode && 'darkmode'} global-container`}>
      <Header />

      <ModalCancelRegister
        open={modalIsOpen}
        actionClose={() => setModalIsOpen(false)}
        action={handleCancel}
        typeCancel={profile.finalized ? 'as edições' : 'o cadastro'}
      />
      <div className="global-content global-content-grey">
        {profile.finalized ? (
          <span className="title">MEU PERFIL</span>
        ) : (
          <span className="title">CADASTRO</span>
        )}
        <hr className="title-bar" />
        <NavProfile active={1} generateUser={generateUser} />
        <SubNav
          complete={1}
          select={2}
          generateUser={generateUser}
          contractingModel={contractingModel}
        />
        <div className="form-labor">
          {inputs.map((item) => (
            <StyledBasicInput
              label={item.label}
              mask={item.mask || ''}
              value={item.value}
              onChange={(e) => {
                const { value } = e.target
                if (item.onlyNumbers) {
                  item.setValue(onlyNumbers(value))
                } else {
                  item.setValue(value)
                }
              }}
              error={item.error || null}
              errorMesage={item.errorMessage || null}
              onBlur={item.function || null}
            />
          ))}
        </div>

        <div className="form-bottom">
          <button
            type="button"
            className="button-return"
            onClick={() => history.push('/profile/1')}
          >
            <MdNavigateBefore size={20} />
            Voltar
          </button>
          <div className="btn-group">
            {buttons.map((item) => (
              <button
                className={item.class}
                type={item.type}
                onClick={item.function || null}
                disabled={item.disabled}
              >
                {item.label}
                {item.containIcon ? (
                  <>
                    {item.iconFinalized ? (
                      <MdCheck
                        size={20}
                        color={darkmode ? '#222222' : '#fff'}
                      />
                    ) : (
                      <MdChevronRight
                        color={darkmode ? '#222222' : '#fff'}
                        size={20}
                      />
                    )}
                  </>
                ) : null}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LaborData
