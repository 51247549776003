import React from 'react'
import { useSelector } from 'react-redux'
// import { Tabs } from 'antd'

import { Header, NavHome, Team } from '../../components'

function CentralTeam() {
  const darkmode = useSelector((state) => state.user.darkmode)
  // const { TabPane } = Tabs
  return (
    <div className={`${darkmode && 'darkmode'} global-container`}>
      <Header />
      <div className="global-content content-white content-fixed-size content-team">
        <div className="nav-home-padding">
          <NavHome active={4} />
        </div>

        <Team />
      </div>
    </div>
  )
}

export default CentralTeam
