import { api } from '../../../services'

const getPlatformsAndAreas = async ({ setAreas, setPlatforms }) => {
  await api.get('/areaCourse').then((response) => {
    setAreas(response.data)
  })

  await api.get('/platforms').then((response) => {
    setPlatforms(response.data)
  })
}

export { getPlatformsAndAreas }
