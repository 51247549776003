/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'
import Modal from 'react-modal'
import { useDispatch } from 'react-redux'
import {
  Header,
  NavHome,
  UserInfos,
  WorkingHours,
  StyledSelect,
  ModalAlert,
  NewModal
} from '../../components'
import { ConfirmationEditWork } from '../../components/ModalsItems'

import {
  getWorkingHoursReq,
  checkOut,
  inVocationFunc,
  selectDate
} from '../../store/modules/user/actions'
import { history } from '../../services'
import { checkToken } from '../../utils'
import { dates } from './Vars'
import { returnMessages, returnModalBtn } from './returnMessages'
import { loadCorrections, handleMarkNewsAsRead, check } from './functions'
import { dateIn, returnCheckData } from './utils'
import useEventListener from '../../hooks/useEventListener'
import useGlobal from '../../hooks/useGlobal'

import { checkUserFiveMinutesOff } from '../../functions'

import './style.css'
import './darkmode.css'

export default function Home() {
  const [modalisOpen, setModalIsOpen] = useState(false)
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false)
  const [correctionsResult, setCorrectionsResult] = useState([])

  const [modalMessage, setModalMessage] = useState('')
  const [modalType, setModalType] = useState('')
  const [errorModal, setErrorModal] = useState(false)
  const [doubleButtons, setDoubleButtons] = useState(false)
  const [messageBtn, setMessageBtn] = useState('')
  const [count, setCount] = useState(0)

  const [_global] = useGlobal()
  const dispatch = useDispatch()

  const {
    darkmode,
    profile,
    id,
    isLoggedIn,
    hourEntry,
    profileWorking,
    inVocation,
    _selectDate
  } = _global

  const dataReq = {
    member: id,
    _ApplicationId: process.env.REACT_APP_APLICATION_ID
  }

  useEffect(() => {
    dispatch(selectDate(dateIn))
  }, [])

  const dispatchFN = (_date) => {
    dispatch(getWorkingHoursReq(_date, id))
    dispatch(selectDate(_date))
  }

  const handler = useCallback(() => {
    if (_selectDate === dateIn) {
      dispatchFN(_selectDate)
      setCount(1)
    }
  }, [count])

  useEventListener('storage', handler, count, setCount)

  useEffect(() => {
    if (isLoggedIn) {
      checkToken(dispatch)
    }
  }, [isLoggedIn])

  useEffect(() => {
    const dispathWorking = () => {
      dispatch(selectDate(dateIn))
      dispatchFN(dateIn)
    }
    dispathWorking()
  }, [])

  useEffect(() => {
    const redirectUser = () => {
      if (!profile.finalized) {
        history.push('/profile/1')
      }
    }
    redirectUser()
  }, [profile])

  /**                                                    *
   * Checks if the user is connected to the point system *
   *                                                     */
  useEffect(() => {
    checkUserFiveMinutesOff({
      profileWorking,
      inVocation,
      hourEntry,
      setModalIsOpen
    })
  }, [profileWorking.working, hourEntry, inVocation])

  useEffect(() => {
    loadCorrections({
      member: id,
      dispatch,
      setCorrectionsResult,
      setModalConfirmOpen
    })
  }, [])

  const dateSelect = (e) => {
    const { value } = e.target
    dispatch(selectDate(value))
    dispatchFN(value)
  }

  const openModal = (type, code) => {
    const message = returnMessages(code)
    const messageCheck = returnModalBtn(code)
    setModalMessage(message)
    setModalType(type)
    setDoubleButtons(true)
    setMessageBtn(messageCheck)
    setErrorModal(true)
  }

  const resetStates = () => {
    setModalMessage('')
    setModalType('')
    setDoubleButtons(false)
    setMessageBtn('')
    setErrorModal(false)
  }

  const handleCheckIn = async (type, force) => {
    const data = returnCheckData({ type, force })
    await check({
      data,
      resetStates,
      dispatchFN,
      dispatch,
      checkOut,
      openModal,
      dateIn,
      profileWorking
    })
  }

  const markRead = async () => {
    await handleMarkNewsAsRead({
      correctionsResult,
      dataReq,
      setModalConfirmOpen
    })
  }

  const actionVocation = () => {
    dispatch(inVocationFunc())
    setModalIsOpen(false)
  }
  /** *************************************************** */
  return (
    <>
      <ModalAlert
        modalisOpen={modalisOpen}
        actionClose={() => setModalIsOpen(false)}
        actionVocation={actionVocation}
      />

      <NewModal
        message={modalMessage}
        isOpen={errorModal}
        title="Check-in/Check-out"
        actionClose={() => setErrorModal(false)}
        type={modalType}
        doubleButton={doubleButtons}
        buttonMessage={messageBtn}
        actionConfirm={() => handleCheckIn('lunch', true)}
      />

      <Modal
        isOpen={modalConfirmOpen}
        className={`${darkmode && 'darkmode'} modal modal-top`}
        overlayClassName="modal-overlay"
      >
        <ConfirmationEditWork date={correctionsResult} action={markRead} />
      </Modal>

      <div className={`${darkmode && 'darkmode'} global-container`}>
        <Header />
        <div
          className={`${
            darkmode && 'darkmode'
          } global-content content-white content-auto-size animated-class-ligth-dark`}
        >
          <NavHome active={1} />
          <div className="user-infos-area">
            <UserInfos handleCheck={handleCheckIn} />
            <div className={`${darkmode && 'darkmode'} select-date`}>
              <StyledSelect
                data={dates}
                value={_selectDate}
                onChange={dateSelect}
                label="Mês/Ano"
              />
            </div>

            <span className={`${darkmode && 'darkmode'} working-hours-text`}>
              Saldo diário
            </span>
            <div className="working-hours">
              <WorkingHours dateNow={selectDate} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
