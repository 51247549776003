import { toast } from 'react-toastify'
import moment from 'moment'
import { memorianAPI, api } from '../../../services'
import { checkStatusCode } from '../../../utils'

const loadCorrections = async ({
  member,
  dispatch,
  setCorrectionsResult,
  setModalConfirmOpen
}) => {
  await api
    .post('/listNews', { member })
    .then(({ data }) => {
      if (data.length > 0) {
        setCorrectionsResult(data)
        setModalConfirmOpen(true)
      }
    })
    .catch((reject) => {
      checkStatusCode(reject, dispatch)
    })
}

const handleMarkNewsAsRead = async ({
  correctionsResult,
  dataReq,
  setModalConfirmOpen
}) => {
  if (correctionsResult.length > 0) {
    await memorianAPI.post('/use/functions/markNewsAsRead', dataReq).then(
      (resolve) => {
        setModalConfirmOpen(false)
      },
      (error) => {
        toast.error('Ops...Encontramos um problema por aqui. Tente novamente.')
      }
    )
  }
}

const check = async ({
  data,
  resetStates,
  dispatchFN,
  dispatch,
  checkOut,
  openModal,
  dateIn,
  profileWorking
}) => {
  await api.post('/saveHoursByMember', data).then(
    (resolve) => {
      const { status, message } = resolve.data
      if (status === 'in') {
        toast.success('Hey, User. Vamos fazer esse dia render!')
      } else {
        const hoursSplit = message.split(':')
        const hours = parseInt(hoursSplit[1], 10)
        hours < profileWorking.hoursPerDay
          ? toast.error(message)
          : toast.success(message)
        const nowHour = moment(new Date())
        dispatch(checkOut(nowHour))
      }
      resetStates()
      dispatchFN(dateIn)
    },
    (reject) => {
      const code = reject.response ? reject.response.status : 400
      if (code === 671 || code === 673 || code === 674) {
        openModal('error', code)
      } else {
        checkStatusCode(reject, dispatch)
      }
    }
  )
}

export { loadCorrections, handleMarkNewsAsRead, check }
