import React from 'react'
import Modal from 'react-modal'
import { MdError } from 'react-icons/md'
import PropTypes from 'prop-types'

import './styles.css'
import './darkmode.css'

function ModalDelete({
  modalDeleteIsOpen,
  action,
  actionClose,
  darkmode,
  formatNumber,
  number
}) {
  return (
    <Modal
      isOpen={modalDeleteIsOpen}
      onRequestClose={actionClose}
      className={`${darkmode && 'darkmode'} modal modal-top`}
      overlayClassName="modal-overlay"
    >
      <>
        <span className="modal-title">Cancelar</span>
        <div className="modal-items">
          <MdError size={30} color={darkmode ? '#FF796B' : '#E74C3C'} />
          <p className="modal-text-forgot">
            {`Deseja realmente excluir o feedback ${formatNumber(number)}?`}
          </p>
        </div>
        <div className="button-modal-2 btn-cancel-register">
          <button className="button-secondary" onClick={actionClose}>
            Voltar
          </button>
          <button className="button-primary" onClick={action}>
            Sim, quero excluir
          </button>
        </div>
      </>
    </Modal>
  )
}

ModalDelete.propTypes = {
  modalDeleteIsOpen: PropTypes.bool.isRequired,
  action: PropTypes.func.isRequired,
  actionClose: PropTypes.func.isRequired,
  darkmode: PropTypes.bool.isRequired,
  formatNumber: PropTypes.func.isRequired,
  number: PropTypes.number.isRequired
}

export default ModalDelete
