/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useRef } from 'react'
import PropTypes, { shape } from 'prop-types'
import Modal from 'react-modal'
import { MdDescription } from 'react-icons/md'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import axios, { CancelToken, isCancel } from 'axios'
import { Tooltip } from 'antd'

import Icon from './Icon'
import Tags from '../Tags'
import Download from '../DownloadComponent'
import RenderView from './RenderView'

import AddDocumentIcon from '../../assets/ViewDoc.svg'
import IconBlue from '../../assets/icons/IconBlue.svg'
import DownloadIcon from '../../assets/downloadDoc.svg'
import SaveBlue from '../../assets/icons/SaveBlue.svg'

import { checkStatusCode } from '../../utils'
import { api } from '../../services'

import './styles.css'
import './darkmode.css'
import '../../../node_modules/video-react/dist/video-react.css'

export default function ListFiles({ data, darkmode }) {
  const dispatch = useDispatch()

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [link, setLink] = useState('')
  const [typeFile, setTypeFile] = useState('')
  const [download, setDownload] = useState(false)

  const [tagsOpen, setTagsOpen] = useState(false)
  const [porcent, setPorcent] = useState(0)
  const [viewIndex, setViewIndex] = useState(null)
  const [downloadedIndex, setDownloadedIndex] = useState(null)
  const [completed, setCompleted] = useState(0)
  const [sizeTotal, setSizeTotal] = useState(0)
  const [nameFile, setNameFile] = useState('')
  const [textTool, setTextTool] = useState('Baixar')
  const errorMessage =
    'Ops...Encontramos um problema por aqui. Tente novamente.'

  const cancelFileDownload = useRef(null)

  const fileTypes = {
    video: {
      blob: 'video/mp4',
      ext: 'mp4'
    },
    pdf: {
      blob: 'application/pdf',
      ext: 'pdf'
    },
    image: {
      blob: 'image/jpg',
      ext: 'jpg'
    }
  }

  const getAPI = async (file) => {
    try {
      const response = await api.get(`/file/${file}`)
      const url = response.data

      return url.toString()
    } catch (error) {
      checkStatusCode(error, dispatch)
      toast.error(errorMessage)
    }
  }

  const confirmView = async (id) => {
    await api.post(`/file/confirmView/${id}`)
  }

  const finalizedDownload = (fileName, downloadUrl, ext) => {
    setTimeout(() => {
      let a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      a.href = downloadUrl
      a.download = `memorian_${fileName}.${ext}`
      a.click()
      URL.revokeObjectURL(a.href)
      document.body.removeChild(a)
    }, 2000)
    setTimeout(() => {
      setDownload(false)
      setPorcent(0)
      setCompleted(0)
      setSizeTotal(0)
      toast.success(
        <div className="toast-custom-download">
          <MdDescription size={25} />
          {`Download de ${fileName} concluído!`}
        </div>
      )
    }, 3000)
    setDownloadedIndex(null)
    setTextTool('Baixar')
  }

  const resetStates = () => {
    setPorcent(0)
    setSizeTotal(0)
    setCompleted(0)
  }

  const getFile = (url) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url,
        responseType: 'blob',
        onDownloadProgress: (evt) => {
          const { loaded, total } = evt
          let progress = parseInt((loaded / total) * 100, 10)
          setCompleted(loaded)
          setSizeTotal(total)
          setPorcent(progress)
        },
        cancelToken: new CancelToken((cancel) => {
          cancelFileDownload.current = cancel
        })
      })
        .then((res) => {
          resolve(res.data)
        })
        .catch((thrown) => {
          if (isCancel(thrown)) {
            setDownloadedIndex(null)
            setTextTool('Baixar')
          }
          setTimeout(() => {
            setDownload(false)
            reject(thrown)
          }, 500)
        })
    })
  }

  const handleCancel = () => {
    if (cancelFileDownload.current) {
      cancelFileDownload.current('User has canceled the file download')
    }
  }

  const downloadItem = async (file, index, _typeFile, fileName) => {
    resetStates()
    setDownloadedIndex(index)
    setDownload(true)
    setNameFile(fileName)
    setTextTool('Baixando')
    setTimeout(async () => {
      const url = await getAPI(file)
      getFile(url).then((res) => {
        const type = {
          type: fileTypes[_typeFile].blob
        }
        const { ext } = fileTypes[_typeFile]
        let blob = new Blob([res], type)
        let downloadUrl = URL.createObjectURL(blob)
        finalizedDownload(fileName, downloadUrl, ext)
      })
    }, 2000)
  }

  const handleOpen = async (file, _typeFile, index) => {
    resetStates()
    setViewIndex(index)
    const url = await getAPI(file.path)
    getFile(url, 100).then(async (res) => {
      const type = {
        type: fileTypes[_typeFile].blob
      }

      let blob = new Blob([res], type)
      let downloadUrl = URL.createObjectURL(blob)
      setTypeFile(_typeFile)
      setLink(downloadUrl)
      setModalIsOpen(true)
      setPorcent(0)
      setViewIndex(null)
      await confirmView(file._id)
    })
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className={`${
          darkmode && 'darkmode'
        } modal-media modal-normal-size modal-z-index`}
        overlayClassName="modal-overlay"
      >
        <RenderView typeFile={typeFile} link={link} />
      </Modal>

      <ul className="files">
        {data.map((item, index) => (
          <li className="file" key={item._id}>
            <div className="item-file">
              <div className="infos">
                <Icon type={item.type} />
                <div className="infos-texts">
                  <span className="file-title">{item.title}</span>
                  <span className="file-texts">{item.type}</span>
                  <span className="file-texts">{item.size}</span>
                  <Tags
                    tagColor={item.area}
                    index={index}
                    tagsOpen={tagsOpen}
                    actionClick={() => setTagsOpen(!tagsOpen)}
                  />
                </div>
              </div>
              <div className="btn-group-icons">
                <div className="button-tool">
                  <Tooltip
                    placement="bottom"
                    title={
                      downloadedIndex === index ? `${textTool}` : 'Visualizar'
                    }
                  >
                    <button
                      className="button-tool-tip"
                      onClick={() => handleOpen(item, item.type, index)}
                    >
                      <img
                        src={viewIndex === index ? IconBlue : AddDocumentIcon}
                        alt="icon-view"
                        className={viewIndex === index ? 'view-blue' : ''}
                      />
                    </button>
                  </Tooltip>
                </div>
                <div className="button-tool">
                  <Tooltip
                    placement="bottom"
                    title={downloadedIndex === index ? `${textTool}` : 'Baixar'}
                  >
                    <button
                      className="button-tool-tip"
                      onClick={() =>
                        downloadItem(item.path, index, item.type, item.title)
                      }
                    >
                      <img
                        src={
                          downloadedIndex === index ? SaveBlue : DownloadIcon
                        }
                        alt="icon-download"
                        className={downloadedIndex === index ? 'save-blue' : ''}
                      />
                    </button>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="barra">
              {viewIndex === index && (
                <div className="barra-2" style={{ width: `${porcent}%` }} />
              )}
            </div>
          </li>
        ))}
        {download && (
          <Download
            percentage={porcent}
            completed={completed}
            sizeTotal={sizeTotal}
            nameFile={nameFile}
            darkmode={darkmode}
            handleCancel={() => handleCancel()}
          />
        )}
      </ul>
    </>
  )
}

ListFiles.defaultProps = {
  data: []
}
ListFiles.propTypes = {
  data: PropTypes.arrayOf(shape([])),
  darkmode: PropTypes.bool.isRequired
}
