import React from 'react'
import { Switch } from 'react-router-dom'

import PrivateRoutes from './PrivateRoutes'
import {
  Academics,
  AccessData,
  BankData,
  CentralFiles,
  CentralTeam,
  Courses,
  Docs,
  Feedbacks,
  ForgotInMenu,
  ForgotPassword,
  Home,
  Infos,
  Juridics,
  LaborData,
  Login,
  PersonData
} from '../pages'

export default function Routes() {
  return (
    <Switch>
      <PrivateRoutes path="/login" component={Login} />
      <PrivateRoutes path="/" exact component={Home} isClosed />
      <PrivateRoutes path="/profile/1" component={PersonData} isClosed />
      <PrivateRoutes path="/profile/academic" component={Academics} isClosed />
      <PrivateRoutes path="/profile/work" component={LaborData} isClosed />
      <PrivateRoutes path="/profile/pj" component={Juridics} isClosed />
      <PrivateRoutes path="/profile/docs" component={Docs} isClosed />
      <PrivateRoutes path="/profile/2" component={AccessData} isClosed />
      <PrivateRoutes path="/profile/3" component={BankData} isClosed />
      <PrivateRoutes path="/profile/4" component={Infos} isClosed />
      <PrivateRoutes path="/courses" component={Courses} isClosed />
      <PrivateRoutes path="/files" component={CentralFiles} isClosed />
      <PrivateRoutes path="/user/forgot" component={ForgotInMenu} isClosed />
      <PrivateRoutes path="/forgot" component={ForgotPassword} />
      <PrivateRoutes path="/centralteam" component={CentralTeam} isClosed />
      <PrivateRoutes path="/feedbacks" component={Feedbacks} isClosed isAdmin />
    </Switch>
  )
}
