/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'

import './styles.css'
import './darkmode.css'

function StyledRadio({ value, setValue, markValue }) {
  return (
    <button
      className="custom-radio"
      type="button"
      onClick={() => setValue(markValue)}
    >
      {value === markValue && <div className="custom-checked" />}
    </button>
  )
}

StyledRadio.propTypes = {
  value: PropTypes.any.isRequired,
  setValue: PropTypes.func.isRequired,
  markValue: PropTypes.any.isRequired
}

export default StyledRadio
