import React from 'react'
import { ToastContainer } from 'react-toastify'
import { useSelector } from 'react-redux'

import './styles.css'
import './darkmode.css'

function ToastContainerIndex() {
  const darkmode = useSelector((state) => state.user.darkmode)
  return (
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnVisibilityChange={false}
      draggable={false}
      pauseOnHover
      className={`toast-custom-container ${darkmode && 'darkmode'}`}
    />
  )
}

export default ToastContainerIndex
