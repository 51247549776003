/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      viewBox="0 0 30 30"
      {...props}
    >
      <path
        data-name="Subtra\xE7\xE3o 3"
        d="M26.998 30h-24a3 3 0 01-3-3V3a3 3 0 013-3h24a3 3 0 013 3v24a3.007 3.007 0 01-3 3zM15.751 18.75v3.748h8.247V18.75zm-9.755 0v3.748h8.253V18.75zm9.755-5.252v3.75h8.247v-3.75zm-9.755 0v3.75h8.253v-3.75zm9.755-5.25V12h8.247V8.246zm-9.755 0V12h8.253V8.246z"
        fill="#757575"
      />
    </svg>
  )
}

export default SvgComponent
