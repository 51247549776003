/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { MdSearch } from 'react-icons/md'
import './style.css'
import './darkmode.css'

// Inpu called by courses page
export default function SearchInput({ ...rest }) {
  return (
    <div className="search-input-cp">
      <div className="icon-search">
        <MdSearch size={30} color="#757575" />
      </div>

      <input {...rest} autoComplete="off" autoSave="off" />
    </div>
  )
}
