import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Tabs } from 'antd'

import { Header, ListFeedbacks } from '../../components'

import './darkmode.css'

function Feedbacks() {
  const darkmode = useSelector((state) => state.user.darkmode)
  const [active, setActive] = useState('1')

  const { TabPane } = Tabs
  return (
    <div className={`${darkmode && 'darkmode'} global-container`}>
      <Header />
      <div className="global-content content-white content-fixed-size">
        <Tabs defaultActiveKey="1" onChange={(e) => setActive(e)}>
          <TabPane
            tab={
              <div className="tab-antd">
                <span>Feedbacks recebidos</span>
              </div>
            }
            key="1"
          >
            {active === '1' && (
              <ListFeedbacks filed={false} darkmode={darkmode} />
            )}
          </TabPane>
          <TabPane
            tab={
              <div className="tab-antd">
                <span>Arquivados</span>
              </div>
            }
            key="2"
          >
            {active === '2' && <ListFeedbacks filed darkmode={darkmode} />}
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default Feedbacks
