import { takeLatest, put, all } from 'redux-saga/effects'
import moment from 'moment'

import { api, history, api_max } from '../../../services'
import { updateHourEntry } from './actions'
import { getNotificationsReq } from '../user/actions'

/**
 * Function called by the persist / REHYDRATE action
 * @param {Object} payload - param auth-token
 */
export function* rehydrateFN({ payload }) {
  if (!payload) return
  const { token, sessionToken } = payload.auth
  const date = new Date()
  const hourEntry = moment(date)

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`
    api_max.defaults.headers.Authorization = `Bearer ${token}`
  }

  if (sessionToken) {
    yield put(getNotificationsReq(sessionToken))
    yield put(updateHourEntry(hourEntry))
  }
}

export function logOut() {
  localStorage.removeItem('persist:memorian')
  history.push('/login')
}

export default all([
  takeLatest('persist/REHYDRATE', rehydrateFN),
  takeLatest('@auth/LOG_OUT', logOut)
])
