const validateArr = ({ refArr, value }) => {
  const arr = [...refArr]
  const buscarPor = value
  let indice = arr.indexOf(buscarPor)
  if (indice >= 0) {
    while (indice >= 0) {
      arr.splice(indice, 1)
      indice = arr.indexOf(buscarPor)
    }
  } else {
    arr.push(value)
  }
  return arr
}

export { validateArr }
