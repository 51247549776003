/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes, { shape } from 'prop-types'
import { useDispatch } from 'react-redux'
import { MdArchive, MdDrafts, MdDelete, MdEmail } from 'react-icons/md'

import { formatDateParse, checkStatusCode } from '../../utils'

import Avatar from './Avatar'
import Modal from './ModalFeedback'
import ModalDelete from './ModalDelete'

import Unfiled from '../../assets/icons/Unfiled.svg'

import { api } from '../../services'

import './styles.css'
import './darkmode.css'

function Feedback({ item, setChange, darkmode }) {
  const dispatch = useDispatch()
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = React.useState(false)

  const iconConfig = {
    size: 25,
    color: '#757575'
  }

  const textFiled = item.filed ? 'Desarquivar' : 'Arquivar'
  const textViewed = item.viewed ? 'Marcar como não lido' : 'Marcar como lido'

  const [user, setUser] = React.useState({})

  const handleFiled = async (value) => {
    setChange(false)
    await api
      .post('/feedback/update', { ...item, filed: value })
      .then((res) => setChange(true))
      .catch((reject) => checkStatusCode(reject, dispatch))
  }

  const handleMarkToViewed = async (value) => {
    setChange(false)
    await api
      .post('/feedback/update', { ...item, viewed: value })
      .then((res) => setChange(true))
      .catch((reject) => checkStatusCode(reject, dispatch))
  }

  const handleDelete = async () => {
    setChange(false)
    await api
      .get(`/feedback/${item._id}`)
      .then((res) => {
        setChange(true)
        setModalDeleteIsOpen(false)
      })
      .catch((reject) => checkStatusCode(reject, dispatch))
  }

  const handleCloseModal = async () => {
    setModalIsOpen(false)
    await handleMarkToViewed()
  }

  const formatNumber = (number) => {
    if (number < 10) {
      number = `#00${number}`
    } else if (number < 100) {
      number = `#0${number}`
    } else {
      number = `#${number}`
    }
    return number
  }

  return (
    <div className="feedback-item">
      <Modal
        modalIsOpen={modalIsOpen}
        item={item}
        user={user}
        actionClose={handleCloseModal}
        darkmode={darkmode}
        formatNumber={formatNumber}
      />

      <ModalDelete
        modalDeleteIsOpen={modalDeleteIsOpen}
        action={handleDelete}
        actionClose={() => setModalDeleteIsOpen(false)}
        darkmode={darkmode}
        formatNumber={formatNumber}
        number={item.number}
      />

      <div
        className="feed-back-area"
        role="button"
        tabIndex={0}
        onClick={() => setModalIsOpen(true)}
      >
        <Avatar user={item.user} setUser={setUser} />
        <div className="feedback-comp">
          <span className={`name-user-feedback ${user.tag}-text`}>
            {user.name}
          </span>
          <div className="text-container">
            <p>{`${formatNumber(item.number)} ${item.feedback}`}</p>
          </div>
          <span className="span-data-feedback data-feed-margin">
            {formatDateParse(item.createdAt)}
          </span>
        </div>
      </div>
      <div className="buttons-feedback">
        <div className="tool-feed">
          <button
            className="tool-tip-feed"
            type="button"
            onClick={
              item.filed ? () => handleFiled(false) : () => handleFiled(true)
            }
          >
            {item.filed ? (
              <img src={Unfiled} alt="unfiled" />
            ) : (
              <MdArchive {...iconConfig} />
            )}
          </button>
          <span className="ft-span">{textFiled}</span>
        </div>
        <div className="tool-feed">
          <button
            className="tool-tip-feed"
            type="button"
            onClick={
              item.viewed
                ? () => handleMarkToViewed(false)
                : () => handleMarkToViewed(true)
            }
          >
            {item.viewed ? (
              <MdEmail {...iconConfig} />
            ) : (
              <MdDrafts {...iconConfig} />
            )}
          </button>
          <span className="sc-span">{textViewed}</span>
        </div>
        <div className="tool-feed">
          <button
            className="tool-tip-feed"
            type="button"
            onClick={() => setModalDeleteIsOpen(true)}
          >
            <MdDelete size={25} color={darkmode ? '#FF796B' : '#E74C3C'} />
          </button>
          <span className="ts-span">Excluir</span>
        </div>
      </div>
    </div>
  )
}

Feedback.propTypes = {
  item: PropTypes.objectOf(shape({})).isRequired,
  setChange: PropTypes.func.isRequired,
  darkmode: PropTypes.bool.isRequired
}

export default Feedback
