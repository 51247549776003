import React from 'react'
import PropTypes from 'prop-types'

import './styles.css'

function ImageView({ url }) {
  return (
    <div style={{ backgroundImage: `url(${url})` }} className="image-view" />
  )
}

ImageView.propTypes = {
  url: PropTypes.string.isRequired
}

export default ImageView
