import React from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'

import EditorProfileComponent from '../EditorProfileComponent'

function UserInfosModalEdit({ modalIsOpen, actionClose, darkmode }) {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={actionClose}
      className={`${darkmode && 'darkmode'} modal-user-infos`}
      overlayClassName="modal-overlay"
    >
      <EditorProfileComponent actionClose={actionClose} darkmode={darkmode} />
    </Modal>
  )
}

UserInfosModalEdit.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  actionClose: PropTypes.func.isRequired,
  darkmode: PropTypes.bool.isRequired
}

export default UserInfosModalEdit
