/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { MdSearch } from 'react-icons/md'
import PropTypes, { shape } from 'prop-types'
import { ClickAwayListener } from '@material-ui/core'
import User from './components/user'

import './styles.css'
import './darkmode.css'

// Inpu called by courses page
function AsyncSearchInput({
  options,
  actionClick,
  clickOut,
  darkmode,
  ...rest
}) {
  const [data, setData] = React.useState([])

  React.useEffect(() => {
    setData(options)
    return () => {
      setData([])
    }
  }, [options])

  const handleClickAway = () => {
    setData([])
    clickOut()
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="async-search-and-options">
        <div className="search-input-cp">
          <div className="icon-search">
            <MdSearch size={30} color="#757575" />
          </div>

          <input {...rest} autoComplete="off" autoSave="off" />
        </div>
        {data.length > 0 && (
          <ul className="async-search-options">
            {data.map((item) => (
              <User user={item} actionClick={actionClick} />
            ))}
          </ul>
        )}
      </div>
    </ClickAwayListener>
  )
}

AsyncSearchInput.propTypes = {
  options: PropTypes.arrayOf(shape([])).isRequired,
  actionClick: PropTypes.func.isRequired,
  clickOut: PropTypes.func.isRequired,
  darkmode: PropTypes.bool.isRequired
}

export default AsyncSearchInput
