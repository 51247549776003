import centralFiles from './CentralFiles'
import centralTeam from './CentralTeam'
import courses from './Courses'
import feedbacks from './Feedbacks'
import forgotMenu from './ForgotInMenu'
import forgotPassword from './ForgotPassword'
import home from './Home'
import login from './Login'
import newMember from './NewMember'

export const CentralFiles = centralFiles
export const CentralTeam = centralTeam
export const Courses = courses
export const Feedbacks = feedbacks
export const ForgotInMenu = forgotMenu
export const ForgotPassword = forgotPassword
export const Home = home
export const Login = login
export const NewMember = newMember
export * from './Profile'
export { FreeRegister } from './FreeRegister'
