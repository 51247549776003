import React from 'react'
import Modal from 'react-modal'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { ConnectedAlert } from '../ModalsItems'

import './styles.css'

function ModalAlert({ modalisOpen, actionClose, actionVocation }) {
  const darkmode = useSelector((state) => state.user.darkmode)
  return (
    <Modal
      isOpen={modalisOpen}
      onRequestClose={actionClose}
      className={`${darkmode && 'darkmode'} modal modal-top`}
      overlayClassName="modal-overlay"
    >
      <ConnectedAlert />

      <div className="button-modal-2 btn-alert">
        <button className="button-secondary" onClick={actionVocation}>
          Tudo bem, estou fora!
        </button>
        <button className="button-primary" onClick={actionClose}>
          Bem lembrado!
        </button>
      </div>
    </Modal>
  )
}

ModalAlert.propTypes = {
  modalisOpen: PropTypes.bool.isRequired,
  actionClose: PropTypes.func.isRequired,
  actionVocation: PropTypes.func.isRequired
}

export default ModalAlert
