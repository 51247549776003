import React from 'react'
import { MdClose } from 'react-icons/md'
import PropTypes from 'prop-types'

// import { Container } from './styles';

function ErrorFN({ darkmode, size }) {
  return (
    <div
      style={{
        background: darkmode ? '#FF796B' : '#E74C3C',
        width: size,
        height: size,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <MdClose color="fff" size={size - 5} strokeWidth={2} />
    </div>
  )
}

ErrorFN.propTypes = {
  darkmode: PropTypes.bool.isRequired,
  size: PropTypes.number.isRequired
}

export default ErrorFN
