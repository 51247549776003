import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  MdNavigateBefore,
  MdCheck,
  MdChevronRight,
  MdArrowDropUp,
  MdArrowDropDown
} from 'react-icons/md'

import {
  Header,
  NavProfile,
  StyledBasicInput,
  AutoComplete,
  ModalCancelRegister
} from '../../../components'
import { onlyNumbers, banks } from '../../../utils'
import { updateUser } from '../../../store/modules/user/actions'

import { history } from '../../../services'

import './style.css'

export default function BankData() {
  const darkmode = useSelector((state) => state.user.darkmode)

  const [disabled, setDisabled] = useState(true)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [bank, setBank] = useState('')
  const [account, setAccount] = useState('')
  const [agency, setAgency] = useState('')
  const [open, setOpen] = useState(false)

  const profile = useSelector((select) => select.user.profile)

  const dispatch = useDispatch()
  /**
   * Auto completes the inputs
   * @param {Object} userdata - User's Object
   */
  const completeTo = (userdata) => {
    setBank(userdata.bank)
    setAccount(userdata.account)
    setAgency(userdata.agency)
  }

  useEffect(() => {
    let user = JSON.parse(sessionStorage.getItem('user'))
    completeTo(user)
  }, [])

  useEffect(() => {
    const checkInpusEmpty = () => {
      if (bank !== '' && account !== '' && agency !== '') {
        setDisabled(false)
      } else {
        setDisabled(true)
      }
    }
    checkInpusEmpty()
  }, [bank, account, agency])

  const generateUser = () => {
    let user = JSON.parse(sessionStorage.getItem('user'))
    user.bank = bank
    user.account = account
    user.agency = agency
    sessionStorage.setItem('user', JSON.stringify(user))
  }

  const handleReturn = () => {
    generateUser()
    history.push('/profile/2')
  }

  const handleCancel = () => {
    setModalIsOpen(false)
    sessionStorage.removeItem('user')
    history.push('/')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await generateUser()
    if (profile.finalized) {
      const userStorage = JSON.parse(sessionStorage.getItem('user'))
      dispatch(updateUser(userStorage))
    } else {
      history.push('/profile/4')
    }
  }

  return (
    <>
      <ModalCancelRegister
        open={modalIsOpen}
        actionClose={() => setModalIsOpen(false)}
        action={handleCancel}
        typeCancel={profile.finalized ? 'as edições' : 'o cadastro'}
      />
      <div className={`${darkmode && 'darkmode'} global-container`}>
        <Header />
        <div className="global-content global-content-grey content-md-size">
          {profile.finalized ? (
            <span className="title">MEU PERFIL</span>
          ) : (
            <span className="title">CADASTRO</span>
          )}
          <div className="bar-div">
            <hr className="bar-bank" />
          </div>

          <NavProfile active={3} generateUser={generateUser} />

          <form className="form3" onSubmit={handleSubmit}>
            <div className="inputs-bank">
              <div className="item-bank">
                <AutoComplete
                  data={banks}
                  label="Banco *"
                  value={bank}
                  onChange={(e) => setBank(e.target.value)}
                  onChangeAutoComplete={(value) => setBank(value)}
                  valueAuto={bank}
                  Icon={open ? MdArrowDropUp : MdArrowDropDown}
                  onFocus={() => setOpen(true)}
                  onBlur={() => setOpen(false)}
                  open={open}
                />
              </div>
              <div className="item-bank">
                <StyledBasicInput
                  label="Agência *"
                  value={agency}
                  onChange={(e) => setAgency(onlyNumbers(e.target.value))}
                />
              </div>
              <div className="item-bank">
                <StyledBasicInput
                  label="Conta(com dígito) *"
                  value={account}
                  onChange={(e) => setAccount(onlyNumbers(e.target.value))}
                />
              </div>
            </div>

            <div className="form-bottom">
              <button
                type="button"
                className="button-return"
                onClick={handleReturn}
              >
                <MdNavigateBefore size={20} />
                Voltar
              </button>

              <div className="btn-group">
                <button
                  type="button"
                  className="button-secondary btn-register-w"
                  onClick={() => setModalIsOpen(true)}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="button-primary btn-register-w"
                  disabled={disabled}
                >
                  {profile.finalized ? (
                    <>
                      Salvar
                      <MdCheck
                        color={darkmode ? '#1a1a1a' : '#fff'}
                        size={20}
                      />
                    </>
                  ) : (
                    <>
                      Avançar
                      <MdChevronRight
                        color={darkmode ? '#1a1a1a' : '#fff'}
                        size={20}
                      />
                    </>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
