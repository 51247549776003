import styled from 'styled-components'

export const Folder = styled.div`
  width: 258px;
  height: 52px;
  border: 1px solid #bfbfbf;
  border-radius: 7px;
  color: #757575;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  cursor: pointer;
`
