import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MdNavigateBefore, MdCheck } from 'react-icons/md'

import { history } from '../../../services'
import {
  Header,
  NavProfile,
  StyledBasicInput,
  StyledSelect,
  ModalCancelRegister,
  StyledRadio
} from '../../../components'
import { updateUser } from '../../../store/modules/user/actions'

import './style.css'

export default function Infos() {
  const darkmode = useSelector((state) => state.user.darkmode)

  let user = JSON.parse(sessionStorage.getItem('user'))
  const [disabled, setDisabled] = useState(true)
  const [modalCancelIsOpen, setModalCancelIsOpen] = useState(false)

  const [sizeShirt, setSizeShirt] = useState('')
  const [sizeShoes, setSizeShoes] = useState('')
  const [usernameInsta, setUsernameInsta] = useState('')
  const [foodRestriction, setFoodRestriction] = useState('')
  const [dominantHand, setDominantHand] = useState('')
  const [admissionDate, setAdmissionDate] = useState('')
  const [typeShirt, setTypeShirt] = useState('')

  const dispatch = useDispatch()

  const tShirtsSize = [
    { value: 'PP' },
    { value: 'P' },
    { value: 'M' },
    { value: 'G' },
    { value: 'GG' },
    { value: 'XG' },
    { value: 'XGG' }
  ]

  const hands = [
    { value: 'Direita' },
    { value: 'Esquerda' },
    { value: 'As Duas' }
  ]

  const shoes = []

  for (let i = 30; i <= 45; i += 1) {
    const obj = { value: i }
    shoes.push(obj)
  }

  const profile = useSelector((select) => select.user.profile)
  /**
   * Auto completes the inputs
   * @param {Object} userData - User's Object
   */

  const completeTo = (userData) => {
    setSizeShirt(userData.sizeShirt)
    setSizeShoes(userData.sizeShoes)
    setFoodRestriction(userData.foodRestriction)
    setDominantHand(userData.dominantHand)
    setUsernameInsta(userData.usernameInsta)
    setAdmissionDate(userData.admissionDate)
    setTypeShirt(userData.typeShirt)
  }

  useEffect(() => {
    const userdata = JSON.parse(sessionStorage.getItem('user'))

    const completeInputs = () => {
      if (userdata) {
        completeTo(userdata)
      }
    }
    completeInputs()
  }, [profile])

  useEffect(() => {
    const checkEmptyInputs = () => {
      if (
        sizeShoes !== '' &&
        sizeShirt !== '' &&
        typeShirt !== '' &&
        dominantHand !== '' &&
        admissionDate !== '' &&
        admissionDate !== '__/__/____'
      ) {
        setDisabled(false)
      } else {
        setDisabled(true)
      }
    }
    checkEmptyInputs()
  }, [sizeShoes, sizeShirt, typeShirt, dominantHand, admissionDate])

  const generateUser = () => {
    user.sizeShirt = sizeShirt
    user.typeShirt = typeShirt
    user.sizeShoes = sizeShoes
    user.usernameInsta = usernameInsta
    user.foodRestriction = foodRestriction
    user.dominantHand = dominantHand
    user.admissionDate = admissionDate
    return user
  }

  const generateToReturn = () => {
    const userdata = generateUser()
    sessionStorage.setItem('user', JSON.stringify(userdata))
  }
  const handleReturn = () => {
    generateUser()
    history.push('/profile/3')
  }

  const handleCancel = () => {
    setModalCancelIsOpen(false)
    sessionStorage.removeItem('user')
    history.push('/')
  }

  const handleSubtmit = (e) => {
    e.preventDefault()
    user = generateUser()
    sessionStorage.setItem('user', JSON.stringify(user))
    sessionStorage.removeItem('no-first-edit')
    dispatch(updateUser(user))
  }

  return (
    <>
      <ModalCancelRegister
        open={modalCancelIsOpen}
        actionClose={() => setModalCancelIsOpen(false)}
        action={handleCancel}
        typeCancel={profile.finalized ? 'as edições' : 'o cadastro'}
      />
      <div className={`${darkmode && 'darkmode'} global-container`}>
        <Header />
        <div className="global-content global-content-grey">
          {profile.finalized ? (
            <span className="title">MEU PERFIL</span>
          ) : (
            <span className="title">CADASTRO</span>
          )}
          <hr className="title-bar" />
          <NavProfile active={4} generateUser={generateToReturn} />

          <form onSubmit={handleSubtmit}>
            <div className="grid-infos">
              <div className="grid-infos grid-top-infos">
                <div className="input-w-margin">
                  <StyledSelect
                    data={shoes}
                    label="Tamanho de calçado *"
                    holder="Selecione"
                    value={sizeShoes}
                    onChange={(e) => setSizeShoes(e.target.value)}
                  />
                </div>
                <div className="input-w-margin">
                  <StyledSelect
                    data={tShirtsSize}
                    label="Tamanho de camiseta *"
                    holder="Selecione"
                    value={sizeShirt}
                    onChange={(e) => setSizeShirt(e.target.value)}
                  />
                </div>
                <div className="input-radio-gp-academic">
                  <label className="radios-label">Tipo de camiseta *</label>
                  <div className="radios-1">
                    <div className="btn-and-label">
                      <StyledRadio
                        value={typeShirt}
                        setValue={setTypeShirt}
                        markValue="Babylook"
                      />
                      <label className="label-radio">Babylook</label>
                    </div>
                    <div className="btn-and-label">
                      <StyledRadio
                        value={typeShirt}
                        setValue={setTypeShirt}
                        markValue="Normal"
                      />
                      <label className="label-radio">Normal</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid-infos grid-center-infos">
                <div className="input-w-margin">
                  <StyledBasicInput
                    label="Restrição alimentar"
                    placeholder="Opcional"
                    value={foodRestriction}
                    onChange={(e) => setFoodRestriction(e.target.value)}
                  />
                </div>
                <div className="input-w-margin">
                  <StyledSelect
                    data={hands}
                    label="Mão dominante *"
                    value={dominantHand}
                    onChange={(e) => setDominantHand(e.target.value)}
                  />
                </div>
              </div>

              <div className="grid-infos grid-bottom-infos">
                <div className="input-w-margin">
                  <StyledBasicInput
                    label="Instagram"
                    value={usernameInsta}
                    onChange={(e) => setUsernameInsta(e.target.value)}
                    placeholder="Opcional"
                  />
                </div>
                <div className="input-w-margin">
                  <StyledBasicInput
                    label="Data de admissão"
                    value={admissionDate}
                    mask="99/99/9999"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="form-bottom">
              <button
                type="button"
                className="button-return"
                onClick={handleReturn}
              >
                <MdNavigateBefore size={20} />
                Voltar
              </button>

              <div className="btn-group">
                <button
                  type="button"
                  className="button-secondary btn-register-w"
                  onClick={() => setModalCancelIsOpen(true)}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="button-primary btn-register-w"
                  disabled={disabled}
                >
                  {profile.finalized ? (
                    <>
                      Salvar
                      <MdCheck
                        color={darkmode ? '#1a1a1a' : '#fff'}
                        size={20}
                      />
                    </>
                  ) : (
                    <>
                      Finalizar
                      <MdCheck
                        color={darkmode ? '#1a1a1a' : '#fff'}
                        size={20}
                      />
                    </>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
