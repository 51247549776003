import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes, { shape, string } from 'prop-types'

import { setAvatarUrlMain } from '../../utils'

import './styles.css'
import './darkmode.css'

function HeaderMenu({ profile, handleOpenProfile }) {
  const dataWorking = useSelector((state) => state.user.profileWorking)
  const nameUser = profile.name.split(' ')
  const [url, setUrl] = React.useState('')

  React.useEffect(() => {
    setAvatarUrlMain(profile, setUrl)
  }, [profile])

  return (
    <button type="button" onClick={handleOpenProfile}>
      <section className="profile">
        <div className="border-profile-img">
          <div className="status-on-off">
            <div
              style={{
                backgroundImage: `url(${
                  profile.avatar_url
                    ? profile.avatar_url
                    : `https://avatar.oxro.io/avatar.svg?name=${nameUser[0]}+${nameUser[1]}&background=f6f6f6&length=2&color=00b7ef`
                })`
              }}
              className="div-test"
            />
            <div
              className={
                dataWorking.working
                  ? 'indicator-status on'
                  : 'indicator-status off'
              }
            />
          </div>
        </div>
      </section>
    </button>
  )
}

HeaderMenu.propTypes = {
  profile: PropTypes.objectOf(shape({ avatar_url: string })).isRequired,
  handleOpenProfile: PropTypes.func.isRequired
}

export default HeaderMenu
