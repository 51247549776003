import { encryptKey } from '../../../utils'

const saveInCache = (url, data) => {
  const name = `${data.name.split(' ')[0]}_${data.name.split(' ').pop()}`
  const oldItem = localStorage.getItem(name)
  if (oldItem) {
    localStorage.removeItem(name)
  }
  localStorage.setItem(
    name,
    JSON.stringify({
      avatar: encryptKey(data.avatar),
      url: encryptKey(url),
      createdAt: new Date()
    })
  )
}

export { saveInCache }
