import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MdArrowDropUp, MdChevronRight } from 'react-icons/md'
import { ClickAwayListener } from '@material-ui/core'
import { GridLoader } from 'react-spinners'
import { toast } from 'react-toastify'

import ToastCustom from '../ToastCustom'
import RadiosGp from '../RadiosGP'

import FeedbackIcon from '../../assets/icons/feedBack.svg'
import CloseIcon from '../../assets/icons/CloseIcon.svg'

import { checkStatusCode } from '../../utils'
import { api } from '../../services'

import './styles.css'
import './darkmode.css'
import useDisabled from '../../hooks/useDisable'

function FeebackButton() {
  const dispatch = useDispatch()
  const memberId = useSelector((state) => state.auth.sessionToken)
  const isLoggedIn = useSelector((state) => state.auth.signed)
  const darkmode = useSelector((state) => state.user.darkmode)

  const [loading, setLoading] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [feedback, setFeedback] = React.useState('')
  const [type, setType] = React.useState('')

  const [disabled] = useDisabled([feedback, type])

  const handleOpenBtn = () => {
    setOpen(!open)
  }

  const checkInput = (value) => {
    setFeedback(value)
  }

  const handleClickAway = () => {
    setOpen(false)
  }

  const handleSubmit = async () => {
    setLoading(true)
    await api
      .post('/feedback', {
        memberId,
        feedback,
        type
      })
      .then((res) => {
        toast.success(
          <ToastCustom text="Feedback enviado com sucesso. Agradecemos pela colaboração!" />
        )
        setLoading(false)
        setOpen(false)
        setFeedback('')
      })
      .catch((reject) => {
        checkStatusCode(reject, dispatch)
        setLoading(false)
      })
  }
  return (
    isLoggedIn && (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={`${darkmode && 'darkmode'} feedback-button`}>
          {!open ? (
            <button
              className="btn-feedback"
              type="button"
              onClick={handleOpenBtn}
            >
              <div className="btn-feed-items">
                <img src={FeedbackIcon} alt="feeedback" />
                <span>Deixar um feedback</span>
                <MdArrowDropUp size={25} />
              </div>
            </button>
          ) : (
            <div className="feedback-component">
              <div className="feedback-header">
                <div className="feedback-header-items">
                  <span>
                    <img src={FeedbackIcon} alt="feeedback" />
                    Feedback do usuário
                  </span>
                  <button type="button" onClick={handleOpenBtn}>
                    <img src={CloseIcon} alt="X" />
                  </button>
                </div>
              </div>
              <div className="feedback-text-area">
                <p>
                  Deixe aqui sua opinião sobre o nosso sistema. Você pode voltar
                  aqui sempre que desejar. Pedimos que detalhe o máximo possível
                  as suas sugestões e/ou críticas.
                </p>
                <textarea
                  value={feedback}
                  onChange={(e) => checkInput(e.target.value)}
                  placeholder="Seu feedback é muito importante para nós!"
                />

                <RadiosGp
                  label1="Sugestão"
                  label2="Bug"
                  label3="Outro"
                  value={type}
                  setValue={setType}
                />

                <button
                  className="button-primary submit-feedback"
                  type="button"
                  onClick={handleSubmit}
                  disabled={disabled}
                >
                  {loading ? (
                    <GridLoader
                      color={darkmode ? '#121212' : '#fff'}
                      size={5}
                    />
                  ) : (
                    <>
                      <span>Enviar</span>
                      <MdChevronRight size={25} />
                    </>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </ClickAwayListener>
    )
  )
}

export default FeebackButton
