import { api } from '../../../services'
import { checkStatusCode } from '../../../utils'

const checkTotalDocs = ({ total, setPage }) => {
  if (total <= 15) {
    setPage(1)
  }
}

const newDocsReturn = (a, b) => {
  if (a.viewed === false && b.viewed === true) {
    return -1
  }
  if (a.viewed === true && b.viewed === false) {
    return 1
  }
  return 0
}

const getFeedbacks = async ({
  filed,
  page,
  dispatch,
  setNumberPages,
  setDataNumbers,
  setDocsNumber,
  setData,
  setPage
}) => {
  try {
    const response = await api.get(
      `/feedback?filed=${filed}&sort=number&order=-1&page=${page}`
    )
    const { totalPages, totalDocs, docs } = response.data
    setNumberPages(totalPages)
    const numbers = []
    for (let i = 1; i <= totalPages; i += 1) {
      const obj = { value: i }
      numbers.push(obj)
    }
    const newDocs = docs.sort(newDocsReturn)
    setDataNumbers(numbers)
    setDocsNumber(totalDocs)
    setData(newDocs)
    checkTotalDocs({ total: totalDocs, setPage })
  } catch (reject) {
    checkStatusCode(reject, dispatch)
  }
}

export { getFeedbacks }
