import React from 'react'
import {
  MdDone,
  MdFace,
  MdSchool,
  MdBusinessCenter,
  MdAttachFile,
  MdWork
} from 'react-icons/md'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import './styles.css'
import './darkmode.css'

function SubNav({ select, complete, contractingModel, generateUser }) {
  const [color, setColor] = React.useState('ligth')

  const darkmode = useSelector((state) => state.user.darkmode)

  React.useEffect(() => {
    darkmode ? setColor('dark') : setColor('ligth')
  }, [darkmode])

  const colors = {
    dark: '#40C9F3',
    ligth: '#00b7ef'
  }

  return (
    <nav>
      <ul className="sub-nav-bar">
        <li className="item-li" onClick={generateUser}>
          {complete >= 1 ? (
            <MdDone size={18} color={colors[color]} />
          ) : (
            <div className={select >= 1 ? 'circle-nav-select' : 'circle-nav'} />
          )}
          <Link
            className={select >= 1 ? 'text-select text-1' : 'text text-1'}
            to="/profile/1"
          >
            Dados pessoais
          </Link>
          <Link className="icon-nav icon-sub icon-top" to="/profile/1">
            <MdFace
              size={25}
              color={select !== 1 ? '#b8b8b9' : colors[color]}
            />
          </Link>
        </li>
        {contractingModel === 'Estágio' && (
          <>
            <li className="sub-nav-linha item-li">
              <hr className={select >= 2 ? 'linha-nav-select' : 'linha-nav'} />
            </li>
            <li className="item-li item-flex" onClick={generateUser}>
              {complete >= 2 ? (
                <MdDone size={18} color={colors[color]} />
              ) : (
                <div
                  className={select >= 2 ? 'circle-nav-select' : 'circle-nav'}
                />
              )}
              <Link
                className={select >= 2 ? 'text-select text-2' : 'text text-2'}
                to="/profile/academic"
              >
                Dados acadêmicos
              </Link>
              <Link
                className="icon-nav icon-sub icon-top"
                to="/profile/academic"
              >
                <MdSchool
                  size={25}
                  color={select !== 2 ? '#b8b8b9' : colors[color]}
                />
              </Link>
            </li>
          </>
        )}
        {contractingModel === 'PJ' && (
          <>
            <li className="sub-nav-linha item-li">
              <hr className={select >= 2 ? 'linha-nav-select' : 'linha-nav'} />
            </li>
            <li className="item-li" onClick={generateUser}>
              {complete >= 2 ? (
                <MdDone size={18} color={darkmode ? '#40C9F3' : '00b7ef'} />
              ) : (
                <div
                  className={select >= 2 ? 'circle-nav-select' : 'circle-nav'}
                />
              )}
              <Link
                className={select >= 2 ? 'text-select text-1' : 'text text-1'}
                to="/profile/pj"
              >
                Dados jurídicos
              </Link>
              <Link className="icon-nav icon-sub icon-top" to="/profile/pj">
                <MdBusinessCenter
                  size={25}
                  color={select !== 2 ? '#b8b8b9' : colors[color]}
                />
              </Link>
            </li>
          </>
        )}

        {contractingModel === 'CLT' && (
          <>
            <li className="sub-nav-linha item-li">
              <hr className={select >= 2 ? 'linha-nav-select' : 'linha-nav'} />
            </li>
            <li className="item-li" onClick={generateUser}>
              {complete >= 2 ? (
                <MdDone size={18} color={darkmode ? '#40C9F3' : '00b7ef'} />
              ) : (
                <div
                  className={select >= 2 ? 'circle-nav-select' : 'circle-nav'}
                />
              )}
              <Link
                className={
                  select >= 2 ? 'text-select text-work' : 'text text-work'
                }
                to="/profile/work"
              >
                Dados trabalhistas
              </Link>
              <Link className="icon-nav icon-sub icon-top" to="/profile/work">
                <MdWork
                  size={25}
                  color={select !== 2 ? '#b8b8b9' : colors[color]}
                />
              </Link>
            </li>
          </>
        )}

        <li className="sub-nav-linha item-li">
          <hr className={select === 3 ? 'linha-nav-select' : 'linha-nav'} />
        </li>
        <li className="item-li" onClick={generateUser}>
          {complete === 3 ? (
            <MdDone size={18} color={darkmode ? '#40C9F3' : '00b7ef'} />
          ) : (
            <div
              className={select === 3 ? 'circle-nav-select' : 'circle-nav'}
            />
          )}
          <Link
            className={select === 3 ? 'text-select text-1' : 'text text-1'}
            to="/profile/docs"
          >
            Documentos
          </Link>
          <Link className="icon-nav icon-sub icon-top" to="/profile/docs">
            <MdAttachFile
              size={25}
              color={select !== 3 ? '#b8b8b9' : colors[color]}
            />
          </Link>
        </li>
      </ul>
    </nav>
  )
}

SubNav.defaultProps = {
  select: 1,
  complete: 0
}

SubNav.propTypes = {
  select: PropTypes.number,
  complete: PropTypes.number,
  generateUser: PropTypes.func.isRequired,
  contractingModel: PropTypes.string.isRequired
}

export default SubNav
