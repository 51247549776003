import React from 'react'
import Modal from 'react-modal'
import { Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { Head } from 'react-static'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import dotenv from 'dotenv'
import { hotjar } from 'react-hotjar'

import './config/ReactotronConfig'
import Routes from './routes'
import { history } from './services'
import { store, persistor } from './store'

import FeedbackBtn from './components/FeebackButton'
import ToastContainer from './components/ToastContainer'

import 'react-toastify/dist/ReactToastify.css'
import './Global.css'
import './darkmode.css'

dotenv.config()

if (process.env.NODE_ENV !== 'development') {
  hotjar.initialize(2004555, 6)

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
  })

  // const { location } = window

  // if (location.protocol !== 'https:') {
  //   location.replace(
  //     `https:${location.href.substring(location.protocol.length)}`
  //   )
  // }
}

Modal.setAppElement('#root')

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <ToastContainer />
          <Routes />
          <FeedbackBtn />
          <Head>
            <title>Memorian</title>
          </Head>
        </Router>
      </PersistGate>
    </Provider>
  )
}

export default App
