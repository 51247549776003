import React from 'react'
import Proptypes from 'prop-types'
import { MdFolder } from 'react-icons/md'

import { Folder } from './styled-components'

function FolderFC({ title, onClick }) {
  return (
    <Folder role="button" tabIndex={0} onClick={onClick}>
      <MdFolder size={30} />
      <span>{title}</span>
    </Folder>
  )
}

FolderFC.propTypes = {
  title: Proptypes.string.isRequired,
  onClick: Proptypes.func.isRequired
}

export default FolderFC
