const verifyCheck = ({ value, array }) => {
  let check = false
  for (let i = 0; i <= array.length; i += 1) {
    if (array[i] === value) {
      check = true
      break
    }
  }
  return check
}

export { verifyCheck }
