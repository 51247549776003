import React from 'react'
import PropTypes from 'prop-types'

import './styles.css'
import './darkmode.css'

function Clock({ darkmode }) {
  const [seconds, setSeconds] = React.useState('')
  const [minutes, setMinutes] = React.useState('')
  const [hours, setHours] = React.useState('')

  const zero = (number) => {
    return number < 10 ? `0${number}` : number
  }

  const runClock = () => {
    const date = new Date()

    let _hours = date.getUTCHours() - 3
    let _minutes = date.getUTCMinutes()
    let _seconds = date.getUTCSeconds()

    setHours(zero(_hours))
    setMinutes(zero(_minutes))
    setSeconds(zero(_seconds))
  }

  setTimeout(() => {
    runClock()
  }, 1000)

  return (
    <div className={`${darkmode && 'darkmode'} clock-component`}>
      <span>{`${hours}:${minutes}:${seconds}`}</span>
    </div>
  )
}

Clock.propTypes = {
  darkmode: PropTypes.bool.isRequired
}

export default Clock
