import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Tabs } from 'antd'
import { Head } from 'react-static'
import { Header, NavHome } from '../../components'
// import { Container } from './styles';

import Geral from './Geral'
import Folders from './Folders'

import './style.css'
import './darkmode.css'

const { TabPane } = Tabs

function CentralFiles() {
  const darkmode = useSelector((state) => state.user.darkmode)
  const [active, setActive] = useState('1')

  return (
    <div className={`${darkmode && 'darkmode'} global-container`}>
      <Head>
        <title>Central de arquivos</title>
      </Head>
      <Header />
      <div className="global-content content-white content-fixed-size">
        <NavHome active={2} progress={0} />
        <Tabs defaultActiveKey="1" onChange={(e) => setActive(e)}>
          <TabPane
            tab={
              <div className="tab-antd">
                <span>Arquivos públicos</span>
              </div>
            }
            key="1"
          >
            {active === '1' && <Geral />}
          </TabPane>
          <TabPane
            tab={
              <div className="tab-antd">
                <span>Pastas compartilhadas</span>
              </div>
            }
            key="2"
          >
            {active === '2' && <Folders />}
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default CentralFiles
