import { toast } from 'react-toastify'

import { api } from '../../../services'

const getTeams = async ({ company, setTeams, setCurrent }) => {
  await api
    .get('/team')
    .then(({ data }) => {
      setTeams(data.filter((item) => item.company === company))
    })
    .catch(() => {
      toast.error('Ops...Encontramos um problema por aqui. Tente novamente.')
    })
  if (company === 'Usemobile' || company === 'iTECOOP') setCurrent(0)
}

export { getTeams }
