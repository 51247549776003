import { api } from '../../../services'

const handleOpen = async ({
  from,
  setUser,
  setModalIsOpen,
  handleCloseModal
}) => {
  if (handleCloseModal) {
    handleCloseModal()
  }
  await api.get(`/teamMember/${from}`).then(({ data }) => {
    if (data !== null) {
      setUser(data)
      setModalIsOpen(true)
    }
  })
}

export { handleOpen }
