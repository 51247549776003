import { api } from '../../../services'
import { checkStatusCode } from '../../../utils'

const getUserFiles = async ({ reloadPhotos, dispatch, setAvatars }) => {
  if (reloadPhotos) {
    await api
      .get('/file/myFiles')
      .then(({ data }) => {
        setAvatars(data)
      })
      .catch((reject) => checkStatusCode(reject, dispatch))
  }
}

export { getUserFiles }
