import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { useDispatch } from 'react-redux'
import InputMask from 'react-input-mask'
import PropTypes, { shape } from 'prop-types'

import ErrorIcon from '../../../../assets/icons/Error'

import { requestWorkingSchedule } from './functions/requestWorkScheduleForce'
import useGlobal from '../../../../hooks/useGlobal'

import './darkmode.css'
import './styles.css'

function ModalPendingCorrection({ modalIsOpen, data, darkmode, actionClose }) {
  const [hours, setHours] = useState([])

  const [_global] = useGlobal()
  const { profile, id, dateNow } = _global

  const dispatch = useDispatch()

  useEffect(() => {
    setHours(data.hours)
  }, [data])

  const changePendingHour = (key, item, e) => {
    let { value } = e.target
    setHours(hours.map((a) => (a === item ? { ...a, [key]: value } : a)))
  }

  const handleSubmit = async () => {
    requestWorkingSchedule({
      date: data.date,
      hours,
      id,
      profile,
      dispatch,
      dateNow
    }).then((_) => actionClose())
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      className={`${darkmode && 'darkmode'} modal modal-pending`}
      overlayClassName="modal-overlay"
    >
      <div className="modal-pending-items">
        <span className="modal-title">Pendência de novo ajuste</span>

        <div className="pending-texts font-17">
          <div className="negative-pending">
            <ErrorIcon darkmode={darkmode} size={20} />
            <span className="text-pending-bold">Ajuste negado:</span>
          </div>

          <span className="text-pending-bold">{data.date}</span>
        </div>

        <p className="pending-paragraph text-pending-bold">
          Sua solicitação de ajuste de ponto foi negada. Para normalizar seu
          banco de horas, insira outro horário para nova análise.
        </p>

        <div className="pending-texts">
          <span className="text-pending-bold">Justificativa:</span>
          <p>{data.reason}</p>
        </div>

        <div className="pending-text-mail">
          <p>Se desejar, entre em contato através do endereço de e-mail:</p>
          <a href="mailto:memorian@usemobile.com.br">
            memorian@usemobile.com.br
          </a>
        </div>

        <ul className="pending-table">
          <li className="table-hours-pending">
            <span>Entrada</span>
            <span>Saída</span>
          </li>

          {hours.length > 0 && (
            <>
              {hours.map((i) => (
                <li className="table-hours-pending">
                  {i.acceptChangeIn === false ? (
                    <InputMask
                      type="text"
                      value={i.in}
                      placeholder="Digite"
                      onChange={(e) => changePendingHour('in', i, e)}
                      mask="99:99:99"
                    />
                  ) : (
                    <span>{i.in}</span>
                  )}
                  {i.acceptChangeOut === false ? (
                    <InputMask
                      type="text"
                      value={i.out}
                      placeholder="Digite"
                      onChange={(e) => changePendingHour('out', i, e)}
                      mask="99:99:99"
                    />
                  ) : (
                    <span>{i.out}</span>
                  )}
                </li>
              ))}
            </>
          )}
        </ul>

        <button
          className="button-primary button-pending-modal"
          onClick={handleSubmit}
        >
          Enviar
        </button>
      </div>
    </Modal>
  )
}

ModalPendingCorrection.defaultProps = {
  modalIsOpen: false
}

ModalPendingCorrection.propTypes = {
  modalIsOpen: PropTypes.bool,
  darkmode: PropTypes.bool.isRequired,
  data: PropTypes.objectOf(shape({})).isRequired,
  actionClose: PropTypes.func.isRequired
}

export default ModalPendingCorrection
