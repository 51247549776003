import cepPromise from 'cep-promise'

export const validateCep = async ({
  cep,
  setCepInvalid,
  setCity,
  setCountry,
  setUf,
  setCepLoader
}) => {
  if (cep !== '' && cep !== '_____-___') {
    setCepLoader(true)
    await cepPromise(cep).then(
      (response) => {
        setCity(response.city)
        setUf(response.state)
        if (setCountry) {
          setCountry('Brasil')
        }
        setCepInvalid(false)
        setCepLoader(false)
      },
      (error) => {
        setCepInvalid(true)
        setCepLoader(false)
      }
    )
  }
}
