import React from 'react'
import PropTypes from 'prop-types'

import { Player } from 'video-react'
import PDFComponent from '../../PDFComponent'
import ImageView from '../../ImageView'

function RenderView({ typeFile, link }) {
  return (
    <>
      {typeFile === 'pdf' && <PDFComponent link={link} />}
      {typeFile === 'video' && (
        <Player autoPlay src={link} fluid={false} width="100%" height={500} />
      )}
      {typeFile === 'image' && <ImageView url={link} />}
    </>
  )
}

RenderView.propTypes = {
  typeFile: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
}

export default RenderView
