import React from 'react'
import { MdDescription } from 'react-icons/md'
import PropTypes from 'prop-types'
import PrettyBytes from 'pretty-bytes'

import CloseIcon from '../../assets/icons/CloseIcon.svg'

import './styles.css'
import './darkmode.css'

function DownloadComponent({
  percentage,
  completed,
  sizeTotal,
  nameFile,
  darkmode,
  handleCancel
}) {
  return (
    <div className={`${darkmode && 'darkmode'} download-component`}>
      <MdDescription size={30} />
      <div className="infos-download">
        <button className="close-download" type="button">
          <img src={CloseIcon} alt="x" />
        </button>

        <span className="span-download-component">{nameFile}</span>
        <span>{`${PrettyBytes(completed)} de ${PrettyBytes(sizeTotal)}`}</span>
        <div className="download-bar">
          <div className="bar-blue-infos" style={{ width: `${percentage}%` }} />
        </div>
        <button
          className="onlyText-btn"
          type="button"
          onClick={() => handleCancel()}
        >
          Cancelar
        </button>
      </div>
    </div>
  )
}

DownloadComponent.defaultProps = {
  percentage: 0,
  completed: 0,
  sizeTotal: 0,
  nameFile: '',
  darkmode: false,
  handleCancel: null
}

DownloadComponent.propTypes = {
  percentage: PropTypes.number,
  completed: PropTypes.number,
  sizeTotal: PropTypes.number,
  nameFile: PropTypes.string,
  darkmode: PropTypes.bool,
  handleCancel: PropTypes.func
}

export default DownloadComponent
