import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MdCheck } from 'react-icons/md'
import { ClipLoader } from 'react-spinners'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import { api } from '../../services'
import { uploadFile, checkStatusCode } from '../../utils'

import StyledBasicInput from '../StyledBasicInput'
import InputFile from '../InputFiles'
import Crop from '../Crop'
import ModalGalery from '../ModalGalery'
import TwoFields from './TwoFiedls'
import ToastCustom from '../ToastCustom'

import { getProfile, getUserFiles } from './functions'

import CloseIcon from '../../assets/icons/CloseIcon.svg'

import './styles.css'
import './darkmode.css'

function EditorProfileComponent({ actionClose, darkmode, onSet, changeIn }) {
  const dispatch = useDispatch()
  const editor = React.useRef(null)
  const inputFile = React.useRef(null)

  const memberId = useSelector((state) => state.auth.sessionToken)

  const [onEditAvatar, setOnEditAvatar] = React.useState(false)

  const [thumbnail, setThumbnail] = React.useState(null)
  const [avatar, setAvatar] = React.useState('')
  const [viewAvatar, setViewUrl] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [scale, setScale] = React.useState(1)
  const [avatars, setAvatars] = React.useState([])
  const [galeryOpen, setGaleryOpen] = React.useState(false)
  const [selectItem, setSelectItem] = React.useState('')
  const [reloadPhotos, setReloadPhotos] = React.useState(true)

  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [nickname, setNickname] = React.useState('')
  const [hometown, setHomeTown] = React.useState('')
  const [linkedinUrl, setLinkedinUrl] = React.useState('')
  const [instagramUrl, setInstagramUrl] = React.useState('')
  const [course, setCourse] = React.useState('')
  const [institution, setInstitution] = React.useState('')
  const [favoriteFood, setFavoriteFood] = React.useState('')
  const [favoriteDrink, setFavoriteDrink] = React.useState('')
  const [favoriteSport, setFavoriteSport] = React.useState('')
  const [musicStyles, setMusicStyles] = React.useState('')
  const [seriesIndication, setSeriesIndication] = React.useState('')
  const [hobbies, setHobbies] = React.useState('')
  const [appIdeia, setAppIdeia] = React.useState('')

  const getData = (user) => {
    const firstN = user.name.split(' ')[0]
    const lastN = user.name.split(' ').pop()
    setAvatar(user.avatar)
    setFirstName(firstN)
    setLastName(lastN)
    setNickname(user.nickname)
    setHomeTown(user.hometown)
    setLinkedinUrl(user.linkedinUrl)
    setInstagramUrl(user.instagramUrl)
    setCourse(user.course)
    setInstitution(user.institution)
    setFavoriteFood(user.favoriteFood)
    setFavoriteDrink(user.favoriteDrink)
    setFavoriteSport(user.favoriteSport)
    setMusicStyles(user.musicStyles)
    setSeriesIndication(user.seriesIndication)
    setHobbies(user.hobbies)
    setAppIdeia(user.appIdeia)
  }

  const getAvatar = async (key) => {
    const response = await api.get(`/file/${key}`)
    if (typeof response === 'string') return response.data
    return response.data
  }

  React.useEffect(() => {
    getProfile({ memberId, dispatch, getData })
  }, [memberId])

  React.useEffect(() => {
    const getView = async () => {
      const res = await getAvatar(avatar)
      setViewUrl(res)
    }
    getView()
  }, [avatar])

  React.useEffect(() => {
    getUserFiles({ reloadPhotos, dispatch, setAvatars })
  }, [reloadPhotos, avatar])

  const getAccount = () => {
    return {
      name: `${firstName} ${lastName}`,
      avatar,
      nickname,
      hometown,
      linkedinUrl,
      instagramUrl,
      course,
      institution,
      favoriteFood,
      favoriteDrink,
      favoriteSport,
      musicStyles,
      seriesIndication,
      hobbies,
      appIdeia,
      memberId
    }
  }

  const saveProfile = async () => {
    const account = getAccount()
    setLoading(true)
    await api
      .post('/teamMember/update', account)
      .then((res) => {
        if (onSet) {
          onSet(!changeIn)
        } else {
          window.location.reload()
        }
        setLoading(false)
        actionClose()
        toast.success(<ToastCustom text="Informações alteradas com sucesso." />)
      })
      .catch((reject) => {
        checkStatusCode(reject)
        setLoading(false)
      })
  }

  const handleSetAvatar = async (e) => {
    const { files } = e.target
    setThumbnail(files[0])
    setOnEditAvatar(true)
  }

  const handleSelect = (e) => {
    setSelectItem(e.fileName)
  }

  const handleSaveFromGalery = async () => {
    const url = await getAvatar(selectItem)
    setAvatar(selectItem)
    setViewUrl(url)
    setGaleryOpen(false)
  }

  const handleAdjust = async () => {
    const response = await getAvatar(avatar)
    const xhr = new XMLHttpRequest()
    xhr.open('GET', response)
    xhr.responseType = 'blob'
    xhr.onload = () => {
      const type = {
        type: 'image/png'
      }
      let blob = new Blob([xhr.response], type)
      let linkImage = URL.createObjectURL(blob)
      setViewUrl(linkImage)
      setOnEditAvatar(true)
    }
    xhr.send()
  }

  const handleSaveAvatar = async () => {
    if (editor !== null) {
      const canvas = editor.current.getImage()
      canvas.toBlob(
        async (blob) => {
          const nameFile = thumbnail === null ? 'new_photo' : thumbnail.name
          setLoading(true)
          const key = `memorian_${Date.now()}_${nameFile}`
          const res = await uploadFile(blob, key)
          await api
            .post('/file', {
              typeFile: 'avatar',
              fileName: res.Key,
              path: res.Location
            })
            .catch((reject) => checkStatusCode(reject))
          const url = await getAvatar(key)
          setAvatar(key)
          setViewUrl(url)
          setLoading(false)
          setOnEditAvatar(false)
          setReloadPhotos(true)
        },
        'image/png',
        0.2
      )
    }
  }

  return (
    <>
      <Crop
        onEditAvatar={onEditAvatar}
        thumbnail={thumbnail}
        fileUrl={viewAvatar}
        editor={editor}
        handleFinalizedEdit={() => setOnEditAvatar(false)}
        loading={loading}
        handleSaveAvatar={handleSaveAvatar}
        darkmode={darkmode}
        scale={scale}
        setScale={setScale}
      />
      <ModalGalery
        galeryOpen={galeryOpen}
        actionClose={() => setGaleryOpen(false)}
        onSelectItem={handleSelect}
        actionSave={handleSaveFromGalery}
        avatars={avatars}
        darkmode={darkmode}
      />
      <div className="fields-profile-editor">
        <button
          className="close-modal-edit"
          type="button"
          onClick={actionClose}
        >
          <img src={CloseIcon} alt="x" />
        </button>
        <span className="modal-title title-editor">Quem sou eu</span>
        <div className="avatar-and-fields">
          <div className="input-file-avatar">
            <InputFile
              avatar_url={viewAvatar}
              ref={inputFile}
              onChange={handleSetAvatar}
              key="memorian"
              buttonGalery={avatars.length >= 2}
              type_file="avatar"
              actionOpenModal={() => setGaleryOpen(true)}
              adjustPhoto={handleAdjust}
            />
          </div>

          <div className="fields-4">
            <div className="input-editor-profile">
              <StyledBasicInput
                label="Nome *"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="input-editor-profile ">
              <StyledBasicInput
                label="Sobrenome *"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="input-editor-profile ">
              <StyledBasicInput
                label="Apelido"
                value={nickname}
                onChange={(e) => setNickname(e.target.value)}
              />
            </div>
            <div className="input-editor-profile">
              <StyledBasicInput
                label="Cidade Natal"
                value={hometown}
                onChange={(e) => setHomeTown(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="input-editor-profile editor-mobile">
          <TwoFields
            label1="URL do perfil no likedin"
            label2="URL do perfil no instagram"
            value1={linkedinUrl}
            setValue1={setLinkedinUrl}
            value2={instagramUrl}
            setValue2={setInstagramUrl}
          />
        </div>

        <span className="modal-title title-editor">Formação</span>
        <TwoFields
          label1="Curso"
          label2="Instituição"
          value1={course}
          setValue1={setCourse}
          value2={institution}
          setValue2={setInstitution}
        />
        <span className="modal-title title-editor">Um pouco sobre mim</span>
        <TwoFields
          label1="Comida preferida"
          label2="Uma bebida"
          value1={favoriteFood}
          setValue1={setFavoriteFood}
          value2={favoriteDrink}
          setValue2={setFavoriteDrink}
        />
        <div className="input-editor-profile editor-mobile">
          <StyledBasicInput
            label="Esporte preferido"
            value={favoriteSport}
            onChange={(e) => setFavoriteSport(e.target.value)}
          />
        </div>

        <div className="input-editor-profile editor-mobile">
          <StyledBasicInput
            label="Estilos musicais preferidos"
            value={musicStyles}
            onChange={(e) => setMusicStyles(e.target.value)}
          />
        </div>

        <div className="input-editor-profile editor-mobile">
          <StyledBasicInput
            label="Séries/filmes que indico"
            value={seriesIndication}
            onChange={(e) => setSeriesIndication(e.target.value)}
          />
        </div>

        <div className="input-editor-profile editor-mobile">
          <StyledBasicInput
            label="Meus hobbies"
            value={hobbies}
            onChange={(e) => setHobbies(e.target.value)}
          />
        </div>

        <div className="input-editor-profile editor-mobile">
          <StyledBasicInput
            label="Se fosse criar um app, criaria um que"
            value={appIdeia}
            onChange={(e) => setAppIdeia(e.target.value)}
          />
        </div>
      </div>
      <div className="button-section">
        <div className="btn-group group-modal-editor">
          <button className="button-secondary" onClick={actionClose}>
            Cancelar
          </button>
          <button className="button-primary" onClick={saveProfile}>
            Confirmar
            {loading ? (
              <div className="loader-margin">
                <ClipLoader size={20} color={darkmode ? '#1a1a1a' : '#fff'} />
              </div>
            ) : (
              <MdCheck size={20} style={{ marginLeft: '8px' }} />
            )}
          </button>
        </div>
      </div>
    </>
  )
}

EditorProfileComponent.defaultProps = {
  onSet: null,
  changeIn: false
}

EditorProfileComponent.propTypes = {
  actionClose: PropTypes.func.isRequired,
  darkmode: PropTypes.bool.isRequired,
  onSet: PropTypes.func,
  changeIn: PropTypes.bool
}

export default EditorProfileComponent
