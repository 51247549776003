import React from 'react'
import Modal from 'react-modal'
import PropTypes, { shape } from 'prop-types'

import Avatar from './Avatar'

import { formatDateParse } from '../../utils'

import CloseIcon from '../../assets/icons/CloseIcon.svg'

function Feedback({
  item,
  modalIsOpen,
  actionClose,
  darkmode,
  user,
  formatNumber
}) {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={actionClose}
      className={`${darkmode && 'darkmode'} modal`}
      overlayClassName="modal-overlay"
    >
      <>
        <div className="title-exit">
          <span className="modal-title title-feedback">{`Feedback ${formatNumber(
            item.number
          )}`}</span>
          <button className="exit-fb" onClick={actionClose}>
            <img src={CloseIcon} alt="x" />
          </button>
        </div>
        <div className="user-feedback-section">
          <Avatar user={item.user} />
          <div className="user-feed-section-infos">
            <span className={`name-user-feedback ${user.tag}-text`}>
              {user.name}
            </span>
            <span className="span-data-feedback">
              {formatDateParse(item.createdAt)}
            </span>
          </div>
        </div>
        <div className="text-feedback-section">
          <p>{item.feedback}</p>
        </div>
      </>
    </Modal>
  )
}

Feedback.propTypes = {
  item: PropTypes.objectOf(shape({})).isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  actionClose: PropTypes.func.isRequired,
  darkmode: PropTypes.bool.isRequired,
  user: PropTypes.objectOf(shape({})).isRequired,
  formatNumber: PropTypes.func.isRequired
}

export default Feedback
