import aws from 'aws-sdk'

import uploadConfig from '../config/upload'

const s3 = new aws.S3(uploadConfig)
s3.config.setPromisesDependency()

export const uploadFile = (file, key) => {
  const bucket =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_DEV_BUCKET
      : process.env.REACT_APP_PROD_BUCKET

  const options = {
    Bucket: bucket,
    Body: file,
    ACL: 'private',
    Key: key
  }
  return s3.upload(options).promise()
}
