import React from 'react'
import PropTypes from 'prop-types'
import StyledBasicInput from '../StyledBasicInput'

import './styles.css'

function TwoFields({ label1, label2, value1, setValue1, value2, setValue2 }) {
  return (
    <div className="two-fields">
      <StyledBasicInput
        label={label1}
        value={value1}
        onChange={(e) => setValue1(e.target.value)}
      />
      <StyledBasicInput
        label={label2}
        value={value2}
        onChange={(e) => setValue2(e.target.value)}
      />
    </div>
  )
}

TwoFields.propTypes = {
  label1: PropTypes.string.isRequired,
  label2: PropTypes.string.isRequired,
  value1: PropTypes.element.isRequired,
  setValue1: PropTypes.func.isRequired,
  value2: PropTypes.element.isRequired,
  setValue2: PropTypes.func.isRequired
}

export default TwoFields
