/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Overlay } from 'react-bootstrap'
import PropTypes, { shape } from 'prop-types'

import './styles.css'
import './darkmode.css'

function Poppover({ target, show, setShow, darkmode }) {
  return (
    <Overlay target={target.current} show={show} placement="right-start">
      {({ placement, arrowProps, show: _show, popper, ...props }) => (
        <div
          {...props}
          style={{
            ...props.style
          }}
          className={`${darkmode && 'darkmode'} popover-div`}
        >
          <div className="text-and-btn">
            <p className="text-popover">
              Navegue pelas seções que desejar alterar e, ao terminar, clique em
              Salvar ao final da página.
            </p>
          </div>
          <button onClick={() => setShow(false)} className="btn-popover">
            Entendi
          </button>
        </div>
      )}
    </Overlay>
  )
}

Poppover.propTypes = {
  target: PropTypes.objectOf(shape({})).isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  darkmode: PropTypes.bool.isRequired
}

export default Poppover
