import React from 'react'
import PropTypes, { shape } from 'prop-types'
import PaginationBTN from '../PaginationBTN'

import './styles.css'
import './darkmode.css'

function Pagination({
  page,
  numberPages,
  onClickNext,
  onClickPrev,
  onChange,
  dataNumbers
}) {
  return (
    <div className="pagination">
      <PaginationBTN
        left
        onClickNext={onClickNext}
        onClickPrev={onClickPrev}
        page={page}
        numberPages={numberPages}
      />
      <div className="pagination-center">
        <span className="pagination-item">Página</span>
        <select
          value={page}
          onChange={onChange}
          className="pagination-select pagination-item"
        >
          {dataNumbers.map((item) => (
            <option value={item.value} key={item.value}>
              {item.value}
            </option>
          ))}
        </select>
        <span className="pagination-item">{`de ${numberPages}`}</span>
      </div>
      <PaginationBTN
        rigth
        onClickNext={onClickNext}
        onClickPrev={onClickPrev}
        page={page}
        numberPages={numberPages}
      />
    </div>
  )
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  numberPages: PropTypes.number.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onClickPrev: PropTypes.func.isRequired,
  dataNumbers: PropTypes.arrayOf(shape([])).isRequired,
  onChange: PropTypes.func.isRequired
}

export default Pagination
