import React, { useState } from 'react'
import Modal from 'react-modal'
import { MdCheckCircle } from 'react-icons/md'
import PropTypes, { shape } from 'prop-types'

import GaleryCard from '../GaleryCard'

import CloseIcon from '../../assets/icons/CloseIcon.svg'

import './styles.css'
import './darkmode.css'

function ModalGalery({
  galeryOpen,
  actionClose,
  onSelectItem,
  actionSave,
  avatars,
  darkmode
}) {
  const [selected, setSelected] = useState(null)

  const handleSelect = (item, index) => {
    setSelected(index)
    return onSelectItem(item)
  }

  return (
    <Modal
      isOpen={galeryOpen}
      onRequestClose={actionClose}
      className={`${darkmode && 'darkmode'} modal modal-galery`}
      overlayClassName="modal-overlay"
    >
      <>
        <div className="title-exit">
          <span className="modal-title title-galery">
            Selecionar uma foto existente
          </span>
          <button className="exit" onClick={actionClose}>
            <img src={CloseIcon} alt="x" />
          </button>
        </div>
        <span className="span-galery button-center">
          Selecione uma foto pré-existente da sua galeria:
        </span>

        <section className="section-photos">
          <div
            className={
              avatars.length === 2
                ? 'photos-user photos-no-betwen'
                : 'photos-user photos-with-betwen'
            }
          >
            {avatars.map((photo, index) => (
              <div className="photos-dad">
                <GaleryCard
                  file={photo.fileName}
                  selected={selected}
                  index={index}
                  actionSelect={() => handleSelect(photo, index)}
                />
                {selected === index && (
                  <div className="photos-check">
                    <MdCheckCircle size={24} color="#00b7ef" />
                  </div>
                )}
              </div>
            ))}
          </div>
        </section>
        <button onClick={actionSave} className="button-primary button-center">
          Salvar
        </button>
      </>
    </Modal>
  )
}

ModalGalery.propTypes = {
  galeryOpen: PropTypes.bool.isRequired,
  actionClose: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  actionSave: PropTypes.func.isRequired,
  avatars: PropTypes.arrayOf(shape([])).isRequired,
  darkmode: PropTypes.bool.isRequired
}

export default ModalGalery
