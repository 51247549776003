import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md'
import { Head } from 'react-static'
import { ClickAwayListener } from '@material-ui/core'
import debounce from 'lodash.debounce'

import {
  Header,
  ListCourses,
  NavHome,
  SearchInput,
  EmptyWindown,
  ButtonsTopSelect,
  CheckItem,
  Pagination,
  ModalAlert
} from '../../components'
import {
  checkUserFiveMinutesOff,
  handleClickNext,
  handleClickPrev,
  handlePage
} from '../../functions'
import { getCourses, getPlatformsAndAreas } from './functions'
import { validateArr } from '../../utils'

import useGlobal from '../../hooks/useGlobal'

import { inVocationFunc } from '../../store/modules/user/actions'

import './styles.css'
import './darkmode.css'

export default function Courses() {
  const [courses, setCourses] = useState([])
  const [platform, setPlatform] = useState([])
  const [area, setArea] = useState([])
  const [title, setTitle] = useState('')
  const [progress, setProgress] = useState(0)
  const [loaded, setLoaded] = useState(false)

  const [dropOpen, setDropOpen] = useState(false)
  const [dropOpen2, setDropOpen2] = useState(false)

  const [filters, setFilters] = useState([])
  const [page, setPage] = useState(1)
  const [numberPages, setNumberPages] = useState(0)
  const [dataNumbers, setDataNumbers] = useState([])
  const [docsNumber, setDocsNumber] = useState(0)

  const [platforms, setPlatforms] = useState([])
  const [areas, setAreas] = useState([])
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [search, setSearch] = useState('')

  const [_global] = useGlobal()

  const { darkmode, hourEntry, profileWorking, inVocation } = _global

  const dispatch = useDispatch()

  const verify = useCallback(
    debounce((item) => setSearch(item), 500),
    []
  )

  useEffect(() => {
    getCourses({
      area,
      platform,
      search,
      page,
      dispatch,
      setProgress,
      setNumberPages,
      setDataNumbers,
      setDocsNumber,
      setCourses,
      setLoaded
    })
  }, [area, platform, search, page])

  useEffect(() => {
    getPlatformsAndAreas({ setAreas, setPlatforms })
  }, [])

  useEffect(() => {
    checkUserFiveMinutesOff({
      profileWorking,
      hourEntry,
      inVocation,
      setModalIsOpen
    })
  }, [profileWorking.working, hourEntry, inVocation])

  const changeInitialPage = () => {
    if (page > 1) {
      setPage(1)
    }
  }

  const handleChangeSelect = (value, array) => {
    const arr = [...filters]
    const newarr = array.filter((item) => {
      return item.value === value
    })
    arr.push(newarr[0].value)
    setFilters(arr)
  }

  const removeItemFilter = (array, setHook, indice) => {
    const arr = [...array]
    arr.splice(indice, 1)
    setHook(arr)
  }

  const handleRemoveItem = (i, value) => {
    const oldArr = [...filters]
    const platformIndice = platform.indexOf(value)
    const areaIndice = area.indexOf(value)
    if (platformIndice >= 0) {
      removeItemFilter(platform, setPlatform, platformIndice)
    }
    if (areaIndice >= 0) {
      removeItemFilter(area, setArea, areaIndice)
    }
    oldArr.splice(i, 1)
    setFilters(oldArr)
  }

  const handleSelect = (refArr, setHook, value, arrayItems) => {
    const arr = validateArr({ refArr, value })
    const filter = validateArr({ refArr: filters, value })
    handleChangeSelect(value, arrayItems)
    setHook(arr)
    setFilters(filter)
    changeInitialPage()
  }

  const handleClear = (setHook, array) => {
    let arr = [...filters]
    for (let i = 0; i <= array.length; i += 1) {
      arr = arr.filter((item) => {
        return item !== array[i]
      })
      setFilters(arr)
    }
    setHook([])
  }

  const handleSelectAll = (arrRef, setHook, arrRef2) => {
    if (arrRef.length !== arrRef2.length) {
      let arr = arrRef.map((item) => {
        return item.value
      })
      const newarr = [...arr, ...filters]
      const newfilters = newarr.filter((este, i) => newarr.indexOf(este) === i)
      setFilters(newfilters)
      setHook(arr)
    }
  }

  const handleClearFilters = () => {
    setFilters([])
    setTitle('')
    setSearch('')
    setArea([])
    setPlatform([])
  }
  /**
   * Render page
   */

  const actionVocation = () => {
    dispatch(inVocationFunc())
    setModalIsOpen(false)
  }

  const handleChangeTitle = (e) => {
    const { value } = e.target
    setTitle(value.toLowerCase())
    verify(value)
    changeInitialPage()
  }

  const handleClickAway = () => {
    setDropOpen(false)
  }

  const handleClickAway2 = () => {
    setDropOpen2(false)
  }

  return (
    <div className={`${darkmode && 'darkmode'} global-container`}>
      <Head>
        <title>Cursos</title>
      </Head>
      <Header />
      <ModalAlert
        modalisOpen={modalIsOpen}
        actionClose={() => setModalIsOpen(false)}
        actionVocation={actionVocation}
      />
      <div className="global-content content-white content-fixed-size">
        <NavHome active={3} progress={progress} />

        <div className="search-area-files">
          <SearchInput
            placeholder="Pesquisar"
            value={title}
            onChange={handleChangeTitle}
            className="search-input-files"
          />
          <div className="search-area">
            <span className="filter-span">Filtrar:</span>
            <div className="selects-filters">
              <ClickAwayListener onClickAway={handleClickAway}>
                <div className="item-flex-filters">
                  <div
                    className="box-select"
                    onClick={() => setDropOpen(!dropOpen)}
                    role="button"
                    tabIndex={0}
                  >
                    <span className="box-span">Plataforma</span>
                    {dropOpen ? (
                      <MdArrowDropUp size={30} color="#757575" />
                    ) : (
                      <MdArrowDropDown size={30} color="#757575" />
                    )}
                  </div>
                  <ul className={!dropOpen ? 'drop-list' : 'drop-list-open'}>
                    <ButtonsTopSelect
                      actionSelect={() =>
                        handleSelectAll(platforms, setPlatform, platform)
                      }
                      actionClear={() => handleClear(setPlatform, platform)}
                    />

                    {platforms.map((item, index) => (
                      <CheckItem
                        key={item.value}
                        item={item}
                        index={index}
                        array={platform}
                        handleClick={() =>
                          handleSelect(
                            platform,
                            setPlatform,
                            item.value,
                            platforms
                          )
                        }
                      />
                    ))}
                  </ul>
                </div>
              </ClickAwayListener>
              <ClickAwayListener onClickAway={handleClickAway2}>
                <div className="item-flex-filters">
                  <div
                    className="box-select"
                    onClick={() => setDropOpen2(!dropOpen2)}
                    role="button"
                    tabIndex={0}
                  >
                    <span className="box-span">Área</span>
                    {dropOpen2 ? (
                      <MdArrowDropUp size={30} color="#757575" />
                    ) : (
                      <MdArrowDropDown size={30} color="#757575" />
                    )}
                  </div>
                  <ul className={!dropOpen2 ? 'drop-list' : 'drop-list-open'}>
                    <ButtonsTopSelect
                      actionSelect={() => handleSelectAll(areas, setArea, area)}
                      actionClear={() => handleClear(setArea, area)}
                    />
                    {areas.map((item, index) => (
                      <CheckItem
                        key={item.value}
                        item={item}
                        index={index}
                        array={area}
                        handleClick={() =>
                          handleSelect(area, setArea, item.value, areas)
                        }
                        darkmode={darkmode}
                      />
                    ))}
                  </ul>
                </div>
              </ClickAwayListener>
            </div>
          </div>
        </div>

        <div className="caminho-filtros">
          {title !== '' && (
            <div>
              <span className="strong-title">Você pesquisou por:</span>
              <strong className="strong-title">{` "${title}"`}</strong>
              {title !== '' && filters.length >= 1 && (
                <span className="strong-title"> em:</span>
              )}
            </div>
          )}

          {filters.map((item, index) => (
            <div className="items-select" key={item}>
              <p className="filters-selects-texts">{item}</p>
              <button
                onClick={() => handleRemoveItem(index, item)}
                className="btn-x"
              >
                x
              </button>
            </div>
          ))}
          {(title !== '' || filters.length >= 1) && (
            <span className="data-length">{`(${docsNumber} ${
              docsNumber > 1 ? 'resultados' : 'resultado'
            })`}</span>
          )}

          {(filters.length >= 1 || title !== '') && (
            <button className="clear-btn" onClick={handleClearFilters}>
              Limpar
            </button>
          )}
        </div>
        {loaded && (
          <>
            {courses.length > 0 ? (
              <ListCourses data={courses} />
            ) : (
              <EmptyWindown text="curso" darkmode={darkmode} />
            )}
          </>
        )}
        {(docsNumber > 15 || page >= 2) && (
          <Pagination
            page={page}
            numberPages={numberPages}
            onClickNext={() => handleClickNext({ page, setPage })}
            onClickPrev={() => handleClickPrev({ page, setPage })}
            dataNumbers={dataNumbers}
            onChange={(e) => handlePage({ e, setPage })}
          />
        )}
      </div>
    </div>
  )
}
