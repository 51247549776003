import React from 'react'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import PropTypes from 'prop-types'

import './styles.css'
import './darkmode.css'

function PaginationBTN({
  left,
  rigth,
  onClickNext,
  onClickPrev,
  page,
  numberPages
}) {
  return (
    <>
      {left && (
        <div
          className={
            page === 1 ? 'btn-pagination disabled-btn' : 'btn-pagination'
          }
        >
          <MdChevronLeft size={25} />
          <button disabled={page === 1} onClick={onClickPrev}>
            Anterior
          </button>
        </div>
      )}
      {rigth && (
        <div
          className={
            page === numberPages
              ? 'btn-pagination disabled-btn'
              : 'btn-pagination'
          }
        >
          <button disabled={page === numberPages} onClick={onClickNext}>
            Próxima
          </button>
          <MdChevronRight size={25} />
        </div>
      )}
    </>
  )
}

PaginationBTN.defaultProps = {
  left: false,
  rigth: false
}

PaginationBTN.propTypes = {
  left: PropTypes.bool,
  rigth: PropTypes.bool,
  onClickNext: PropTypes.func.isRequired,
  onClickPrev: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  numberPages: PropTypes.number.isRequired
}

export default PaginationBTN
