/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react'
import { MdNavigateBefore, MdCheck, MdChevronRight } from 'react-icons/md'
import { useSelector, useDispatch } from 'react-redux'

import {
  Header,
  NavProfile,
  SubNav,
  StyledBasicInput,
  StyledSelect,
  ModalCancelRegister,
  StyledRadio
} from '../../../../components'

import { history } from '../../../../services'
import { updateUser } from '../../../../store/modules/user/actions'

import './styles.css'

function Academics() {
  const darkmode = useSelector((state) => state.user.darkmode)

  const [disabled, setDisabled] = useState(true)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [course, setCourse] = useState('')
  const [half, setHalf] = useState('')
  const [instituition, setInstituition] = useState('')
  const [yearOfEntry, setYearOfEntry] = useState('')
  const [graduate, setGraduate] = useState('')
  const [graduateYear, setGraduateYear] = useState('')

  const profile = useSelector((select) => select.user.profile)

  const { contractingModel } = profile

  const dispatch = useDispatch()

  const date = new Date()
  const yearNow = date.getFullYear()

  const anos = []
  for (let i = 2010; i <= yearNow; i++) {
    anos.push({ value: i })
  }

  useEffect(() => {
    const checkEmptyInputs = () => {
      if (
        course !== '' &&
        half !== '' &&
        instituition !== '' &&
        yearOfEntry !== ''
      ) {
        setDisabled(false)
      } else {
        setDisabled(true)
      }
    }
    checkEmptyInputs()
  }, [course, half, instituition, yearOfEntry])

  const completeTo = async (userData) => {
    setCourse(userData.course)
    setInstituition(userData.instituition)
    setYearOfEntry(userData.yearOfEntry)
    setGraduateYear(userData.graduateYear)
    setGraduate(userData.graduate)
    setHalf(userData.half)
  }

  useEffect(() => {
    let user = JSON.parse(sessionStorage.getItem('user'))
    completeTo(user)
  }, [profile])

  const generateUser = () => {
    let user = JSON.parse(sessionStorage.getItem('user'))
    user = {
      ...user,
      course,
      half,
      instituition,
      yearOfEntry,
      graduate,
      graduateYear
    }
    sessionStorage.setItem('user', JSON.stringify(user))
  }

  const handleReturn = () => {
    generateUser()
    history.push('/profile/1')
  }

  const handleCancel = () => {
    setModalIsOpen(false)
    sessionStorage.removeItem('user')
    history.push('/')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await generateUser()
    if (profile.finalized) {
      const userStorage = JSON.parse(sessionStorage.getItem('user'))
      dispatch(updateUser(userStorage))
    } else {
      history.push('/profile/docs')
    }
  }

  return (
    <>
      <ModalCancelRegister
        open={modalIsOpen}
        actionClose={() => setModalIsOpen(false)}
        action={handleCancel}
        typeCancel={profile.finalized ? 'as edições' : 'o cadastro'}
      />
      <div className={`${darkmode && 'darkmode'} global-container`}>
        <Header />
        <div className="global-content global-content-grey">
          {profile.finalized ? (
            <span className="title">MEU PERFIL</span>
          ) : (
            <span className="title">CADASTRO</span>
          )}
          <hr className="title-bar" />
          <NavProfile active={1} generateUser={generateUser} />

          <SubNav
            complete={1}
            select={2}
            generateUser={generateUser}
            contractingModel={contractingModel}
          />

          <form onSubmit={handleSubmit}>
            <div className="title-top">
              <span className="title-min">Dados acadêmicos</span>
              <hr className="title-bar" />
            </div>
            <div className="area-grid">
              <StyledBasicInput
                label="Curso *"
                value={course}
                onChange={(e) => setCourse(e.target.value)}
              />
              <div className="select-y">
                <StyledSelect
                  data={anos}
                  label="Ano de ingresso *"
                  holder="Selecione"
                  value={yearOfEntry}
                  onChange={(e) => setYearOfEntry(e.target.value)}
                />
              </div>

              <div className="input-radio-gp-academic">
                <label className="radios-label">Semestre de ingresso *</label>
                <div className="radios-1">
                  <div className="btn-and-label">
                    <StyledRadio
                      value={half}
                      setValue={setHalf}
                      markValue="1"
                    />
                    <label className="label-radio">1</label>
                  </div>
                  <div className="btn-and-label second-radio">
                    <StyledRadio
                      value={half}
                      setValue={setHalf}
                      markValue="2"
                    />
                    <label className="label-radio">2</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="area-400 area-grid">
              <StyledBasicInput
                label="Instituição de ensino *"
                value={instituition}
                onChange={(e) => setInstituition(e.target.value)}
              />
              <div className="input-radio-gp-academic">
                <label className="radios-label">Concluído *</label>
                <div className="radios-1">
                  <div className="btn-and-label">
                    <StyledRadio
                      value={graduate}
                      setValue={setGraduate}
                      markValue
                    />
                    <label className="label-radio">Sim</label>
                  </div>
                  <div className="btn-and-label second-radio">
                    <StyledRadio
                      value={graduate}
                      setValue={setGraduate}
                      markValue={false}
                    />
                    <label className="label-radio">Não</label>
                  </div>
                </div>
              </div>
              {graduate !== '' && graduate !== false && (
                <div className="conclusion">
                  <StyledSelect
                    data={anos}
                    label="Ano de conclusão *"
                    holder="Selecione"
                    value={graduateYear}
                    onChange={(e) => setGraduateYear(e.target.value)}
                  />
                </div>
              )}
            </div>

            <div className="form-bottom">
              <button
                type="button"
                className="button-return"
                onClick={handleReturn}
              >
                <MdNavigateBefore size={20} />
                Voltar
              </button>

              <div className="btn-group">
                <button
                  type="button"
                  className="button-secondary btn-register-w"
                  onClick={() => setModalIsOpen(true)}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="button-primary btn-register-w"
                  disabled={disabled}
                >
                  {profile.finalized ? (
                    <>
                      Salvar{' '}
                      <MdCheck
                        color={darkmode ? '#1a1a1a' : '#fff'}
                        size={20}
                      />
                    </>
                  ) : (
                    <>
                      Avançar{' '}
                      <MdChevronRight
                        color={darkmode ? '#1a1a1a' : '#fff'}
                        size={20}
                      />
                    </>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Academics
