import moment from 'moment'
import { decryptKey } from '../../../utils'

const checkDiff = (data) => {
  const before = moment(data)
  const after = moment(new Date())
  const days = after.diff(before, 'days')
  return days >= 6
}

const getInCache = (data) => {
  return new Promise((resolve, reject) => {
    const name = `${data.name.split(' ')[0]}_${data.name.split(' ').pop()}`
    const JsonItem =
      localStorage.getItem(name) || JSON.stringify({ teste: 'no cache' })
    const item = JSON.parse(JsonItem)
    if (item) {
      const { avatar, url, createdAt } = item
      const decryptAvatar = decryptKey(avatar)
      const decryptUrl = decryptKey(url)
      if (decryptAvatar === data.avatar) {
        checkDiff(createdAt)
          ? reject(null)
          : resolve({
              avatar: decryptAvatar,
              url: decryptUrl
            })
      } else {
        reject(null)
      }
    } else {
      reject(null)
    }
  })
}

export { getInCache }
