import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md'
import { ClickAwayListener } from '@material-ui/core'
import debounce from 'lodash.debounce'

import {
  EmptyWindown,
  SearchInput,
  ListFiles,
  ButtonsTopSelect,
  CheckItem,
  Pagination,
  ModalAlert
} from '../../components'

import { inVocationFunc } from '../../store/modules/user/actions'
import {
  checkUserFiveMinutesOff,
  handleClickNext,
  handleClickPrev,
  handlePage
} from '../../functions'
import { loadFiles, loadAreas } from './functions'
import { validateArr } from '../../utils'
import useGlobal from '../../hooks/useGlobal'

import './style.css'
import './darkmode.css'

export default function CentralFiles() {
  const [data, setData] = useState([])
  const [title, setTitle] = useState('')
  const [area, setArea] = useState([''])
  const [progress, setProgress] = useState(0)
  const [areas, setAreas] = useState([])

  const [dropOpen, setDropOpen] = useState(false)

  const [filters, setFilters] = useState([])

  const [numberPages, setNumberPages] = useState(0)
  const [page, setPage] = useState(1)
  const [dataNumbers, setDataNumbers] = useState([])
  const [docsNumber, setDocsNumber] = useState(0)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [search, setSearch] = useState('')

  const [_global] = useGlobal()

  const { hourEntry, profileWorking, inVocation, darkmode } = _global

  const dispatch = useDispatch()

  const [loaded, setLoaded] = useState(false)

  const verify = useCallback(
    debounce((item) => setSearch(item), 500),
    []
  )

  useEffect(() => {
    loadFiles({
      search,
      area,
      page,
      dispatch,
      setProgress,
      setNumberPages,
      setDataNumbers,
      setDocsNumber,
      setData,
      setLoaded
    })
  }, [search, area, page])

  useEffect(() => {
    loadAreas({ setAreas })
  }, [])

  useEffect(() => {
    checkUserFiveMinutesOff({
      profileWorking,
      inVocation,
      hourEntry,
      setModalIsOpen
    })
  }, [profileWorking.working, hourEntry, inVocation])

  const changeInitialPage = () => {
    if (page > 1) {
      setPage(1)
    }
  }

  const handleChangeSelect = (value, array) => {
    const arr = [...filters]
    const label = array.filter((item) => {
      return item.value === value
    })
    arr.push(label[0].value)
    setFilters(arr)
  }

  const handleSelectAll = () => {
    const arr = areas.map((item) => {
      return item.value
    })
    setArea(arr)
    setFilters(arr)
  }

  const handleClear = () => {
    setArea([''])
    setFilters([])
  }
  const handleClearFilter = () => {
    setFilters([])
    setTitle('')
    setSearch('')
    handleClear()
  }

  const handleRemoveItem = (i) => {
    const oldArr = [...filters]
    oldArr.splice(i, 1)
    setFilters(oldArr)
    setArea(oldArr)
  }

  const handleSelect = (value) => {
    const arr = validateArr({ refArr: area, value })
    const filter = validateArr({ refArr: filters, value })
    handleChangeSelect(value, areas)
    setArea(arr)
    setFilters(filter)
    changeInitialPage()
  }

  const actionVocation = () => {
    dispatch(inVocationFunc())
    setModalIsOpen(false)
  }

  const handleChangeTitle = (e) => {
    const { value } = e.target
    setTitle(value.toLowerCase())
    verify(value)
    changeInitialPage()
  }

  const handleClickAway = () => {
    setDropOpen(false)
  }

  return (
    <>
      <ModalAlert
        modalisOpen={modalIsOpen}
        actionClose={() => setModalIsOpen(false)}
        actionVocation={actionVocation}
      />

      <div className="search-area-files">
        <SearchInput
          placeholder="Pesquisar"
          value={title}
          onChange={handleChangeTitle}
          className="search-input-files"
        />

        <div className="select-drop-area">
          <div className="search-area-filter">
            <span className="filter-text">Filtrar:</span>
            <ClickAwayListener onClickAway={handleClickAway}>
              <div className="item-flex-filters">
                <div
                  className="box-select"
                  onClick={() => setDropOpen(!dropOpen)}
                  role="button"
                  tabIndex={0}
                >
                  <span className="box-span">Área</span>
                  {dropOpen ? (
                    <MdArrowDropUp size={30} color="#757575" />
                  ) : (
                    <MdArrowDropDown size={30} color="#757575" />
                  )}
                </div>
                <ul className={!dropOpen ? 'drop-list' : 'drop-list-open'}>
                  <ButtonsTopSelect
                    actionSelect={handleSelectAll}
                    actionClear={handleClear}
                  />
                  {areas.map((item, index) => (
                    <CheckItem
                      item={item}
                      index={index}
                      array={area}
                      handleClick={() => handleSelect(item.value)}
                      darkmode={darkmode}
                    />
                  ))}
                </ul>
              </div>
            </ClickAwayListener>
          </div>
        </div>
      </div>

      <div className="caminho-filtros">
        {title !== '' && (
          <div>
            <span className="strong-title">Você pesquisou por:</span>
            <strong className="strong-title">{` "${title}"`}</strong>
            {title !== '' && filters.length >= 1 && (
              <span className="strong-title"> em:</span>
            )}
          </div>
        )}

        {filters.map((item, index) => (
          <div className="items-select">
            <p className="filters-selects-texts">{item}</p>
            <button onClick={() => handleRemoveItem(index)} className="btn-x">
              x
            </button>
          </div>
        ))}
        {(title !== '' || filters.length >= 1) && (
          <span className="data-length">{`(${docsNumber} ${
            docsNumber > 1 ? 'resultados' : 'resultado'
          })`}</span>
        )}

        {(filters.length >= 1 || title !== '') && (
          <button className="clear-btn" onClick={handleClearFilter}>
            Limpar
          </button>
        )}
      </div>

      {loaded && (
        <>
          {data.length > 0 ? (
            <ListFiles data={data} darkmode={darkmode} />
          ) : (
            <EmptyWindown text="arquivo" darkmode={darkmode} />
          )}
        </>
      )}
      {(docsNumber > 15 || page >= 2) && (
        <Pagination
          page={page}
          numberPages={numberPages}
          onClickNext={() => handleClickNext({ page, setPage })}
          onClickPrev={() => handleClickPrev({ page, setPage })}
          dataNumbers={dataNumbers}
          onChange={(e) => handlePage({ e, setPage })}
        />
      )}
    </>
  )
}
