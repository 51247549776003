import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  MdNavigateBefore,
  MdVisibilityOff,
  MdVisibility,
  MdCheck,
  MdChevronRight
} from 'react-icons/md'

import {
  Header,
  NavProfile,
  StyledBasicInput,
  ModalCancelRegister
} from '../../../components'

import { onlyNumbers, checkStatusCode } from '../../../utils'
import { api, history } from '../../../services'

import { updateUser } from '../../../store/modules/user/actions'

import './style.css'

export default function AcessData() {
  const darkmode = useSelector((state) => state.user.darkmode)

  const profile = useSelector((select) => select.user.profile)
  const user = JSON.parse(sessionStorage.getItem('user'))

  const [disabled, setDisabled] = useState(true)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const { acessCode } = profile
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [errrorPassword, setErrorPassword] = useState(false)
  const [errorMesssage, setErrorMessage] = useState('')
  const [visible, setVisible] = useState(false)

  const dispatch = useDispatch()

  /**
   * Complete inputs with state data
   */

  useEffect(() => {
    const validationInputs = () => {
      if (password !== confirmPassword && confirmPassword.length > 2) {
        setErrorPassword(true)
        setErrorMessage('As senhas não coincidem')
      } else {
        setErrorPassword(false)
        setErrorMessage('')
      }
      if (password !== '' && confirmPassword !== '') {
        setDisabled(false)
      } else {
        setDisabled(true)
      }
    }
    validationInputs()
  }, [password, confirmPassword])

  const completeTo = (data) => {
    setPassword(data.password)
    setConfirmPassword(data.confirmPassword)
  }

  useEffect(() => {
    const userdata = JSON.parse(sessionStorage.getItem('user-data'))
    if (userdata) {
      completeTo(userdata)
    }
  }, [])

  const handleCancel = () => {
    setModalIsOpen(false)
    sessionStorage.removeItem('user')
    history.push('/')
  }

  const generateData = () => {
    const userData = {
      password,
      confirmPassword
    }
    sessionStorage.setItem('user-data', JSON.stringify(userData))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await api
      .post('/profile/changePassword', {
        newPassword: password
      })
      .then(
        (res) => {
          generateData()
          if (profile.finalized) {
            const userStorage = JSON.parse(sessionStorage.getItem('user'))
            dispatch(updateUser(userStorage))
          } else {
            history.push('/profile/3')
            sessionStorage.setItem('user', JSON.stringify(user))
          }
        },
        (reject) => checkStatusCode(reject, dispatch)
      )
  }
  const handleReturn = () => {
    generateData()
    history.push('/profile/docs')
  }

  const handleVisible = (hook, setHook) => {
    setHook(!hook)
  }

  return (
    <>
      <ModalCancelRegister
        open={modalIsOpen}
        actionClose={() => setModalIsOpen(false)}
        action={handleCancel}
        typeCancel={profile.finalized ? 'as edições' : 'o cadastro'}
      />
      <div className={`${darkmode && 'darkmode'} global-container`}>
        <Header />

        <div className="global-content global-content-grey content-md-size">
          {profile.finalized ? (
            <span className="title">MEU PERFIL</span>
          ) : (
            <span className="title">CADASTRO</span>
          )}
          <hr className="title-bar" />
          <NavProfile active={2} generateUser={generateData} />

          <form
            className="form2"
            onSubmit={handleSubmit}
            autoComplete="new-password"
          >
            <div className="inputs-ad">
              <StyledBasicInput
                label="Seu código de acesso"
                value={acessCode}
                disabled
              />
              <div className="input-message-ad item-acess">
                <StyledBasicInput
                  type={visible ? 'text' : 'password'}
                  label="Senha *"
                  value={password}
                  error={errrorPassword}
                  maxLength={4}
                  onChange={(e) => setPassword(onlyNumbers(e.target.value))}
                  Icon={visible ? MdVisibilityOff : MdVisibility}
                  onVisible={() => handleVisible(visible, setVisible)}
                  showIcon={password.length >= 1}
                  autoComplete="new-password"
                />
                <span className="message-span-ad">*4 digítos numéricos</span>
              </div>
              <div className="item-acess">
                <StyledBasicInput
                  type={visible ? 'text' : 'password'}
                  label="Confirme a senha *"
                  maxLength={4}
                  value={confirmPassword}
                  error={errrorPassword}
                  errorMesage={errorMesssage}
                  onChange={(e) =>
                    setConfirmPassword(onlyNumbers(e.target.value))
                  }
                  Icon={visible ? MdVisibilityOff : MdVisibility}
                  onVisible={() => handleVisible(visible, setVisible)}
                  showIcon={confirmPassword.length >= 1}
                  autoComplete="new-password"
                />
              </div>
            </div>
            <div className="form-bottom">
              <button
                type="button"
                className="button-return"
                onClick={handleReturn}
              >
                <MdNavigateBefore size={20} />
                Voltar
              </button>

              <div className="btn-group">
                <button
                  type="button"
                  className="button-secondary btn-register-w"
                  onClick={() => setModalIsOpen(true)}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="button-primary btn-register-w"
                  disabled={disabled}
                >
                  {profile.finalized ? (
                    <>
                      Salvar
                      <MdCheck
                        color={darkmode ? '#1a1a1a' : '#fff'}
                        size={20}
                      />
                    </>
                  ) : (
                    <>
                      Avançar
                      <MdChevronRight
                        color={darkmode ? '#1a1a1a' : '#fff'}
                        size={20}
                      />
                    </>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
