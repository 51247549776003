import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MdSignalWifiOff } from 'react-icons/md'
import PropTypes from 'prop-types'
import { ClipLoader } from 'react-spinners'
import { Route, Redirect } from 'react-router-dom'

import { toast } from 'react-toastify'
import { store } from '../store'

import { checkToken } from '../utils'

export default function PrivateRoutes({
  component: Component,
  isAdmin,
  isClosed,
  ...rest
}) {
  const isLoggedIn = store.getState().auth.signed
  const profile = useSelector((state) => state.auth.profile)

  const dispatch = useDispatch()

  const [ofline, setOffline] = useState(false)
  useEffect(() => {
    const checkConnection = () => {
      if (!navigator.onLine) {
        setOffline(true)
      }
    }
    checkConnection()
  }, [Component])

  useEffect(() => {
    if (isLoggedIn) {
      checkToken(dispatch)
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (ofline) {
      toast.error(
        <div>
          <MdSignalWifiOff size={20} />
          <span>Ops parece que a conexão saiu para tomar um café.</span>
          <ClipLoader size={20} color="#fff" />
        </div>,
        {
          autoClose: false
        }
      )
    }
  }, [ofline])
  /**
   * Condition that defines which route the user will be redirected to
   */
  if (isClosed && !isLoggedIn) {
    return (
      <Redirect
        to={{ pathname: '/login', state: { prevPath: rest.location.pathname } }}
      />
    )
  }
  /**
   * Closed routes with access level
   */
  if (profile) {
    const { role } = profile
    if (isAdmin && role === 'user') {
      return <Redirect to="/" />
    }
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...rest} component={Component} />
}
/**
 * Properties
 */
PrivateRoutes.defaultProps = {
  isClosed: false,
  isAdmin: false
}
PrivateRoutes.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  isClosed: PropTypes.bool,
  isAdmin: PropTypes.bool
}
