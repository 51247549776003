import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getFolders } from './functions'
import FolderList from './components/FolderList'
import OpenFolder from './components/OpenFolder'
import { checkStatusCode } from '../../utils'

// import { Container } from './styles';

function CentralFiles() {
  const dispatch = useDispatch()

  const [data, setData] = useState([])
  const [open, setOpen] = useState(false)
  const [folder, setFolder] = useState({})

  useEffect(() => {
    const getData = async () => {
      await getFolders()
        .then((res) => setData(res))
        .catch((reject) => checkStatusCode(reject, dispatch))
    }
    getData()
  }, [])

  return (
    <>
      {!open ? (
        <FolderList setFolder={setFolder} setOpen={setOpen} data={data} />
      ) : (
        <OpenFolder folder={folder} returnAction={() => setOpen(false)} />
      )}
    </>
  )
}

export default CentralFiles
