export const officesVariables = {
  android: 'Desenvolvedor(a) Android',
  backend: 'Desenvolvedor(a) Backend',
  design: 'Designer',
  devops: 'Desenvolvedor(a) DevOps',
  flutter: 'Desenvolvedor(a) Flutter',
  frontend: 'Desenvolvedor(a) Front-end',
  fullstack: 'Desenvolvedor(a) Fullstack',
  ios: 'Desenvolvedor(a) iOS'
}
