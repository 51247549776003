import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Switch } from 'antd'
import {
  MdArrowDropDown,
  MdArrowDropUp,
  MdFace,
  MdGroupAdd
} from 'react-icons/md'
import { ClickAwayListener } from '@material-ui/core'

import AccountIcon from '../../assets/icons/editAccount.svg'
import EditPassword from '../../assets/icons/editPassword.svg'
import logoutIcon from '../../assets/icons/logout.svg'
import DarkmodeAccount from '../../assets/icons/darkmode-editAccount.svg'
import DarkmodeEditPassword from '../../assets/icons/darkmode-editPassword.svg'
import darkmodeLogout from '../../assets/icons/darkmode-logout.svg'

import ModalAlert from '../ModalAlert'
import ModalEditProfile from '../UserInfosModalEdit'

import { logOut } from '../../store/modules/auth/actions'
import {
  inVocationFunc,
  setDarkMode,
  setDarkModeOf
} from '../../store/modules/user/actions'

import './style.css'
import './darkmode.css'

export default function DropDown({ actionRemove, darkmode }) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false)
  const [dropActived, setDropActived] = useState(false)

  const hourEntry = useSelector((state) => state.auth.hourEntry)
  const profileWorking = useSelector((state) => state.user.profileWorking)
  const inVocation = useSelector((state) => state.user.inVocation)
  const profile = useSelector((state) => state.auth.profile)

  const dashboard_url = process.env.REACT_APP_DASHBOARD_URL

  useEffect(() => {
    const now = moment(new Date())
    const setDiff = () => {
      const duration = moment.duration(now.diff(hourEntry))
      const minutes = duration.asMinutes()
      if (minutes >= 5) {
        setModalIsOpen(true)
      }
    }
    if (!profileWorking.working && !inVocation && dropActived) {
      setDiff()
    }
  }, [profileWorking.working, inVocation, hourEntry, dropActived])

  const dispatch = useDispatch()

  /**
   * Triggers the logout action
   */
  const handleLogOut = () => {
    actionRemove()
    dispatch(logOut())
  }

  const actionVocation = () => {
    dispatch(inVocationFunc())
    setModalIsOpen(false)
  }

  const handleDarkMode = (checked) => {
    checked ? dispatch(setDarkMode()) : dispatch(setDarkModeOf())
  }

  return (
    <>
      <ModalAlert
        modalisOpen={modalIsOpen}
        actionClose={() => setModalIsOpen(false)}
        actionVocation={actionVocation}
      />

      <ModalEditProfile
        modalIsOpen={modalEditIsOpen}
        darkmode={darkmode}
        actionClose={() => setModalEditIsOpen(false)}
      />
      <ClickAwayListener onClickAway={() => setDropActived(false)}>
        <div>
          <div className="icons-dropdown">
            {dropActived ? (
              <MdArrowDropUp
                size={35}
                color="#fff"
                onClick={() => setDropActived(!dropActived)}
                style={{ cursor: 'pointer' }}
              />
            ) : (
              <MdArrowDropDown
                size={35}
                color="#fff"
                onClick={() => setDropActived(!dropActived)}
                style={{ cursor: 'pointer' }}
              />
            )}
          </div>

          <ul className={dropActived ? 'dropdown-ul' : 'dropdown-ul-disabled'}>
            <li onClick={() => setModalEditIsOpen(true)}>
              <MdFace size={22} color="#757575" />
              <span className="edit-perfil-span">Editar perfil</span>
            </li>
            <li onClick={actionRemove}>
              <img
                src={darkmode ? DarkmodeAccount : AccountIcon}
                alt="profile-edit"
              />
              <Link to="/profile/1">Atualizar cadastro</Link>
            </li>
            <li onClick={actionRemove}>
              <img
                src={darkmode ? DarkmodeEditPassword : EditPassword}
                alt="profile-edit"
              />
              <Link to="/user/forgot">Alterar senha</Link>
            </li>
            <li className="set-darkmode">
              <span id="dropdown-span">Modo escuro</span>
              <Switch onChange={handleDarkMode} checked={darkmode} />
            </li>
            {profile.role === 'admin' && (
              <li onClick={actionRemove}>
                <MdGroupAdd size={22} color="#757575" />
                <a href={dashboard_url} target="_blank" rel="noreferrer">
                  Módulo R.H
                </a>
              </li>
            )}

            <li onClick={handleLogOut}>
              <img src={darkmode ? darkmodeLogout : logoutIcon} alt="logout" />
              <span className="span-exit-drop">Sair</span>
            </li>
          </ul>
        </div>
      </ClickAwayListener>
    </>
  )
}
/**
 * Props validation
 */

DropDown.propTypes = {
  actionRemove: PropTypes.func.isRequired,
  darkmode: PropTypes.bool.isRequired
}
