import { checkStatusCode } from '../../../utils'
import { api } from '../../../services'

const getCourses = async ({
  area,
  platform,
  search,
  page,
  dispatch,
  setProgress,
  setNumberPages,
  setDataNumbers,
  setDocsNumber,
  setCourses,
  setLoaded
}) => {
  const config = {
    onDownloadProgress(progressEvent) {
      setProgress(
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      )
    }
  }
  const url = search
    ? `/course/filter?setor=${area}&instituition=${platform}&keyWords=${search}&page=${page}
    `
    : `/course/filter?setor=${area}&instituition=${platform}&page=${page}`
  await api.get(url, config).then(
    (resolve) => {
      const { docs, totalPages, totalDocs } = resolve.data
      setNumberPages(totalPages)
      const numbers = []
      for (let i = 1; i <= totalPages; i += 1) {
        const obj = { value: i }
        numbers.push(obj)
      }
      setDataNumbers(numbers)
      setDocsNumber(totalDocs)
      setCourses(docs)
    },
    (reject) => checkStatusCode(reject, dispatch)
  )
  setProgress(0)
  setLoaded(true)
}
export { getCourses }
