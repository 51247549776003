import { toast } from 'react-toastify'
import { checkStatusCode } from '../../../utils'
import { api, history } from '../../../services'

const forgotPassword = async ({ password, dispatch }) => {
  await api
    .post('/profile/changePassword', {
      newPassword: password
    })
    .then(
      (res) => {
        const userData = {
          password,
          confirmPassword: password
        }
        sessionStorage.setItem('user-data', JSON.stringify(userData))
        history.push('/')
        toast.success('Informações alteradas com sucesso.')
      },
      (reject) => checkStatusCode(reject, dispatch)
    )
}

export { forgotPassword }
