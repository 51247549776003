const returnCheckData = ({ dataReq, type, force }) => {
  const data = {}
  if (type === 'lunch') {
    data.checkoutForLunch = true
  }
  if (force) {
    data.force = true
  }
  return data
}

export { returnCheckData }
