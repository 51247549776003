import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import Feedback from '../Feedback'
import Pagination from '../Pagination'

import { getFeedbacks } from './functions'

import { handleClickNext, handleClickPrev, handlePage } from '../../functions'

import './styles.css'

function ListFeedbacks({ filed, tabChange, darkmode }) {
  const dispatch = useDispatch()

  const [data, setData] = React.useState([])
  const [change, setChange] = React.useState(true)
  const [page, setPage] = React.useState(1)
  const [numberPages, setNumberPages] = React.useState(0)
  const [dataNumbers, setDataNumbers] = React.useState([])
  const [docsNumber, setDocsNumber] = React.useState(0)

  React.useEffect(() => {
    getFeedbacks({
      filed,
      page,
      dispatch,
      setNumberPages,
      setDataNumbers,
      setDocsNumber,
      setData,
      setPage
    })
  }, [filed, change, tabChange, page])

  return (
    <div className="feedback-list">
      {data.map((item) => (
        <Feedback item={item} setChange={setChange} darkmode={darkmode} />
      ))}

      <div className="pagination-feedback">
        {(docsNumber > 15 || page >= 2) && (
          <Pagination
            page={page}
            numberPages={numberPages}
            onClickNext={() => handleClickNext({ page, setPage })}
            onClickPrev={() => handleClickPrev({ page, setPage })}
            dataNumbers={dataNumbers}
            onChange={(e) => handlePage({ e, setPage })}
          />
        )}
      </div>
    </div>
  )
}

ListFeedbacks.propTypes = {
  filed: PropTypes.bool.isRequired,
  tabChange: PropTypes.bool.isRequired,
  darkmode: PropTypes.bool.isRequired
}

export default ListFeedbacks
