import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { MdVisibilityOff, MdVisibility, MdCheck } from 'react-icons/md'
import { ClipLoader } from 'react-spinners'
import { onlyNumbers } from '../../utils'

import { Header, StyledBasicInput, ModalCancelRegister } from '../../components'

import { history } from '../../services'

import useGlobal from '../../hooks/useGlobal'

import { forgotPassword, checkValidate } from './functions'

import './styles.css'
import './darkmode.css'

function ForgotInMenu() {
  const dispatch = useDispatch()
  const [_global] = useGlobal()
  const { darkmode, profile } = _global
  const userdata = JSON.parse(sessionStorage.getItem('user-data'))

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [atualPass, setAtualPass] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [visible, setVisible] = useState(false)
  const [errorAtualPass, setErrorAtualPass] = useState(false)
  const [errorPass, setErrorPass] = useState(false)
  const [loading, setLoading] = useState(false)

  const errorMessages = {
    confirmErr: 'As senhas não coicidem.'
  }

  const handleVisible = (hook, setHook) => {
    setHook(!hook)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setErrorPass(false)
    setErrorAtualPass(false)
    const _errorAtualPass = checkValidate({
      password: atualPass,
      comparePassword: userdata.password,
      setErrorHook: setErrorAtualPass,
      setLoading
    })
    const errorConfirmPass = checkValidate({
      password,
      comparePassword: confirmPassword,
      setErrorHook: setErrorPass,
      setLoading
    })
    if (_errorAtualPass || errorConfirmPass) return
    await forgotPassword({ password, dispatch })
    setLoading(false)
  }

  const handleCancel = () => {
    history.push('/')
    setModalIsOpen(false)
  }

  return (
    <div className={`${darkmode && 'darkmode'} global-container`}>
      <ModalCancelRegister
        open={modalIsOpen}
        actionClose={() => setModalIsOpen(false)}
        typeCancel="a edição de senha"
        action={handleCancel}
      />
      <Header />
      <div className="global-content global-content-grey content-md-size">
        <span className="title">Redefinição de senha</span>
        <hr className="title-bar" />
        <form
          className="form-forgot-menu"
          onSubmit={handleSubmit}
          autoComplete="new-password"
          autoSave="off"
        >
          <div className="inputs-forgot-menu">
            <div className="item-forgot">
              <StyledBasicInput
                label="Código de acesso"
                value={profile.acessCode}
                disabled
              />
            </div>
            <div className="item-forgot">
              <StyledBasicInput
                type={visible ? 'text' : 'password'}
                label="Senha atual"
                value={atualPass}
                maxLength={4}
                Icon={visible ? MdVisibilityOff : MdVisibility}
                onVisible={() => handleVisible(visible, setVisible)}
                onChange={(e) => setAtualPass(onlyNumbers(e.target.value))}
                showIcon={atualPass.length >= 1}
                error={errorAtualPass}
                errorMesage="Senha errada"
                autoComplete="new-password"
              />
            </div>
            <div className="item-forgot">
              <div className="input-forgot-with-span">
                <StyledBasicInput
                  type={visible ? 'text' : 'password'}
                  label="Nova senha"
                  value={password}
                  maxLength={4}
                  Icon={visible ? MdVisibilityOff : MdVisibility}
                  onVisible={() => handleVisible(visible, setVisible)}
                  onChange={(e) => setPassword(onlyNumbers(e.target.value))}
                  showIcon={password.length >= 1}
                  error={errorPass}
                  autoComplete="new-password"
                />
                <span className="label-forgot-password-menu">
                  *4 digítos numéricos.
                </span>
              </div>
            </div>
            <div className="item-forgot">
              <StyledBasicInput
                type={visible ? 'text' : 'password'}
                label="Confirmar a nova senha"
                value={confirmPassword}
                maxLength={4}
                Icon={visible ? MdVisibilityOff : MdVisibility}
                onVisible={() => handleVisible(visible, setVisible)}
                onChange={(e) =>
                  setConfirmPassword(onlyNumbers(e.target.value))
                }
                showIcon={confirmPassword.length >= 1}
                error={errorPass}
                errorMesage={errorMessages.confirmErr}
                autoComplete="new-password"
              />
            </div>
          </div>
          <div className="button-group-forgot-menu">
            <button
              type="button"
              className="button-secondary btn-forgot-menu"
              onClick={() => setModalIsOpen(true)}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="button-primary btn-forgot-menu"
              disabled={
                (atualPass.length &&
                  password.length &&
                  confirmPassword.length) < 4
              }
            >
              Salvar
              {loading ? (
                <div className="loader-margin">
                  <ClipLoader size={20} color="#fff" />
                </div>
              ) : (
                <MdCheck size={20} style={{ marginLeft: '8px' }} />
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ForgotInMenu
