import { toast } from 'react-toastify'
import { api } from '../../../services'

const forgotPassword = async ({
  acessCode,
  loginError,
  setEmailUser,
  setModalIsOpen,
  setAcessCodeToForgot,
  setInvalidACLogin,
  setLoginError
}) => {
  await api.post('/forgot', { acessCode }).then(
    (resolve) => {
      const { email } = resolve.data
      if (email) {
        setEmailUser(email)
        setModalIsOpen(true)
      }
    },
    (error) => {
      setAcessCodeToForgot(true)
      setInvalidACLogin(true)
      toast.error('Ops...Encontramos um problema por aqui. Tente novamente.')
      if (loginError) {
        setLoginError(false)
      }
    }
  )
}

export { forgotPassword }
