import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PDFViewer from 'mgr-pdf-viewer-react'
import PropTypes from 'prop-types'
import { GridLoader } from 'react-spinners'

import { api } from '../../services'

import './styles.css'

function PDFViewerThumb({ link, handleClick }) {
  const darkmode = useSelector((state) => state.user.darkmode)
  const [url, setUrl] = useState(process.env.REACT_APP_DEFAULT_FILE)

  useEffect(() => {
    const getAPI = async () => {
      await api.get(`/file/${link}`).then((response) => {
        const url_video = response.data
        setUrl(url_video)
      })
    }
    getAPI()
  }, [link])
  return (
    <div className="miniatura" onClick={handleClick} role="button" tabIndex={0}>
      <PDFViewer
        document={{
          url
        }}
        scale={0.08}
        loader={
          <div className="loader-grid">
            <GridLoader size={10} color={darkmode ? '#BFBFBF' : '#00b7ef'} />
          </div>
        }
        hideNavbar
      />
    </div>
  )
}

PDFViewerThumb.propTypes = {
  link: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired
}

export default PDFViewerThumb
