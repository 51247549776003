import React, { useState, useEffect } from 'react'
import VideoThumbnail from 'react-video-thumbnail'
import PropTypes from 'prop-types'

import { api } from '../../services'

import './styles.css'

function VideoThumbnailComponent({ link, handleClick }) {
  const [url, setUrl] = useState('')

  useEffect(() => {
    const getAPI = async () => {
      await api.get(`/file/${link}`).then((response) => {
        const url_video = response.data
        setUrl(url_video.toString())
      })
    }
    getAPI()
  }, [link])

  return (
    <div className="thumb" onClick={handleClick} role="button" tabIndex={0}>
      <VideoThumbnail
        videoUrl={url}
        width={150}
        height={100}
        cors
        renderThumbnail
      />
    </div>
  )
}

VideoThumbnailComponent.propTypes = {
  link: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired
}

export default VideoThumbnailComponent
