const verifyPass = (item1, item2) => {
  return item1 !== item2
}

const checkValidate = ({
  password,
  comparePassword,
  setErrorHook,
  setLoading
}) => {
  const validatePass = verifyPass(password, comparePassword)
  if (validatePass) {
    setErrorHook(true)
    setLoading(false)
  }
  return validatePass
}

export { checkValidate }
