/* eslint-disable import/no-cycle */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import Depoiment from '../Depoiment'
import UserInfosModal from '../UserInfosModal'

import { getDepoiments, handleDelete, handleOpen } from './functions'

import './styles.css'
import './darkmode.css'

function Depoiments({ userId, change, darkmode }) {
  const dispatch = useDispatch()

  const [depoiments, setDepoiments] = React.useState([])
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const [user, setUser] = React.useState({})

  const memberId = useSelector((state) => state.auth.sessionToken)

  React.useEffect(() => {
    getDepoiments({ userId, change, dispatch, setDepoiments })
  }, [userId, change])

  const handleCloseModal = () => {
    setUser({})
    setModalIsOpen(false)
  }

  return (
    <>
      <UserInfosModal
        modalIsOpen={modalIsOpen}
        actionClose={handleCloseModal}
        user={user}
      />
      <div className="depoiment-lists">
        {depoiments.map((item, index) => (
          <Depoiment
            item={item}
            memberId={memberId}
            handleDelete={() =>
              handleDelete({ id: item._id, index, depoiments, setDepoiments })
            }
            darkmode={darkmode}
            handleOpenProfile={() =>
              handleOpen({
                from: item.from,
                setUser,
                setModalIsOpen,
                handleCloseModal
              })
            }
          />
        ))}
      </div>
    </>
  )
}

Depoiments.defaultProps = {
  change: false
}

Depoiments.propTypes = {
  userId: PropTypes.string.isRequired,
  change: PropTypes.bool,
  darkmode: PropTypes.bool.isRequired
}

export default Depoiments
