import { decryptKey, setAvatar } from '../../../utils'
import { saveInCache } from '../../Avatar/functions/saveInCache'
import { api } from '../../../services'

const getInCacheDp = (data) => {
  return new Promise((resolve) => {
    const name = `${data.name.split(' ')[0]}_${data.name.split(' ').pop()}`
    const JsonItem = localStorage.getItem(name)
    const item = JSON.parse(JsonItem)
    if (item) {
      const { avatar, url } = item
      const decryptAvatar = decryptKey(avatar)
      const decryptUrl = decryptKey(url)
      resolve({
        avatar: decryptAvatar,
        url: decryptUrl
      })
    } else {
      api.get(`/teamMember/${data.memberId}`).then((result) => {
        const user =
          result.data === null
            ? {
                name: data.name,
                avatar: ''
              }
            : result.data
        setAvatar(user).then((res) => {
          const newData = { avatar: '', url: res, name: user.name }
          saveInCache(res, newData)
          resolve(newData)
        })
      })
    }
  })
}

export { getInCacheDp }
