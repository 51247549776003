import { memorianAPI } from '../../../../../services'
import { sendMailToRh } from '../../../../../components/BlocoHours/functions'
import { getWorkingHoursReq } from '../../../../../store/modules/user/actions'

const _ApplicationId = process.env.REACT_APP_APLICATION_ID

export const requestWorkingSchedule = async ({
  date,
  hours,
  id,
  profile,
  dispatch,
  dateNow
}) => {
  const dataObj = {
    _ApplicationId,
    date,
    hours,
    member: id,
    offset: 0,
    force: true
  }
  const { name, avatar_url } = profile
  return new Promise((resolve, reject) => {
    memorianAPI
      .post('/use/functions/requestWorkScheduleCorrection', dataObj)
      .then(() => {
        sendMailToRh({ name, avatar: avatar_url }).then(() => {
          dispatch(getWorkingHoursReq(dateNow, id))
          resolve({ sucess: 'ok' })
        })
      })
      .catch((err) => reject(err))
  })
}
