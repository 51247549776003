/**
 * Functon called by Infos Page
 * @param {Object} user - User's Object
 */
export function updateUser(user) {
  return {
    type: '@user/UPDATE_USER',
    payload: { user }
  }
}
/**
 * Fuction called by saga
 * @param {Object} user - User's data
 */

export function updateUserSucess(user) {
  return {
    type: '@user/UPDATE_USER_SUCESS',
    payload: { user }
  }
}

/**
 * Time bank search request called from the home page
 * @param {string} date - date time
 * @param {string} id - user id in the memorian hour bank
 */
export function getWorkingHoursReq(date, id) {
  return {
    type: '@user/GET_WORKING_HOURS',
    payload: { date, id }
  }
}
/**
 * Successful action to search for data in the bank of hours called by the saga
 * @param {Object} user - User's data
 */
export function getWorkingHoursSucess(user) {
  return {
    type: '@user/GET_WORKING_HOURS_SUCESS',
    payload: { user }
  }
}

export function checkOut(hourEntry) {
  return {
    type: '@working/CHECK_OUT',
    payload: { hourEntry }
  }
}

export function inVocationFunc() {
  return {
    type: '@working/IN_VOCATION'
  }
}

export function setDarkMode() {
  return {
    type: '@layout/DARKMODE'
  }
}

export function setDarkModeOf() {
  return {
    type: '@layout/DARKMODE_OF'
  }
}

export function getNotificationsReq(id) {
  return {
    type: '@user/GET_NOTIFICATIONS_REQ',
    payload: { id }
  }
}

export function getNotificationsSuccess(notifications, notRead) {
  return {
    type: '@user/GET_NOTIFICATIONS_SUCCESS',
    payload: { notifications, notRead }
  }
}

export function markAsReadReq(items) {
  return {
    type: '@user/MARK_NOTIFICATIONS_AS_READ_REQ',
    payload: { items }
  }
}

export function markAsRead() {
  return {
    type: '@user/MARK_NOTIFICATIONS_AS_READ'
  }
}

export function selectDate(date) {
  return {
    type: '@user/SELECT_DATE',
    payload: { date }
  }
}
