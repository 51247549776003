/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash.debounce'

import { Tabs } from 'antd'

import SearchInput from '../AsyncSearch'
import UserInfosModal from '../UserInfosModal'
import Company from '../Company'

import { checkStatusCode } from '../../utils'
import { api } from '../../services'

function Team() {
  const darkmode = useSelector((state) => state.user.darkmode)
  const dispatch = useDispatch()
  const { TabPane } = Tabs

  const [data, setData] = React.useState([])
  const [user, setUser] = React.useState({})
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const [searchName, setSearchName] = React.useState('')
  const [companys, setCompanys] = React.useState([])
  const [company, setCompany] = React.useState('Usemobile')
  const [search, setSearch] = React.useState('')

  const verify = React.useCallback(
    debounce(async (value) => {
      await api
        .get(`/teamMember/user?filter=${value}&fields=name`)
        .then((res) => {
          const { docs } = res.data
          setData(docs.filter((item) => item.company === company))
        })
        .catch((reject) => checkStatusCode(reject, dispatch))
    }, 500),
    []
  )

  React.useEffect(() => {
    const getCompanys = async () => {
      await api
        .get('/company')
        .then((res) => {
          setCompanys(res.data)
        })
        .catch((reject) => checkStatusCode(reject, dispatch))
    }
    getCompanys()
  }, [])

  const searchUser = (e) => {
    const { value } = e.target
    setSearchName(value)
    if (value.length > 0) {
      verify(value)
    } else {
      setData([])
    }
  }

  const handleResetStates = () => {
    setData([])
    setSearchName('')
  }

  const handleOpenModal = (item) => {
    setUser(item)
    setModalIsOpen(true)
    handleResetStates()
  }

  const handleSetCompany = (key) => {
    key = parseInt(key, 10)
    const i = companys.findIndex((item) => item.key === key)
    setCompany(companys[i].name)
  }

  return (
    <div className="team-component">
      <div className="team-component-search" style={{ marginBottom: '15px' }}>
        <SearchInput
          placeholder="Pesquisar"
          options={data}
          onChange={searchUser}
          actionClick={handleOpenModal}
          value={searchName}
          clickOut={handleResetStates}
          darkmode={darkmode}
        />
      </div>

      <UserInfosModal
        modalIsOpen={modalIsOpen}
        actionClose={() => setModalIsOpen(false)}
        user={user}
      />
      <Tabs onTabClick={(key) => handleSetCompany(key)}>
        {companys.map((item) => (
          <TabPane
            tab={
              <button className="tab-antd" type="button">
                <span>{item.name}</span>
              </button>
            }
            key={item.key}
            destroyInactiveTabPane
          >
            {company === item.name && <Company company={item.name} />}
          </TabPane>
        ))}
      </Tabs>
    </div>
  )
}

export default Team
