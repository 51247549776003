/* eslint-disable radix */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import './styles.css'
import './darkmode.css'

function Timer() {
  const darkmode = true
  const workingProfile = useSelector((state) => state.user.profileWorking)
  const { hours, working, hoursPerDay } = workingProfile
  const [minutes, setMinutes] = useState('00')
  const [hour, setHour] = useState('00')
  const [seconds, setSeconds] = useState('00')
  const initTimer = () => {
    const date = new Date()
    const day = date.getDate()
    hours.map((item) => {
      if (item.day === day) {
        const lastArr = [...item.hours].pop()
        const newHour = lastArr.in.split(':')

        const total = item.total.split(':')
        const totalHours = total[0] * 3600 * 1000
        const totalMinutes = total[1] * 60 * 1000
        const totalSeconds = total[2] * 1000

        const totalGeral = totalHours + totalMinutes + totalSeconds

        const field3 = newHour[2].split('h')
        const hToMs = newHour[0] * 3600 * 1000
        const mToMS = newHour[1] * 60 * 1000
        const sToMs = field3[0] * 1000
        const hNowToMs = date.getHours() * 3600 * 1000
        const mNowToMs = date.getMinutes() * 60 * 1000
        const sNowToMS = date.getSeconds() * 1000

        const hmsToMs = hToMs + mToMS + sToMs
        const hmsNowToMs = hNowToMs + mNowToMs + sNowToMS
        const difTimer = hmsNowToMs - hmsToMs + totalGeral

        let sTimer = difTimer / 1000
        let hTimer = parseInt(sTimer / 3600)
        sTimer %= 3600
        let mTimer = parseInt(sTimer / 60)
        sTimer %= 60
        sTimer = sTimer < 10 ? `0${sTimer}` : sTimer
        mTimer = mTimer < 10 ? `0${mTimer}` : mTimer
        hTimer = hTimer < 10 ? `0${hTimer}` : hTimer
        setSeconds(sTimer)
        setMinutes(mTimer)
        setHour(hTimer)
      }
    })
  }
  if (working) {
    setTimeout(() => {
      initTimer()
    }, 1000)
  }
  const CountTime = () => {
    const htimer = parseInt(hour, 10)

    const item = (
      <div className="timer-item">
        <p
          className={`${darkmode && 'darkmode'} ${
            htimer >= hoursPerDay ? 'count' : 'count-negative'
          }`}
        >{` Jornada atual: ${hour}h e ${minutes}min`}</p>
        <p className="seconds-timer">{seconds}</p>
      </div>
    )
    return item
  }
  return (
    <>
      <CountTime />
    </>
  )
}
export default Timer
