import React from 'react'
import Modal from 'react-modal'
import { useSelector } from 'react-redux'
import { MdError } from 'react-icons/md'
import PropTypes from 'prop-types'
import './styles.css'

function ModalCancelRegister({ open, action, actionClose, typeCancel }) {
  const darkmode = useSelector((state) => state.user.darkmode)
  return (
    <Modal
      isOpen={open}
      onRequestClose={actionClose}
      className={`${darkmode && 'darkmode'} modal modal-top`}
      overlayClassName="modal-overlay"
    >
      <>
        <span className="modal-title">Cancelar</span>
        <div className="modal-items">
          <MdError size={30} color={darkmode ? '#FF796B' : '#E74C3C'} />
          <p className="modal-text-forgot">
            Deseja realmente cancelar {typeCancel}?
            <br />
            Você perderá as informações preenchidas até aqui.
          </p>
        </div>
        <div className="button-modal-2 btn-cancel-register">
          <button className="button-secondary" onClick={actionClose}>
            Voltar
          </button>
          <button className="button-primary" onClick={action}>
            Sim, quero cancelar
          </button>
        </div>
      </>
    </Modal>
  )
}

ModalCancelRegister.defaultProps = {
  open: false
}

ModalCancelRegister.propTypes = {
  open: PropTypes.bool,
  action: PropTypes.func.isRequired,
  actionClose: PropTypes.func.isRequired,
  typeCancel: PropTypes.string.isRequired
}

export default ModalCancelRegister
