/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { MdStar } from 'react-icons/md'
import PropTypes from 'prop-types'
import { GridLoader } from 'react-spinners'

import { formatName } from '../../utils'
import { api } from '../../services'
import { getInCache } from '../Avatar/functions/getInCache'
import { saveInCache } from '../Avatar/functions/saveInCache'

import { officesVariables } from './Vars'

import MoneyOutline from '../../assets/moneyOut.svg'

import './styles.css'
import './darkmode.css'

function CardTeam({ item, team }) {
  const [url, setUrl] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const getAvatar = async (name, avatar) => {
    const nameUser = name.split(' ')
    const data = {
      name,
      avatar
    }
    setLoading(true)
    getInCache(data)
      .then((cache) => setUrl(cache.url))
      .catch((_) => {
        if (!avatar || avatar === '') {
          setUrl(
            `https://avatar.oxro.io/avatar.svg?name=${nameUser[0]}+${nameUser[1]}&background=f6f6f6&length=2&color=00b7ef`
          )
        } else {
          api
            .get(`/file/${avatar}`)
            .then((res) => {
              saveInCache(res.data, data)
              setUrl(res.data)
            })
            .catch((err) => console.error(err))
        }
      })
    setLoading(false)
  }

  React.useEffect(() => {
    getAvatar(item.name, item.avatar)
  }, [item.avatar, item.name])

  return (
    <div className="card-team-item">
      <div className="card-top">
        {loading ? (
          <div className="loader-img card-team-avatar">
            <GridLoader size={10} color={team.color} />
          </div>
        ) : (
          <div
            className="card-team-avatar"
            style={{
              backgroundImage: `url(${url})`
            }}
          />
        )}
        {item.lead && <MdStar color={team.color} />}
        <span
          className={
            item.lead
              ? 'card-team-name'
              : 'card-team-name card-team-name-margin'
          }
        >
          {item.name ? formatName(item.name) : ''}
        </span>
        <span className="user-office">
          {officesVariables[item.team] || item.office}
        </span>
      </div>

      <div className="card-coins" style={{ backgroundColor: team.color }}>
        <img src={MoneyOutline} alt="money_icon" />
        <span>{item.coins}</span>
      </div>
    </div>
  )
}

CardTeam.defaultProps = {
  item: {},
  team: {}
}

CardTeam.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]),
  team: PropTypes.oneOfType([PropTypes.object])
}

export default CardTeam
