import React from 'react'
import { MdError } from 'react-icons/md'
import PropTypes from 'prop-types'

import './style.css'

/**
 * Error messages called on the login page
 */
export default function ErrorMesage({ message }) {
  return (
    <div className="error-message">
      <span className="error-span">
        <MdError size={18} color="#E74C3C" />
        {message}
      </span>
    </div>
  )
}

ErrorMesage.propTypes = {
  message: PropTypes.string.isRequired
}
