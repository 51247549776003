/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={29.998}
      viewBox="0 0 30 29.998"
      {...props}
    >
      <path
        data-name="Subtra\xE7\xE3o 4"
        d="M27 29.998H3a3 3 0 01-3-3v-24a3 3 0 013-3h24a3 3 0 013 3v24a3 3 0 01-3 3zM7.881 9.307a.947.947 0 00-.947.947v9.484a.947.947 0 00.947.947h11.38a.948.948 0 00.947-.947V16.42l3.793 3.793V9.785l-3.793 3.793v-3.324a.948.948 0 00-.947-.947z"
        fill="#757575"
      />
    </svg>
  )
}

export default SvgComponent
