/* eslint-disable no-undef */
import React, { useState } from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { ClipLoader } from 'react-spinners'

import './style.css'
import { toast } from 'react-toastify'
import { api, history } from '../../services'

function ModalForgot({ modalIsOpen, actionClose, actionSubmit, emailUser }) {
  const [code, setCode] = useState(['', '', '', '', '', ''])
  const [loading, setLoading] = useState(false)

  const autoTab = (e) => {
    const BACKSPACE_KEY = 8
    const DELETE_KEY = 46
    let tabindex = $(e.target).attr('tabindex') || 0
    tabindex = Number(tabindex)
    if (e.keyCode === BACKSPACE_KEY) {
      tabindex -= 1
    } else if (e.keyCode !== DELETE_KEY) {
      tabindex += 1
    }
    const elem = $(`[tabindex=${tabindex}]`)
    if (elem[0]) {
      elem.focus()
    }
  }

  const handleChange = (e, index) => {
    const { value } = e.target
    const arr = [...code]
    arr[index] = value
    setCode(arr)
  }

  const handleSubmit = async () => {
    setLoading(true)
    const key = `${code[0]}${code[1]}${code[2]}${code[3]}${code[4]}${code[5]}`
    await api.post('/checkForgot', { key }).then(
      (response) => {
        sessionStorage.setItem('user-forgot', JSON.stringify(response.data))
        history.push('/forgot')
        modalIsOpen = false
      },
      (error) => {
        toast.error('Ops...Encontramos um problema aqui, tente novamente.')
      }
    )
    setLoading(false)
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={actionClose}
      className="modal"
      overlayClassName="modal-overlay"
    >
      <>
        <span className="modal-title">REDEFINIÇÃO DE SENHA</span>

        <section className="text-area">
          <p className="modal-text-forgot">
            Enviamos um código para seu e-mail
            <p>{emailUser}.</p>
            Insira-o aqui:
          </p>
        </section>
        <div className="inputs-code">
          {code.map((item, index) => (
            <input
              className="input-forgot-code"
              maxLength={1}
              value={code[index]}
              onChange={(e) => handleChange(e, index)}
              tabIndex={index}
              onKeyUp={autoTab}
            />
          ))}
        </div>
      </>
      <div className="button-modal-forgot-pass">
        <button className="button-primary" onClick={handleSubmit}>
          Avançar {loading && <ClipLoader color="#fff" size={20} />}
        </button>
        <span className="resend-email">
          Não recebeu?
          <button className="button-resend" onClick={actionSubmit}>
            Reenviar e-mail.
          </button>
        </span>
      </div>
    </Modal>
  )
}

ModalForgot.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  actionClose: PropTypes.func.isRequired,
  actionSubmit: PropTypes.func.isRequired,
  emailUser: PropTypes.string.isRequired
}

export default ModalForgot
