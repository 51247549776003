import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const TopInfos = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  span.title {
    font-size: 20px;
    font-weight: 600;
  }
`
