import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { MdPerson, MdLock, MdCreditCard, MdAddCircle } from 'react-icons/md'

import './style.css'
/**
 * Navbar Called By Profile
 * @param {number} active -  index of the list component that is active
 */
export default function NavProfile({ active, generateUser }) {
  return (
    <nav className="register-nav">
      <ul>
        <li className={active === 1 ? 'nav-active' : ''} onClick={generateUser}>
          <Link className="link-web" to="/profile/1">
            Dados pessoais
          </Link>
          <Link className="icon-nav" to="/profile/1">
            <MdPerson size={30} />
          </Link>
        </li>
        <li className={active === 2 ? 'nav-active' : ''} onClick={generateUser}>
          <Link className="link-web" to="/profile/2">
            Dados de acesso
          </Link>
          <Link className="icon-nav" to="/profile/2">
            <MdLock size={30} />
          </Link>
        </li>
        <li className={active === 3 ? 'nav-active' : ''} onClick={generateUser}>
          <Link className="link-web" to="/profile/3">
            Dados bancários
          </Link>
          <Link className="icon-nav" to="/profile/3">
            <MdCreditCard size={30} />
          </Link>
        </li>
        <li className={active === 4 ? 'nav-active' : ''} onClick={generateUser}>
          <Link className="link-web" to="/profile/4">
            Informações adicionais
          </Link>
          <Link className="icon-nav" to="/profile/4">
            <MdAddCircle size={30} />
          </Link>
        </li>
      </ul>
    </nav>
  )
}
NavProfile.defaultProps = {
  active: 1
}

NavProfile.propTypes = {
  active: PropTypes.number,
  generateUser: PropTypes.func.isRequired
}
