/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from 'react'
import PDFViewer from 'mgr-pdf-viewer-react'
import PropTypes from 'prop-types'
import { MdEdit } from 'react-icons/md'

import './styles.css'

function ViewDOC({ link, label, progress, ...rest }) {
  const inputRef = useRef(null)

  const handleOpenInput = () => {
    inputRef.current.click()
  }

  return (
    <div className="miniatura-pdf">
      <div className="pdf-doc-item">
        <PDFViewer
          document={{
            url: link
          }}
          scale={0.25}
          hideNavbar
        />
      </div>
      <input type="file" className="item-input-file" ref={inputRef} {...rest} />
      {progress > 0 && (
        <div className="upload-bar">
          <div className="uploadProgess" style={{ width: `${progress}%` }} />
        </div>
      )}
      {link && (
        <div className="item-edit">
          <div
            className="cirle-edit"
            onClick={handleOpenInput}
            role="button"
            tabIndex={0}
          >
            <MdEdit size={15} color="#757575" />
          </div>
        </div>
      )}

      {label && link && <label className="input-file-label">{label}</label>}
    </div>
  )
}

ViewDOC.defaultProps = {
  progress: 0
}

ViewDOC.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleOpenInput: PropTypes.func.isRequired,
  progress: PropTypes.number
}

export default ViewDOC
