import React from 'react'
import PropTypes, { shape } from 'prop-types'

import './styles.css'
import StyledCheckBox from '../StyledCheckBox'

import { verifyCheck } from './functions/verifyCheck'

function CheckItem({ item, index, array, handleClick, darkmode }) {
  return (
    <li className="item-drop-list">
      <StyledCheckBox
        checked={verifyCheck({ value: item.value, array })}
        handleClick={handleClick}
        id={index}
        darkmode={darkmode}
      />
      <span className="select-label">{item.label}</span>
    </li>
  )
}

CheckItem.propTypes = {
  item: PropTypes.objectOf(
    shape({ value: PropTypes.string, label: PropTypes.string })
  ).isRequired,
  index: PropTypes.number.isRequired,
  array: PropTypes.arrayOf(shape([])).isRequired,
  handleClick: PropTypes.func.isRequired,
  darkmode: PropTypes.bool.isRequired
}

export default CheckItem
