/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { GridLoader } from 'react-spinners'
import { Avatar } from 'antd'
import { setAvatar } from '../../utils'
import { getInCache } from './functions/getInCache'
import { saveInCache } from './functions/saveInCache'

import { Container } from './styles'

function AvatarFC({ data, sizes, loader, ...rest }) {
  const [url, setUrl] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    const _user = {
      name: data.name,
      avatar: data.avatar
    }
    getInCache(_user)
      .then((cache) => {
        setUrl(cache.url)
      })
      .catch((_) =>
        setAvatar(_user, setLoading).then((res) => {
          saveInCache(res, _user)
          setUrl(res)
        })
      )
    return () => {
      setUrl('') // This worked for me
    }
  }, [data, url])

  return (
    <Container style={{ width: sizes.w, height: sizes.h }} {...rest}>
      {loading ? (
        <GridLoader size={loader.size} color={loader.color} />
      ) : (
        <Avatar
          src={url}
          style={{
            width: sizes.w,
            height: sizes.h
          }}
          alt="failed to load avatar"
        />
      )}
    </Container>
  )
}

AvatarFC.defaultProps = {
  data: {},
  sizes: {},
  loader: {}
}

AvatarFC.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  sizes: PropTypes.oneOfType([PropTypes.object]),
  loader: PropTypes.oneOfType([PropTypes.object])
}

export default AvatarFC
