import axios from 'axios'

const MAX_REQUESTS_COUNT = 100
const INTERVAL_MS = 200
let PENDING_REQUESTS = 0

let url = window.location.href.split('/')
url.length = 3

const newUrl = `https://${url[2]}/`
const newUrl2 = `http://${url[2]}/`

const API_URL =
  newUrl !== process.env.REACT_APP_URL_PROD_HTTPS ||
  newUrl2 !== process.env.REACT_APP_URL_PROD_HTTP
    ? process.env.REACT_APP_API_STAGING
    : process.env.REACT_APP_API_URL

const api = axios.create({
  baseURL: API_URL
})

/**
 * Axios Request Interceptor
 */
api.interceptors.request.use((config) => {
  return new Promise((resolve, reject) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS += 1
        clearInterval(interval)
        resolve(config)
      }
    }, INTERVAL_MS)
  })
})

/**
 * Axios Response Interceptor
 */
api.interceptors.response.use(
  (response) => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.resolve(response)
  },
  (error) => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.reject(error)
  }
)

export default api
