import { api } from '../services'

const checkProp = (prop, value) => {
  if (prop) {
    prop(value)
  }
}

const returnInitials = (profile) => {
  const nameUser = profile.name.split(' ')
  return `https://avatar.oxro.io/avatar.svg?name=${nameUser[0]}+${nameUser[1]}&background=f6f6f6&length=2&color=00b7ef`
}

export const setAvatar = async (item, setLoading) => {
  return new Promise((resolve, reject) => {
    checkProp(setLoading, true)
    if (!item.avatar || item.avatar === '' || item.avatar === null) {
      const initials = returnInitials(item)
      resolve(initials)
    } else {
      api
        .get(`/file/${item.avatar}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err.message))
    }
  }).finally((_) => checkProp(setLoading, false))
}

export const setAvatarUrlMain = (profile, setUrl) => {
  if (profile.avatar_url === '' || !profile.avatar_url) {
    const initials = returnInitials(profile)
    setUrl(initials)
  } else {
    setUrl(profile.avatar_url)
  }
}
