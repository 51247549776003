import { useSelector } from 'react-redux'

const useGlobal = () => {
  const profile = useSelector((state) => state.user.profile)
  const id = useSelector((state) => state.auth.sessionToken)
  const isLoggedIn = useSelector((state) => state.auth.signed)
  const hourEntry = useSelector((state) => state.auth.hourEntry)
  const profileWorking = useSelector((state) => state.user.profileWorking)
  const inVocation = useSelector((state) => state.user.inVocation)
  const darkmode = useSelector((state) => state.user.darkmode)
  const _selectDate = useSelector((state) => state.user._selectDate)

  const date = new Date()
  const month = date.getMonth()
  const year = date.getFullYear()
  const newMonth = month + 1 < 10 ? `0${month + 1}` : month + 1
  const dateNow = `${newMonth}-${year}`

  return [
    {
      profile,
      id,
      isLoggedIn,
      hourEntry,
      profileWorking,
      inVocation,
      darkmode,
      dateNow,
      _selectDate
    }
  ]
}

export default useGlobal
