/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { checkStatusCode } from '../../utils'
import { api } from '../../services'

function GaleryCard({ file, selected, index, actionSelect }) {
  const dispatch = useDispatch()
  const [url, setUrl] = useState('')

  useEffect(() => {
    const getProfile = async () => {
      await api
        .get(`/file/${file}`)
        .then(({ data }) => setUrl(data))
        .catch((reject) => checkStatusCode(reject, dispatch))
    }
    getProfile()
  }, [file])

  return (
    <div
      style={{
        backgroundImage: `url(${url})`
      }}
      className={
        selected === index ? 'avatar-select avatar-selected' : 'avatar-select'
      }
      role="button"
      tabIndex={0}
      onClick={actionSelect}
    />
  )
}
GaleryCard.propTypes = {
  file: PropTypes.string.isRequired,
  selected: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  actionSelect: PropTypes.func.isRequired
}

export default GaleryCard
