import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { MdCheck } from 'react-icons/md'
import { toast } from 'react-toastify'
import { GridLoader } from 'react-spinners'

import CheckItem from '../CheckItem'
import ToastCustom from '../ToastCustom'
import AsyncSearchUser from '../AsyncSearchUser'
import UsersSection from '../UsersSection'
import ModalCancel from '../ModalCancelRegister'

import { onlyNumbers, checkStatusCode } from '../../utils'
import { api, api_max } from '../../services'

import './styles.css'
import './darkmode.css'

function SendTerms({ darkmode }) {
  const dispatch = useDispatch()

  const [terms, setTerms] = React.useState([])
  const [user, setUser] = React.useState('')
  const [users, setUsers] = React.useState([])
  const [usersSelected, setUsersSelected] = React.useState([])

  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const arrayTerms = [
    { value: 'contentTerm', label: 'Termo de adesão de conteúdo produzido.' },
    { value: 'imageAndVoice', label: 'Termo de imagem, voz e nome.' },
    { value: 'confidentiality', label: 'Termo de confidencialidade.' },
    {
      value: 'homeOffice',
      label: 'Home office provisório.'
    },
    {
      value: 'workInHome',
      label: 'Aditivo de contrato de trabalho - home office.'
    }
  ]

  const handleResetStates = () => {
    const arr = [setUsers]
    setUser('')
    arr.forEach((item) => item([]))
  }

  const handleSelectAll = () => {
    const arr = arrayTerms.map((item) => {
      return item.value
    })
    const removed = ['workInHome', 'homeOffice']
    removed.forEach((item) => {
      arr.forEach((item2, i) => {
        if (item === item2) {
          arr.splice(i, 1)
        }
      })
    })
    setTerms(arr)
  }

  const handleSelect = (value) => {
    const arr = validadeArr(terms, value)
    setTerms(arr)
  }

  const handleSelectUser = (value) => {
    const arr = validadeArr(usersSelected, value)
    setUsersSelected(arr)
  }

  const validadeArr = (refArr, value) => {
    const arr = [...refArr]
    const buscarPor = value
    let indice = arr.indexOf(buscarPor)
    if (indice >= 0) {
      while (indice >= 0) {
        arr.splice(indice, 1)
        indice = arr.indexOf(buscarPor)
      }
    } else {
      arr.push(value)
    }
    return arr
  }

  const removeItem = (i) => {
    const arr = [...usersSelected]
    arr.splice(i, 1)
    setUsersSelected(arr)
  }

  const searchUser = async (e) => {
    const { value } = e.target
    setUser(value)
    if (value.length > 0) {
      await api
        .get(`/users?filter=${value}&fields=name`)
        .then((res) => {
          setUsers(res.data.docs)
        })
        .catch((reject) => checkStatusCode(reject, dispatch))
    } else {
      setUsers([])
    }
  }

  const formatTerms = (data) => {
    const arr = [...data]
    const group = ['contentTerm', 'imageAndVoice', 'confidentiality']
    let equal = 0
    data.forEach((item) => {
      group.forEach((item2) => {
        if (item === item2) {
          equal += 1
        }
      })
    })
    if (equal >= 3) {
      group.forEach((item) => arr.splice(arr.indexOf(item), 1))
      arr.push('all_terms')
    }
    return arr
  }

  const sendForm = async () => {
    return new Promise((resolve, reject) => {
      setLoading(true)
      usersSelected.forEach(async (item) => {
        const userEnv = {
          name: item.name,
          email: item.email,
          ctps: item.ctps,
          ctpsSerie: item.ctpsSerie,
          cpf: item.cpf,
          rg: onlyNumbers(item.idRg),
          address: item.address,
          district: item.district,
          city: item.city,
          uf: item.uf,
          admissionDate: item.admissionDate,
          terms: formatTerms(terms)
        }

        await api_max
          .post('/terms', { ...userEnv })
          .then((res) => {
            resolve(res.status)
          })
          .catch((err) => {
            checkStatusCode(err, dispatch)
            reject(err.response.status)
          })
      })
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await sendForm()
      .then((res) =>
        toast.success(<ToastCustom text="Documentação enviada com sucesso!" />)
      )
      .catch((err) =>
        toast.error('Ops...Encontramos um problema por aqui. Tente novamente.')
      )
    setLoading(false)
  }

  const handleCancel = () => {
    setModalIsOpen(false)
    const arr = [setTerms, setUsersSelected]
    arr.forEach((item) => item([]))
  }

  const buttons = [
    {
      className: 'button-secondary',
      type: 'button',
      label: 'Cancelar',
      icon: false,
      action: () => setModalIsOpen(true)
    },
    {
      className: 'button-primary',
      type: 'submit',
      label: 'Enviar',
      icon: true,
      loading
    }
  ]

  return (
    <form className="send-terms" action="submit" onSubmit={handleSubmit}>
      <ModalCancel
        open={modalIsOpen}
        action={handleCancel}
        actionClose={() => setModalIsOpen(false)}
        typeCancel="o envio de termos"
      />
      <div className="title-and-select-all">
        <span className="title title-new-member">Gerar documentação</span>
        <button
          type="button"
          onClick={handleSelectAll}
          className="select-all-terms only-text-button"
        >
          Selecionar pacote
        </button>
      </div>

      <hr className="title-bar" />
      <div className="check-terms-area grid-class">
        {arrayTerms.map((item, index) => (
          <CheckItem
            item={item}
            index={index}
            array={terms}
            handleClick={() => handleSelect(item.value)}
            darkmode={darkmode}
          />
        ))}
      </div>

      <span className="title title-new-member colabs">Colaboradores</span>
      <hr className="title-bar" />

      <section className="search-colab">
        <div className="search-user-area">
          <span className="colab-search-label">Buscar de colaboradores</span>
          <AsyncSearchUser
            options={users}
            value={user}
            onChange={searchUser}
            actionClick={handleSelectUser}
            clickOut={handleResetStates}
            darkmode={darkmode}
          />
        </div>

        <div className="colabs-add">
          <span className="colab-search-label">Colaboradores adicionados</span>
          <UsersSection
            options={usersSelected}
            actionRemove={removeItem}
            darkmode={darkmode}
          />
        </div>
      </section>

      <div className="btn-terms-group">
        {buttons.map((item) => (
          <button
            type={item.type}
            className={item.className}
            onClick={item.action ? item.action : null}
          >
            {item.loading ? (
              <GridLoader size={5} color={darkmode ? '#121212' : '#fff'} />
            ) : (
              <>
                {item.label}
                {item.icon && <MdCheck size={25} />}
              </>
            )}
          </button>
        ))}
      </div>
    </form>
  )
}

SendTerms.propTypes = {
  darkmode: PropTypes.bool.isRequired
}

export default SendTerms
