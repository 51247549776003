/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable func-names */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { MdChevronRight, MdCheck } from 'react-icons/md'

import {
  Header,
  NavProfile,
  StyledBasicInput,
  InputFiles,
  StyledSelect,
  ModalCancelRegister,
  SubNav,
  ModalGalery,
  Poppover,
  Crop
} from '../../../components'

import { updateUser } from '../../../store/modules/user/actions'
import { api, history } from '../../../services'
import { bloodTypes } from './Vars'
import {
  validateCpf,
  validateCep,
  validatePhone,
  uploadFile,
  checkStatusCode
} from '../../../utils'

import addPhoto from '../../../assets/icons/addPhoto.svg'

import 'antd/dist/antd.css'
import './style.css'

export default function PersonData() {
  const darkmode = useSelector((state) => state.user.darkmode)

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [galeryOpen, setGaleryOpen] = useState(false)
  const [reloadPhotos, setReloadPhotos] = useState(true)
  const profile = useSelector((select) => select.user.profile)
  const target = useRef(null)

  const [selectItem, setSelectItem] = useState('')
  const { email } = profile

  const [disabled, setDisabled] = useState(true)
  const [show, setShow] = useState(false)

  const [avFile, setAvFile] = useState('')
  const [thumbnail, setThumbnail] = useState(null)
  const [avatarView, setAvatarView] = useState(null)
  const [name, setName] = useState('')
  const [cpf, setCpf] = useState('')
  const [idRg, setIdRg] = useState('')
  const [dateBirth, setDateBirth] = useState('')
  const [typeBlood, setTypeBlood] = useState('')
  const [phone, setPhone] = useState('')
  const [cep, setCep] = useState('')
  const [country, setCountry] = useState('')
  const [uf, setUf] = useState('')
  const [city, setCity] = useState('')
  const [address, setAddress] = useState('')
  const [district, setDistrict] = useState('')
  const [complement, setComplement] = useState('')

  const [cpfInvalid, setCpfInvalid] = useState(false)
  const [cepInvalid, setCepInvalid] = useState(false)
  const [phoneInvalid, setPhoneInvalid] = useState(false)

  const [cepLoader, setCepLoader] = useState(false)

  const [avatars, setAvatars] = useState([])

  const [onEditAvatar, setOnEditAvatar] = useState(false)
  const [fileUrl, setFileUrl] = useState('')
  const [scale, setScale] = useState(1)
  const editor = useRef(null)
  const inputFile = useRef(null)

  const { contractingModel } = profile

  const fileInputKey = inputFile.value ? inputFile.value.name : +new Date()
  const dispatch = useDispatch()

  const noFirstEdit = sessionStorage.getItem('no-first-edit')

  /**
   * Auto completes the inputs
   * @param {Object} userdata - User's Object
   */
  const completeTo = async (userdata) => {
    setName(userdata.name)
    setTypeBlood(userdata.typeBlood || 'Pendente')
    setCpf(userdata.cpf)
    setIdRg(userdata.idRg)
    setDateBirth(userdata.dateBirth)
    setPhone(userdata.phone)
    setCep(userdata.cep)
    setCountry(userdata.country)
    setUf(userdata.uf)
    setCity(userdata.city)
    setAddress(userdata.address)
    setDistrict(userdata.district)
    setComplement(userdata.complement)
  }

  const getUser = () => {
    const userStorage = JSON.parse(sessionStorage.getItem('user'))
    let user = {}
    if (userStorage) {
      user = { ...userStorage }
    } else {
      user = { ...profile }
    }
    return user
  }

  useEffect(() => {
    const userStorage = JSON.parse(sessionStorage.getItem('user'))
    if (userStorage) {
      completeTo(userStorage)
    } else {
      completeTo(profile)
    }
  }, [profile])
  /**
   * Preview thumbnail
   */
  useEffect(() => {
    const getAvatar = async () => {
      const userStorage = JSON.parse(sessionStorage.getItem('user'))
      let user = {}
      if (userStorage) {
        user = { ...userStorage }
      } else {
        user = { ...profile }
      }
      if (user.avatar !== '') {
        const response = await api.get(`/file/${user.avatar}`)
        if (response.data !== '') {
          setAvatarView(response.data)
        }
      }
    }
    getAvatar()
  }, [profile])

  useEffect(() => {
    const getUserFiles = async () => {
      if (reloadPhotos) {
        await api.get('/file/myFiles').then(
          (res) => {
            setAvatars(res.data)
          },
          (reject) => checkStatusCode(reject, dispatch)
        )
      }
    }
    getUserFiles()
  }, [reloadPhotos, avFile])

  useEffect(() => {
    const checkEmptyFields = () => {
      if (
        name !== '' &&
        cpf !== '' &&
        idRg !== '' &&
        dateBirth !== '' &&
        phone !== '' &&
        cep !== '' &&
        address !== '' &&
        district !== '' &&
        typeBlood !== ''
      ) {
        setDisabled(false)
      } else {
        setDisabled(true)
      }
    }
    checkEmptyFields()
  }, [
    thumbnail,
    avatarView,
    name,
    cpf,
    idRg,
    dateBirth,
    phone,
    cep,
    address,
    district,
    typeBlood
  ])

  useEffect(() => {
    const openPopover = () => {
      if (profile.finalized) {
        setShow(true)
      }
    }
    openPopover()
  }, [profile])

  const getProfile = async (file) => {
    const response = await api.get(`/file/${file}`)
    return response.data
  }

  const handleFinalizedEdit = () => {
    setOnEditAvatar(false)
    setScale(1)
  }

  const setAvatar = async (e) => {
    const { files } = e.target
    setThumbnail(files[0])
    setOnEditAvatar(true)
  }

  const handleSaveFromGalery = async () => {
    const url = await getProfile(selectItem)
    setAvFile(selectItem)
    setAvatarView(url)
    setGaleryOpen(false)
  }

  const handleAdjust = async () => {
    const user = getUser()
    const file = avFile || user.avatar
    const response = await getProfile(file)
    const xhr = new XMLHttpRequest()
    xhr.open('GET', response)
    xhr.responseType = 'blob'
    xhr.onload = () => {
      const type = {
        type: 'image/png'
      }
      let blob = new Blob([xhr.response], type)
      let linkImage = URL.createObjectURL(blob)
      setFileUrl(linkImage)
      setOnEditAvatar(true)
    }
    xhr.send()
  }

  const handleSaveAvatar = async () => {
    if (editor !== null) {
      const canvas = editor.current.getImage()
      canvas.toBlob(
        async function (blob) {
          const nameFile = thumbnail === null ? 'new_photo' : thumbnail.name
          setLoading(true)
          const key = `memorian_${Date.now()}_${nameFile}`
          const res = await uploadFile(blob, key)
          await api
            .post('/file', {
              typeFile: 'avatar',
              fileName: res.Key,
              path: res.Location
            })
            .catch((reject) => checkStatusCode(reject, dispatch))
          const url = await getProfile(key)
          setAvFile(key)
          setAvatarView(url)
          handleFinalizedEdit()
          setLoading(false)
          setReloadPhotos(true)
        },
        'image/png',
        0.2
      )
    }
  }

  const generateUser = () => {
    const user = getUser()
    user.avatar = avFile !== '' ? avFile : user.avatar
    user.name = name
    user.email = email
    user.typeBlood = typeBlood
    user.cpf = cpf
    user.idRg = idRg
    user.dateBirth = dateBirth
    user.phone = phone
    user.cep = cep
    user.country = country
    user.uf = uf
    user.city = city
    user.address = address
    user.district = district
    user.complement = complement
    sessionStorage.setItem('user', JSON.stringify(user))
    sessionStorage.setItem('no-first-edit', true)
  }

  const redirectPage = () => {
    if (contractingModel === 'Estágio') {
      history.push('/profile/academic')
    }
    if (contractingModel === 'CLT') {
      history.push('/profile/work')
    }
    if (contractingModel === 'PJ') {
      history.push('/profile/pj')
    }
  }

  const handleCancel = () => {
    setModalIsOpen(false)
    sessionStorage.removeItem('user')
    history.push('/')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (cpfInvalid || cepInvalid) {
      toast.error('Ops, verifique os campos e tente novamente')
      return
    }
    await generateUser()
    if (profile.finalized) {
      const userStorage = JSON.parse(sessionStorage.getItem('user'))
      dispatch(updateUser(userStorage))
    } else {
      redirectPage()
    }
  }

  const actionRemove = () => {
    setAvatarView(null)
    setAvFile('')
    setThumbnail(null)
    const user = getUser()
    user.avatar = ''
    sessionStorage.setItem('user', JSON.stringify(user))
  }

  return (
    <>
      <ModalCancelRegister
        open={modalIsOpen}
        actionClose={() => setModalIsOpen(false)}
        action={handleCancel}
        typeCancel={profile.finalized ? 'as edições' : 'o cadastro'}
      />
      <ModalGalery
        galeryOpen={galeryOpen}
        actionClose={() => setGaleryOpen(false)}
        onSelectItem={(e) => setSelectItem(e.fileName)}
        actionSave={handleSaveFromGalery}
        avatars={avatars}
        darkmode={darkmode}
      />
      <Crop
        scale={scale}
        setScale={setScale}
        onEditAvatar={onEditAvatar}
        thumbnail={thumbnail}
        fileUrl={fileUrl}
        editor={editor}
        handleFinalizedEdit={handleFinalizedEdit}
        loading={loading}
        handleSaveAvatar={handleSaveAvatar}
        darkmode={darkmode}
      />

      <div className={`${darkmode && 'darkmode'} global-container`}>
        <Header />
        <div className="global-content global-content-grey" ref={target}>
          {profile.finalized ? (
            <span className="title">MEU PERFIL</span>
          ) : (
            <span className="title">CADASTRO</span>
          )}
          <hr className="title-bar" />
          <NavProfile active={1} generateUser={generateUser} />

          <SubNav
            contractingModel={contractingModel}
            generateUser={generateUser}
          />

          <form onSubmit={handleSubmit} className="form-1">
            <div className="class-grid-1 info-group">
              <div className="input-profile-avatar">
                <InputFiles
                  icon={addPhoto}
                  avatar_url={avatarView}
                  onChange={setAvatar}
                  span="Anexar foto de perfil"
                  ref={inputFile}
                  key={fileInputKey}
                  type_file="avatar"
                  actionRemove={actionRemove}
                  actionOpenModal={() => setGaleryOpen(true)}
                  buttonGalery={avatars.length >= 2}
                  adjustPhoto={handleAdjust}
                />
              </div>
              <div className="input-w-margin">
                <StyledBasicInput
                  label="Nome completo *"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="input-w-margin">
                <StyledBasicInput
                  label="E-mail corporativo"
                  value={email}
                  disabled
                />
              </div>

              <div className="class-grid-1 sub-group-infos-1">
                <div className="select-blood">
                  <StyledSelect
                    data={bloodTypes}
                    label="Tipo sanguíneo *"
                    holder="Selecione"
                    value={typeBlood}
                    onChange={(e) => setTypeBlood(e.target.value)}
                  />
                </div>
                <div className="input-w-margin">
                  <StyledBasicInput
                    label="CPF *"
                    value={cpf}
                    mask="999.999.999-99"
                    placeholder="___.___.___-__"
                    onChange={(e) => setCpf(e.target.value)}
                    onBlur={() => {
                      validateCpf(cpf, setCpfInvalid)
                    }}
                    error={cpfInvalid}
                    errorMesage="CPF inválido"
                  />
                </div>
                <div className="input-w-margin">
                  <StyledBasicInput
                    label="RG *"
                    value={idRg}
                    onChange={(e) => setIdRg(e.target.value)}
                  />
                </div>
                <div className="input-w-margin">
                  <StyledBasicInput
                    label="Data de nascimento *"
                    value={dateBirth}
                    mask="99/99/9999"
                    placeholder="__/__/____"
                    onChange={(e) => setDateBirth(e.target.value)}
                    errorMesage="Data inválida"
                  />
                </div>
              </div>
            </div>

            <div className="class-grid-1 rows-gap-30 contact-address-group">
              <div className="title-data-c">
                <span className="title-min">Dados de contato</span>
                <hr className="title-bar" />
              </div>

              <div className="class-grid-1 contact-address-1">
                <div className="input-w-margin">
                  <StyledBasicInput
                    label="DDD + CELULAR *"
                    value={phone}
                    mask="(99) 9 9999-9999"
                    placeholder="(__) _ ____-____"
                    onChange={(e) => setPhone(e.target.value)}
                    error={phoneInvalid}
                    errorMesage="Número de celular inválido"
                    onBlur={() => validatePhone(phone, setPhoneInvalid)}
                  />
                </div>
                <div className="input-w-margin">
                  <StyledBasicInput
                    label="CEP *"
                    value={cep}
                    mask="99999-999"
                    placeholder="_____-___"
                    onChange={(e) => {
                      setCep(e.target.value)
                    }}
                    onBlur={() => {
                      validateCep({
                        cep,
                        setCepInvalid,
                        setCity,
                        setCountry,
                        setUf,
                        setCepLoader
                      })
                    }}
                    error={cepInvalid}
                    errorMesage="CEP inválido"
                    loader={cepLoader}
                  />
                </div>
              </div>

              <div className="class-grid-1 contact-address-2">
                <div className="input-w-margin">
                  <StyledBasicInput
                    label="Estado"
                    value={uf}
                    required
                    disabled
                  />
                </div>
                <div className="input-w-margin">
                  <StyledBasicInput label="Cidade" value={city} disabled />
                </div>
              </div>

              <div className="class-grid-1 contact-address-3">
                <div className="input-w-margin">
                  <StyledBasicInput
                    label="Endereço *"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <div className="input-w-margin">
                  <StyledBasicInput
                    label="Bairro *"
                    value={district}
                    onChange={(e) => setDistrict(e.target.value)}
                  />
                </div>
                <div className="input-w-margin">
                  <StyledBasicInput
                    label="Complemento"
                    value={complement}
                    onChange={(e) => setComplement(e.target.value)}
                    placeholder="Opcional"
                  />
                </div>
              </div>
            </div>
            <div className="btn-group btns-register-profile">
              <button
                type="button"
                className="button-secondary btn-register-w"
                onClick={() => setModalIsOpen(true)}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="button-primary btn-register-w"
                disabled={disabled}
              >
                {profile.finalized ? (
                  <>
                    Salvar{' '}
                    <MdCheck color={darkmode ? '#1a1a1a' : '#fff'} size={20} />
                  </>
                ) : (
                  <>
                    Avançar{' '}
                    <MdChevronRight
                      color={darkmode ? '#1a1a1a' : '#fff'}
                      size={20}
                    />
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
        {!noFirstEdit && (
          <Poppover
            target={target}
            show={show}
            setShow={setShow}
            darkmode={darkmode}
          />
        )}
      </div>
    </>
  )
}
