import { memorianAPI } from '../../../services'

const _ApplicationId = process.env.REACT_APP_APLICATION_ID

const date = new Date()

const month = date.getMonth()
const year = date.getFullYear()
const newMonth = month + 1 < 10 ? `0${month + 1}` : month + 1

const getHours = async (id) => {
  return new Promise((resolve, reject) => {
    memorianAPI
      .post('/use/functions/getWorkingHours', {
        member: id,
        date: `${newMonth}-${year}`,
        _ApplicationId
      })
      .then((res) => {
        const working = res.data.result
        resolve(working)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export { getHours }
