import React from 'react'
import PropTypes, { shape } from 'prop-types'
import FolderFC from '../Folder'

import { List } from './styled-components'

function FolderList({ data, setFolder, setOpen }) {
  const handleOpen = (folder) => {
    setFolder(folder)
    setOpen(true)
  }

  return (
    <>
      {data.length > 0 && (
        <List>
          {data.map((item) => (
            <FolderFC title={item.name} onClick={() => handleOpen(item)} />
          ))}
        </List>
      )}
    </>
  )
}

FolderList.propTypes = {
  data: PropTypes.arrayOf(shape([])).isRequired,
  setFolder: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired
}

export default FolderList
