import { api } from '../../../services'
import { checkStatusCode } from '../../../utils'

const loadFiles = async ({
  search,
  area,
  page,
  dispatch,
  setProgress,
  setNumberPages,
  setDataNumbers,
  setDocsNumber,
  setData,
  setLoaded
}) => {
  const config = {
    onDownloadProgress(progressEvent) {
      setProgress(
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      )
    }
  }
  const url =
    search !== ''
      ? `/file?area=${area}&keys=${search}&public=true&page=${page}&limit=15`
      : `/file?area=${area}&public=true&page=${page}&limit=15`
  await api.get(url, config).then(
    (response) => {
      const { docs, totalPages, totalDocs } = response.data
      setNumberPages(totalPages)
      const numbers = []
      for (let i = 1; i <= totalPages; i += 1) {
        const obj = { value: i }
        numbers.push(obj)
      }
      setDataNumbers(numbers)
      setDocsNumber(totalDocs)
      setData(docs)
      setLoaded(true)
    },
    (reject) => {
      setLoaded(true)
      checkStatusCode(reject, dispatch)
    }
  )
  setProgress(0)
}

export { loadFiles }
