import React from 'react'
import Modal from 'react-modal'
import AvatarEditor from 'react-avatar-editor'
import { MdZoomIn, MdZoomOut, MdNavigateBefore, MdCheck } from 'react-icons/md'
import { ClipLoader } from 'react-spinners'
import { Slider } from 'antd'
import PropTypes from 'prop-types'

import './styles.css'
import './darkmode.css'

function Crop({
  scale,
  setScale,
  onEditAvatar,
  thumbnail,
  fileUrl,
  editor,
  handleFinalizedEdit,
  loading,
  handleSaveAvatar,
  darkmode
}) {
  const handleZoomIn = () => {
    let scaleIn = scale
    scaleIn += 0.01
    setScale(scaleIn)
  }

  const handleZoomOut = () => {
    let scaleOut = scale
    scaleOut -= 0.01
    setScale(scaleOut)
  }

  return (
    <Modal
      isOpen={onEditAvatar}
      className={`${darkmode && 'darkmode'} modal modal-editor`}
      overlayClassName="modal-overlay"
    >
      <div className="avatar-crop">
        <span className="modal-title">Ajustar mídia</span>
        <div className="border-avatar-editor">
          <AvatarEditor
            ref={editor}
            image={thumbnail || fileUrl}
            width={300}
            height={300}
            border={2}
            color={[255, 255, 255, 0.1]} // RGBA
            scale={scale}
            rotate={0}
          />
        </div>

        <div className="slider">
          <button onClick={handleZoomOut}>
            <MdZoomOut size={25} color={darkmode && '#757575'} />
          </button>
          <div className="slider-input">
            <Slider
              min={1}
              max={5}
              value={scale}
              onChange={(value) => setScale(value)}
              step={0.01}
            />
          </div>
          <button onClick={handleZoomIn}>
            <MdZoomIn size={25} color={darkmode && '#757575'} />
          </button>
        </div>
        <div className="buttons-adjust">
          <button
            type="button"
            className="button-return return-modal"
            onClick={handleFinalizedEdit}
          >
            <MdNavigateBefore size={20} />
            Voltar
          </button>
          <button
            type="submit"
            className="button-primary btn-adjust"
            onClick={handleSaveAvatar}
          >
            Salvar
            {loading ? (
              <div className="loader-margin">
                <ClipLoader size={20} color={darkmode ? '#1a1a1a' : '#fff'} />
              </div>
            ) : (
              <MdCheck size={20} style={{ marginLeft: '8px' }} />
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

Crop.defaultProps = {
  scale: 1
}

Crop.propTypes = {
  scale: PropTypes.number,
  setScale: PropTypes.func.isRequired,
  onEditAvatar: PropTypes.bool.isRequired,
  thumbnail: PropTypes.element.isRequired,
  fileUrl: PropTypes.string.isRequired,
  editor: PropTypes.element.isRequired,
  handleFinalizedEdit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  handleSaveAvatar: PropTypes.func.isRequired,
  darkmode: PropTypes.bool.isRequired
}

export default Crop
