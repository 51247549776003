import React, { useState, useEffect } from 'react'
import { MdNavigateBefore, MdCheck, MdChevronRight } from 'react-icons/md'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import {
  Header,
  NavProfile,
  SubNav,
  StyledBasicInput,
  ModalCancelRegister
} from '../../../../components'
import { history } from '../../../../services'
import { validateCep, validateCnpj, validateCpf } from '../../../../utils'
import { updateUser } from '../../../../store/modules/user/actions'
import './styles.css'

function Juridics() {
  const profile = useSelector((select) => select.user.profile)
  const darkmode = useSelector((state) => state.user.darkmode)

  const [disabled, setDisabled] = useState(true)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [corporateName, setCorporateName] = useState('')
  const [nameJuridic, setNameJuridic] = useState('')
  const [maritalStatus, setMaritalStatus] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [atuationArea, setAtuationArea] = useState('')
  const [cpfJuridic, setCpf] = useState('')
  const [rg, setRg] = useState('')
  const [cep, setCep] = useState('')
  const [cityJuridic, setCity] = useState('')
  const [ufJuridic, setUf] = useState('')
  const [addressJuridic, setAddress] = useState('')
  const [districtJuridic, setDistrict] = useState('')
  const [complementJuridic, setComplement] = useState('')

  const [cnpjInvalid, setCnpjInvalid] = useState(false)
  const [cpfInvalid, setCpfInvalid] = useState(false)
  const [cepInvalid, setCepInvalid] = useState(false)

  const [cepLoader, setCepLoader] = useState(false)

  const { contractingModel } = profile

  const dispatch = useDispatch()

  const completeTo = async (userData) => {
    setCorporateName(userData.corporateName)
    setNameJuridic(userData.nameJuridic)
    setMaritalStatus(userData.maritalStatus)
    setCnpj(userData.cnpj)
    setAtuationArea(userData.atuationArea)
    setCpf(userData.cpfJuridic)
    setRg(userData.rg)
    setCep(userData.cep)
    setCity(userData.cityJuridic)
    setUf(userData.ufJuridic)
    setAddress(userData.addressJuridic)
    setDistrict(userData.districtJuridic)
    setComplement(userData.complementJuridic)
  }

  useEffect(() => {
    const checkEmptyInputs = () => {
      if (
        corporateName !== '' &&
        cnpj !== '' &&
        nameJuridic !== '' &&
        maritalStatus !== '' &&
        atuationArea !== '' &&
        cpfJuridic !== '' &&
        rg !== '' &&
        cep !== '' &&
        addressJuridic !== '' &&
        districtJuridic !== ''
      ) {
        setDisabled(false)
      } else {
        setDisabled(true)
      }
    }
    checkEmptyInputs()
  }, [
    corporateName,
    cnpj,
    nameJuridic,
    maritalStatus,
    atuationArea,
    cpfJuridic,
    rg,
    cep,
    addressJuridic,
    districtJuridic
  ])

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem('user'))
    completeTo(user)
  }, [])

  const generateUser = () => {
    let user = JSON.parse(sessionStorage.getItem('user'))
    user = {
      ...user,
      corporateName,
      nameJuridic,
      maritalStatus,
      cnpj,
      atuationArea,
      cpfJuridic,
      cepJuridic: cep,
      rg,
      cityJuridic,
      ufJuridic,
      addressJuridic,
      districtJuridic,
      complementJuridic
    }
    sessionStorage.setItem('user', JSON.stringify(user))
  }

  const handleReturn = () => {
    generateUser()
    history.push('/profile/1')
  }

  const handleCancel = () => {
    setModalIsOpen(false)
    sessionStorage.removeItem('user')
    history.push('/')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (cnpjInvalid || cepInvalid || cpfInvalid) {
      toast.error('Ops, verifique os campos e tente novamente.')
      return
    }

    await generateUser()
    if (profile.finalized) {
      const userStorage = JSON.parse(sessionStorage.getItem('user'))
      dispatch(updateUser(userStorage))
    } else {
      history.push('/profile/docs')
    }
  }

  return (
    <>
      <ModalCancelRegister
        open={modalIsOpen}
        actionClose={() => setModalIsOpen(false)}
        action={handleCancel}
        typeCancel={profile.finalized ? 'as edições' : 'o cadastro'}
      />
      <div className={`${darkmode && 'darkmode'} global-container`}>
        <Header />
        <div className="global-content global-content-grey">
          {profile.finalized ? (
            <span className="title">MEU PERFIL</span>
          ) : (
            <span className="title">CADASTRO</span>
          )}
          <hr className="title-bar" />
          <NavProfile active={1} generateUser={generateUser} />

          <SubNav
            complete={1}
            select={2}
            generateUser={generateUser}
            contractingModel={contractingModel}
          />

          <form onSubmit={handleSubmit}>
            <div>
              <div className="area-tile-juridic">
                <span className="title-min">Dados de pessoa jurídica</span>
                <hr className="title-bar" />
              </div>
              <div className="juridic-person-data area-grid-global margin-global">
                <div className="input-w-margin">
                  <StyledBasicInput
                    label="Razão social *"
                    value={corporateName}
                    onChange={(e) => setCorporateName(e.target.value)}
                  />
                </div>
                <div className="input-w-margin">
                  <StyledBasicInput
                    label="CNPJ *"
                    value={cnpj}
                    mask="99.999.999/9999-99"
                    placeholder="__.___.___/____-__"
                    onChange={(e) => setCnpj(e.target.value)}
                    onBlur={() => {
                      validateCnpj(cnpj, setCnpjInvalid)
                    }}
                    error={cnpjInvalid}
                    errorMesage="CPNJ inválido"
                  />
                </div>
              </div>

              <div className="juridic-person">
                <div className="name-civil area-grid-global margin-global">
                  <div className="input-w-margin">
                    <StyledBasicInput
                      label="Nome completo *"
                      value={nameJuridic}
                      onChange={(e) => setNameJuridic(e.target.value)}
                    />
                  </div>
                  <div className="input-w-margin">
                    <StyledBasicInput
                      label="Estado Civil *"
                      value={maritalStatus}
                      onChange={(e) => setMaritalStatus(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="area-tile-juridic">
                <span className="title-min">Dados do anuente</span>
                <hr className="title-bar" />
              </div>

              <div className="atuation-docs area-grid-global margin-global">
                <div className="input-w-margin">
                  <StyledBasicInput
                    label="Area de atuação *"
                    value={atuationArea}
                    onChange={(e) => setAtuationArea(e.target.value)}
                  />
                </div>
                <div className="input-w-margin">
                  <StyledBasicInput
                    label="CPF *"
                    value={cpfJuridic}
                    mask="999.999.999-99"
                    placeholder="___.___.___-__"
                    onChange={(e) => setCpf(e.target.value)}
                    onBlur={() => {
                      validateCpf(cpfJuridic, setCpfInvalid)
                    }}
                    error={cpfInvalid}
                    errorMesage="CPF inválido"
                  />
                </div>
                <div className="input-w-margin">
                  <StyledBasicInput
                    label="RG *"
                    value={rg}
                    onChange={(e) => setRg(e.target.value)}
                  />
                </div>
              </div>

              <div className="juridic-address-1 area-grid-global margin-global">
                <div className="input-w-margin">
                  <StyledBasicInput
                    label="CEP *"
                    value={cep}
                    mask="99999-999"
                    placeholder="_____-__"
                    onChange={(e) => {
                      setCep(e.target.value)
                    }}
                    onBlur={() => {
                      validateCep({
                        cep,
                        setCepInvalid,
                        setCity,
                        setUf,
                        setCepLoader
                      })
                    }}
                    error={cepInvalid}
                    errorMesage="CEP inválido"
                    loader={cepLoader}
                  />
                </div>
                <div className="input-w-margin">
                  <StyledBasicInput label="Estado" value={ufJuridic} disabled />
                </div>
                <div className="input-w-margin">
                  <StyledBasicInput
                    label="Cidade"
                    value={cityJuridic}
                    disabled
                  />
                </div>
              </div>

              <div className="atuation-docs area-grid-global margin-global">
                <div className="input-w-margin">
                  <StyledBasicInput
                    label="Endereço *"
                    value={addressJuridic}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <div className="input-w-margin">
                  <StyledBasicInput
                    label="Bairro *"
                    value={districtJuridic}
                    onChange={(e) => setDistrict(e.target.value)}
                  />
                </div>
                <div className="input-w-margin">
                  <StyledBasicInput
                    label="Complemento"
                    value={complementJuridic}
                    onChange={(e) => setComplement(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="form-bottom">
              <button
                type="button"
                className="button-return"
                onClick={handleReturn}
              >
                <MdNavigateBefore size={20} />
                Voltar
              </button>

              <div className="btn-group">
                <button
                  type="button"
                  className="button-secondary btn-register-w"
                  onClick={() => setModalIsOpen(true)}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="button-primary btn-register-w"
                  disabled={disabled}
                >
                  {profile.finalized ? (
                    <>
                      Salvar
                      <MdCheck
                        color={darkmode ? '#1a1a1a' : '#fff'}
                        size={20}
                      />
                    </>
                  ) : (
                    <>
                      Avançar
                      <MdChevronRight
                        color={darkmode ? '#1a1a1a' : '#fff'}
                        size={20}
                      />
                    </>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Juridics
