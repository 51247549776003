/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'

import './style.css'
/**
 * Styled Input with icon
 * @param {Element} Icon - Icon that will be rendered
 * @param {string} color - Icon color
 */
export default function StyledInput({ Icon, error, ...rest }) {
  return (
    <div className="styled-input">
      <div className="icon">{Icon && <Icon size={20} color="#757575" />}</div>
      <input
        {...rest}
        className={error ? 'input-text input-text-error' : 'input-text'}
        autoComplete="off"
        autoSave="off"
      />
    </div>
  )
}
/** ******************
 * Props Validation *
 ******************* */
StyledInput.defaultProps = {
  Icon: '',
  error: false
}
StyledInput.propTypes = {
  Icon: PropTypes.element,
  error: PropTypes.bool
}
