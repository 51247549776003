import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AvatarContent = styled.div`
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
`
