/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react'
import Modal from 'react-modal'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes, { shape } from 'prop-types'
import {
  MdArrowDropDown,
  MdArrowDropUp,
  MdMonetizationOn
} from 'react-icons/md'
import { ClickAwayListener } from '@material-ui/core'
import { GridLoader } from 'react-spinners'

import moment from 'moment'

import Tags from '../Tags'
import Depoiments from '../Depoiments'
import Stamps from '../Stamps'
// import Trajectory from '../UserTrajectory'
import NewDepoiment from '../NewDepoiment'
import EditorProfileComponent from '../EditorProfileComponent'

import { getInCache } from '../Avatar/functions/getInCache'
import { saveInCache } from '../Avatar/functions/saveInCache'

import InstagramIcon from '../../assets/icons/instagram.svg'
import LinkedinIcon from '../../assets/icons/linkedin.svg'
import CloseIcon from '../../assets/icons/CloseIcon.svg'

import { api } from '../../services'
import { formatName, checkStatusCode, setAvatar, ajuste } from '../../utils'

import './styles.css'
import './darkmode.css'
import 'react-circular-progressbar/dist/styles.css'

function UserInfosModal({
  modalIsOpen,
  actionClose,
  user,
  onChange,
  changeIn
}) {
  const darkmode = useSelector((state) => state.user.darkmode)
  const memberId = useSelector((state) => state.auth.sessionToken)

  const dispatch = useDispatch()

  const [showMore, setShowMore] = React.useState(false)
  const [data, setData] = React.useState([])
  const [avatarUrl, setAvatarUrl] = React.useState('')
  const [tagOpen, setTagOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const [onEditProfile, setOnEditProfile] = React.useState(false)

  const [change, setChange] = useState(false)

  React.useEffect(() => {
    setData(user)

    return () => {
      setData([])
      setShowMore(false)
    }
  }, [user])

  React.useEffect(() => {
    const getAvatarIfOpenModal = async () => {
      try {
        if (modalIsOpen) {
          const _user = {
            name: data.name,
            avatar: data.avatar
          }
          getInCache(_user)
            .then((cache) => {
              setAvatarUrl(cache.url)
            })
            .catch((_) =>
              setAvatar(_user, setLoading).then((res) => {
                saveInCache(res, _user)
                setAvatarUrl(res)
              })
            )
        }
      } catch (error) {
        checkStatusCode(error, dispatch)
      }
    }
    getAvatarIfOpenModal()

    return () => {
      setAvatarUrl('') // This worked for me
    }
  }, [data])

  const checkStampsNumber = (stamps) => {
    return stamps.length < 10 ? `0${stamps.length}` : stamps.length
  }

  const formatYears = (year) => {
    const now = moment(new Date()) // Data de hoje
    const newyear = `${year.split('/')[2]}-${year.split('/')[1]}-${
      year.split('/')[0]
    }`
    const past = moment(newyear) // Outra data no passado
    const duration = moment.duration(now.diff(past))
    const months = duration.asMonths()
    const years = duration.asYears()
    let number = years < 1 ? ajuste(months, 0) : ajuste(years, 0)
    number = number === 0 ? '00' : number
    const obj = {
      number: isNaN(number) ? '00' : number,
      text: years < 1 ? 'meses' : 'anos'
    }
    return obj
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={actionClose}
      className={`${darkmode && 'darkmode'} modal-user-infos`}
      overlayClassName="modal-overlay"
    >
      {onEditProfile ? (
        <EditorProfileComponent
          actionClose={() => setOnEditProfile(false)}
          darkmode={darkmode}
          onSet={onChange}
          changeIn={changeIn}
        />
      ) : (
        <ClickAwayListener onClickAway={actionClose}>
          <div className="user-infos-content">
            <button
              className="close-user-modal"
              type="button"
              onClick={actionClose}
            >
              <img src={CloseIcon} alt="x" />
            </button>
            <section>
              {loading ? (
                <div className="user-infos-modal-avatar">
                  <GridLoader color={darkmode ? '#BFBFBF' : '#00b7ef'} />
                </div>
              ) : (
                <div
                  style={{
                    backgroundImage: `url(${avatarUrl})`
                  }}
                  className="user-infos-modal-avatar"
                />
              )}

              <div className="user-stamps-coins-time">
                <div className="user-stamps-itens">
                  <h2 className="number-stamps">
                    {checkStampsNumber(data.stamps || [])}
                  </h2>
                  <span>selos</span>
                </div>
                <div className="coins-tool-tip">
                  <div className="user-stamps-itens">
                    <h2 className="number-stamps">
                      <MdMonetizationOn
                        size={20}
                        color={darkmode ? '#bfbfbf' : '#000'}
                      />
                      {data.coins < 10 ? `0${data.coins}` : data.coins}
                    </h2>
                    <span>blue coins</span>
                  </div>
                  <div className="tool-tip-coins">
                    <span>Em breve...</span>
                  </div>
                </div>

                <div className="user-stamps-itens">
                  <h2 className="number-stamps">
                    {formatYears(data.dateEntry || '00/00/00').number}
                  </h2>
                  <span>{formatYears(data.dateEntry || '00/00/00').text}</span>
                </div>
              </div>
              {/* {<div style={{ marginTop: '20px', width: '100%' }}>
                <Stamps userStamps={data.stamps || []} />
              </div>} */}
              {/* 
              <div style={{ marginTop: '20px', width: '100%' }}>
                <Trajectory historic={data.historic || []} />
              </div> */}
            </section>
            <section>
              <div className="user-infos-top">
                <div className="user-infos-top-name">
                  <h1 className="top-name-h1">
                    {data.name ? formatName(data.name) : ''}
                  </h1>
                  <span className="top-name-span">{data.office}</span>
                  <Tags
                    tagColor={[data.tag]}
                    index={0}
                    actionClick={() => setTagOpen(!tagOpen)}
                    tagsOpen={tagOpen}
                  />
                  {memberId === data.ownerId && (
                    <button
                      type="button"
                      onClick={() => setOnEditProfile(true)}
                      className="edit-my-profile"
                    >
                      Editar perfil
                    </button>
                  )}
                </div>
                <div className="social-media-icons">
                  <a
                    href={data.instagramUrl}
                    target="_blank"
                    className={`insta-tool ${
                      !data.instagramUrl || data.instagramUrl === '-'
                        ? 'disabled'
                        : ''
                    }`}
                  >
                    <img src={InstagramIcon} alt="instagram" />
                  </a>
                  <div className="tool-tip-insta">
                    <span>Instagram</span>
                  </div>
                  <a
                    href={data.linkedinUrl}
                    target="_blank"
                    className={`linkedin-tool ${
                      !data.instagramUrl || data.instagramUrl === '-'
                        ? 'disabled'
                        : ''
                    }`}
                  >
                    <img src={LinkedinIcon} alt="linkedin" />
                  </a>
                  <div className="tool-tip-linkedin">
                    <span>Linkedin</span>
                  </div>
                </div>
              </div>

              <div className="user-infos-section">
                <div className="user-infos-fields">
                  <span>Quem sou eu?</span>
                  <ul className="user-infos-text-value">
                    <li>
                      <p>Nome completo: </p>
                      <p>{data.name}</p>
                    </li>
                    <li>
                      <p>Apelido:</p>
                      <p>{data.nickname}</p>
                    </li>
                    <li>
                      <p>Cidade natal:</p>
                      <p>{data.hometown}</p>
                    </li>
                    <li className="email-field-infos">
                      <p>E-mail:</p>
                      <a
                        href={`mailTo:${data.email}`}
                        target="_blank"
                        className="infos-email"
                      >
                        {data.email}
                      </a>
                    </li>
                  </ul>

                  <span>Formação</span>
                  <ul className="user-infos-text-value">
                    <li>
                      <p>Curso:</p>
                      <p>{data.course}</p>
                    </li>
                    <li>
                      <p>Instituição:</p>
                      <p>{data.institution}</p>
                    </li>
                  </ul>

                  {showMore && (
                    <>
                      <span>Um pouco sobre mim</span>
                      <ul className="user-infos-text-value">
                        <li>
                          <p>Comida preferida:</p>
                          <p>{data.favoriteFood}</p>
                        </li>
                        <li>
                          <p>Uma bebida:</p>
                          <p>{data.favoriteDrink}</p>
                        </li>
                        <li>
                          <p>Um esporte:</p>
                          <p>{data.favoriteSport}</p>
                        </li>
                        <li>
                          <p>Séries que indico:</p>
                          <div className="li-with-array">
                            {data.seriesIndication.map((serie, index) => (
                              <p>
                                {index === data.seriesIndication.length - 1
                                  ? `${serie}`
                                  : `${serie},`}
                              </p>
                            ))}
                          </div>
                        </li>
                        <li>
                          <p>Estilos musicais preferidos:</p>
                          <div className="li-with-array">
                            {data.musicStyles.map((music, index) => (
                              <p>
                                {index === data.musicStyles.length - 1
                                  ? `${music}`
                                  : `${music},`}
                              </p>
                            ))}
                          </div>
                        </li>
                        <li>
                          <p>Meus hobbies:</p>
                          <div className="li-with-array">
                            {data.hobbies.map((hobbie, index) => (
                              <p>
                                {index === data.hobbies.length - 1
                                  ? `${hobbie}`
                                  : `${hobbie},`}
                              </p>
                            ))}
                          </div>
                        </li>
                        <li>
                          <p>Se fosse criar um app, criaria um que:</p>
                          <p>{data.appIdeia}</p>
                        </li>
                      </ul>
                    </>
                  )}
                  <button
                    onClick={() => setShowMore(!showMore)}
                    className="view-more-btn"
                  >
                    {showMore ? 'Ver menos' : 'Ver mais'}
                    {showMore ? (
                      <MdArrowDropUp size={24} />
                    ) : (
                      <MdArrowDropDown size={24} />
                    )}
                  </button>
                </div>
              </div>
              <NewDepoiment
                formatName={formatName}
                data={data}
                setChange={setChange}
                memberId={memberId}
                api={api}
                darkmode={darkmode}
              />
              <div className="depoiments-area-infos">
                {modalIsOpen && (
                  <Depoiments
                    userId={data.ownerId}
                    change={change}
                    darkmode={darkmode}
                  />
                )}
              </div>
            </section>
          </div>
        </ClickAwayListener>
      )}
    </Modal>
  )
}

UserInfosModal.defaultProps = {
  user: {},
  onChange: null,
  changeIn: false
}

UserInfosModal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  actionClose: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([PropTypes.object]),
  onChange: PropTypes.func,
  changeIn: PropTypes.bool
}

export default UserInfosModal
