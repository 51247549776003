import moment from 'moment'

const checkUserFiveMinutesOff = ({
  profileWorking,
  inVocation,
  hourEntry,
  setModalIsOpen
}) => {
  setModalIsOpen(false)
  if (!profileWorking.working && !inVocation) {
    const now = moment(new Date())
    const duration = moment.duration(now.diff(hourEntry))
    const minutes = duration.asMinutes()
    if (minutes >= 5) {
      setModalIsOpen(true)
    }
  }
}

export { checkUserFiveMinutesOff }
