import { toast } from 'react-toastify'
import { memorianAPI, api } from '../../../services'
import { getWorkingHoursReq } from '../../../store/modules/user/actions'
import { formatDate } from '../../../utils'

const sendMailToRh = async ({ name, avatar }) => {
  const date = formatDate(new Date())
  const data = {
    name,
    avatar,
    date
  }
  await api.post('/sendmail', data)
}

const requestWorkScheduleCorrection = async ({
  dataObj,
  dateNow,
  id,
  profile,
  openModal,
  dispatch,
  setModalIsOpen,
  setModalOpen,
  setStates
}) => {
  const { name, avatar_url } = profile
  await memorianAPI
    .post('/use/functions/requestWorkScheduleCorrection', dataObj)
    .then(
      () => {
        sendMailToRh({ name, avatar: avatar_url }).then(() => {
          setModalIsOpen(true)
          setModalOpen('CONFIRM_REQ')
          dispatch(getWorkingHoursReq(dateNow, id))
          setStates()
        })
      },
      (reject) => {
        const { data } = reject.response
        if (data.code === 656 || data.code === 657 || data.code === 672) {
          openModal(
            'error',
            'Ops... Não é possível realizar uma correção de ponto para o dia atual. Realize a solicitação no seu próximo dia de trabalho.'
          )
        } else {
          toast.error(
            'Ops...Encontramos um problema por aqui. Tente novamente.'
          )
        }
      }
    )
}

export { requestWorkScheduleCorrection, sendMailToRh }
