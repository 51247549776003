import React from 'react'
import PropTypes from 'prop-types'

import TeamsList from '../TeamsList'

import { getTeams, handleClick, handleScroll } from './functions'

import './darkmode.css'
import './styles.css'

function Company({ company }) {
  const [teams, setTeams] = React.useState([])
  const [current, setCurrent] = React.useState(0)

  const scrollableDiv = React.useRef()

  React.useEffect(() => {
    getTeams({ company, setTeams, setCurrent })
  }, [company])

  return (
    <div className="team-area">
      <nav className="nav-team">
        <ul className="nav-ul">
          {teams.map((item, index) => (
            <li
              key={item.value}
              className={
                current === index ? 'nav-team-li active' : 'nav-team-li'
              }
            >
              <button
                type="button"
                className={`${item.value}-nav`}
                onClick={() =>
                  handleClick({ index, scrollableDiv, setCurrent })
                }
              >
                {item.title}
              </button>
            </li>
          ))}
        </ul>
      </nav>

      <div
        className="teams-lists"
        ref={scrollableDiv}
        onScroll={() => handleScroll({ teams, scrollableDiv, setCurrent })}
      >
        {teams.map((item) => (
          <section
            className="div-teams-scroll"
            id={item.value}
            key={item.value}
          >
            <TeamsList team={item} company={company} />
          </section>
        ))}
      </div>
    </div>
  )
}

Company.propTypes = {
  company: PropTypes.string.isRequired
}

export default Company
