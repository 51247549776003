/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable dot-notation */
import React, { useState } from 'react'
import PropTypes, { shape } from 'prop-types'
import { useSelector } from 'react-redux'
import { MdContentCopy, MdError, MdCheckCircle, MdCancel } from 'react-icons/md'
import { Tooltip } from 'antd'

import MoreInfos from '../MoreInfos'
import ModalPendingCorrections from '../../pages/Home/components/ModalPendingCorrection'
import Tags from '../Tags'
import { formatDate } from '../../utils'

import EditAccountGreen from '../../assets/icons/editAccountGreen.svg'
import CloseIcon from '../../assets/icons/CloseIcon.svg'

import './style.css'
import './darkmode.css'

const IconStyled = (size) => {
  const darkmode = useSelector((state) => state.user.darkmode)
  return {
    refused: (
      <MdCancel
        size={size}
        color={darkmode ? '#FF796B' : '#E74C3C'}
        style={{ alignSelf: 'center' }}
      />
    ),
    approved: (
      <MdCheckCircle
        size={size}
        color={darkmode ? '#58D68D' : '#2ECC71'}
        style={{ alignSelf: 'center' }}
      />
    ),
    pending: (
      <MdError
        size={size}
        color={darkmode ? '#FFDF61' : '#FFBF00'}
        style={{ alignSelf: 'center' }}
      />
    )
  }
}

/**
 * Modal called by Home
 */
export function ConnectedAlert() {
  return (
    <>
      <span className="modal-title">USUÁRIO DESCONECTADO</span>
      <div className="modal-items">
        {IconStyled(35)['refused']}
        <p className="modal-text">Ops...</p>
        <p className="modal-text">
          Você está desconectado(a) do sistema de ponto!
        </p>
      </div>
    </>
  )
}
/**
 * Modal called by EditProfile
 */
export function EditProfile() {
  return (
    <>
      <span className="modal-title">EDIÇÃO DE PERFIL</span>
      <img src={EditAccountGreen} alt="editIcon" />
      <p className="modal-text-forgot">
        Cadastro finalizado com sucesso.
        <br />
        Agora você pode utilizar nossa plataforma!
      </p>
    </>
  )
}

/**
 * Modal called by list courses
 * @param {Object} course - course object
 */
export function CourseModal({ course, action, tagsOpen, actionClick }) {
  const tooltips = {
    password: 'copiar',
    access: 'copiar'
  }
  const [{ password, access }, setState] = useState(tooltips)

  const copy = (text, key) => {
    navigator.clipboard.writeText(text)
    setState((prevState) => ({ ...prevState, [key]: 'copiado!' }))
    setTimeout(() => {
      setState(tooltips)
    }, 2000)
  }

  // Render Items
  return (
    <>
      <div className="title-exit">
        <h1 className="modal-courses-h1">{course.title}</h1>
        <button type="button" onClick={action} className="exit">
          <img src={CloseIcon} alt="X" />
        </button>
      </div>
      <h2 className="modal-courses-h2">{course.autor}</h2>
      <span className="modal-courses-area">
        {course.instituition.map((item) => {
          return (
            <span className="insituition-class">{item !== 'all' && item} </span>
          )
        })}
        | Adicionado em:
        {formatDate(course.createdAt)}
      </span>
      <Tags
        tagColor={course.tagColor}
        tagsOpen={tagsOpen}
        actionClick={actionClick}
      />

      <div className="item-course-info">
        <p className="modal-courses-titles">Link para o curso</p>
        <a
          className="modal-courses-link"
          target="_blank"
          href={course.courseUrl}
        >
          Acesse o curso na plataforma clicando aqui.
        </a>
      </div>

      <div className="item-course-info">
        <p className="modal-courses-titles">Login</p>

        <div className="copy-acess">
          <p className="modal-courses-acess">{course.acessEmail}</p>
          {document.queryCommandSupported('copy') && (
            <Tooltip title={access} placement="bottom">
              <button
                type="button"
                onClick={() => copy(course.acessEmail, 'access')}
              >
                <MdContentCopy size={20} color="#707070" />
              </button>
            </Tooltip>
          )}
        </div>
      </div>

      <div className="item-course-info">
        <p className="modal-courses-titles">Senha</p>

        <div className="copy-acess">
          <p className="modal-courses-acess" id="acessPassword">
            {course.acessPassword}
          </p>
          {document.queryCommandSupported('copy') && (
            <Tooltip title={password} placement="bottom">
              <button
                type="button"
                onClick={() => copy(course.acessPassword, 'password')}
              >
                <MdContentCopy size={20} color="#707070" />
              </button>
            </Tooltip>
          )}
        </div>
      </div>
    </>
  )
}

export function ConfirmEditWorkReq({ action }) {
  return (
    <>
      <span className="modal-title">Correção de Ponto</span>
      {IconStyled(35)['approved']}
      <section className="text-area">
        <p className="modal-text-forgot">
          Solicitação de correção de ponto enviada com sucesso! O<br />
          ajuste ficará pendente até que seja validado pelo RH.
        </p>
      </section>

      <button
        className="button-primary"
        onClick={action}
        style={{ alignSelf: 'center', marginTop: 20 }}
      >
        Ok
      </button>
    </>
  )
}

export function CancelEditWork({ action, action2 }) {
  return (
    <>
      <span className="modal-title">Correção de Ponto</span>
      {IconStyled(35)['refused']}
      <section className="text-area">
        <p className="modal-text-forgot">
          Deseja realmente cancelar sua solicitação de correção de
          <br />
          ponto? Os ajustes preenchidos
          <br />
          serão excluídos.
        </p>
      </section>

      <div className="btn-group-cancel">
        <button className="button-secondary btn-cancel" onClick={action2}>
          Voltar
        </button>
        <button className="button-primary btn-cancel" onClick={action}>
          Sim, quero cancelar
        </button>
      </div>
    </>
  )
}

export function ConfirmationEditWork({ date, action }) {
  const darkmode = useSelector((state) => state.user.darkmode)
  const [showMore, setShowMore] = useState(false)
  const [showPending, setShowPending] = useState(false)
  const [obj, setObj] = useState({})
  const [ind, setInd] = useState(0)

  const setItems = (item, index, type) => {
    setObj(item)
    setInd(index)
    type === 'pending' ? setShowPending(true) : setShowMore(true)
  }

  return (
    <>
      <span className="modal-title">Correção de Ponto</span>

      <MoreInfos
        item={obj}
        index={ind}
        action={() => setShowMore(false)}
        darkmode={darkmode}
        showMore={showMore}
      />

      {showPending && (
        <ModalPendingCorrections
          modalIsOpen={showPending}
          darkmode={darkmode}
          data={obj || { reason: '', hours: [], date: '' }}
          actionClose={() => setShowPending(false)}
        />
      )}

      <>
        <div className="modal-confirmation-title">
          {IconStyled(35)['pending']}
          <span className="modal-text">
            Confira o resultado de suas solicitações pendentes:
          </span>
        </div>
        <div className="confirm-padding">
          <section className="text-area-overflow">
            {date.map((item, index) => (
              <div className="item-cofirm">
                {IconStyled(20)[item.status]}
                <p
                  className={`modal-text-confirm-${item.status} modal-text-confirm`}
                >{`Ajuste ${index + 1}:`}</p>
                <p className="confirm-date">{item.date}:</p>
                <div className="texts-informations">
                  {item.status === 'approved' ? (
                    <p>Solicitação validada com sucesso.</p>
                  ) : (
                    <div className="invalid-correction">
                      <p>
                        {item.status === 'pending'
                          ? 'Itens pendentes'
                          : 'Solicitação invalidada'}
                      </p>
                      <button
                        className="more-infos"
                        onClick={() =>
                          setItems(
                            item,
                            index,
                            item.status === 'pending' ? 'pending' : 'refused'
                          )
                        }
                      >
                        Saiba mais
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </section>
        </div>

        <button
          className="button-primary"
          style={{ alignSelf: 'center', marginBottom: '2%', marginTop: '5%' }}
          onClick={action}
        >
          Ok
        </button>
      </>
    </>
  )
}
/**
 * Props validation
 */
CourseModal.defaultProps = {
  course: {}
}
CourseModal.propTypes = {
  course: PropTypes.objectOf(shape({})),
  action: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  tagsOpen: PropTypes.bool.isRequired,
  actionClick: PropTypes.func.isRequired
}

CancelEditWork.propTypes = {
  action: PropTypes.func.isRequired,
  action2: PropTypes.func.isRequired
}

ConfirmEditWorkReq.propTypes = {
  action: PropTypes.func.isRequired
}

ConfirmationEditWork.propTypes = {
  date: PropTypes.arrayOf(shape([])).isRequired,
  action: PropTypes.func.isRequired
}
