const key = process.env.REACT_APP_HASH_KEY

const encryptKey = (pass) => {
  const encryptor = require('simple-encryptor')(key)
  const newCode = encryptor.encrypt(pass)
  return newCode
}

const decryptKey = (pass) => {
  const encryptor = require('simple-encryptor')(key)
  const newCode = encryptor.decrypt(pass)
  return newCode
}

export { encryptKey, decryptKey }
