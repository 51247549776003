import React from 'react'
import PropTypes, { shape } from 'prop-types'
import { GridLoader } from 'react-spinners'
import { newFormatData } from '../../../utils'

import { api } from '../../../services'

import { getInCache } from '../../Avatar/functions/getInCache'
import { saveInCache } from '../../Avatar/functions/saveInCache'

import UseLogo from '../../../assets/icons/useLogo.svg'

function Item({ item, handleOpenNotify }) {
  const [url, setUrl] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const getAvatar = async (name, avatar) => {
    const nameUser = name.split(' ')
    const data = {
      name,
      avatar
    }

    setLoading(true)
    getInCache(data)
      .then((cache) => setUrl(cache.url))
      .catch((_) => {
        if (!avatar || avatar === '') {
          setUrl(
            `https://avatar.oxro.io/avatar.svg?name=${nameUser[0]}+${nameUser[1]}&background=f6f6f6&length=2&color=00b7ef`
          )
        } else {
          api
            .get(`/file/${avatar}`)
            .then((res) => {
              saveInCache(res.data, data)
              setUrl(res.data)
            })
            .catch((err) => console.error(err))
        }
      })
    setLoading(false)
  }

  React.useEffect(() => {
    if (item.from.name && item.from.name !== 'Usemobile') {
      getAvatar(item.from.name, item.from.avatar)
    }
  }, [item.from.avatar, item.from.name])

  return (
    <li className="notification-item" onClick={handleOpenNotify}>
      <div className="avatar-and-notification">
        {!item.read ? (
          <div className="not-read-symbol" />
        ) : (
          <div style={{ width: '10px', height: '10px' }} className="none" />
        )}
        <div className="avatar-area-notify">
          {item.from.name === 'Usemobile' || !item.from.name ? (
            <div
              className="avatar"
              style={{ backgroundImage: `url(${UseLogo})` }}
            />
          ) : (
            <>
              {loading ? (
                <div className="loader-img card-team-avatar">
                  <GridLoader size={2} color="#fff" />
                </div>
              ) : (
                <div
                  className="avatar"
                  style={{
                    backgroundImage: `url(${url})`
                  }}
                />
              )}
            </>
          )}
        </div>
        <p>{item.notification}</p>
      </div>
      <span>{newFormatData(item.createdAt)}</span>
    </li>
  )
}

Item.propTypes = {
  item: PropTypes.objectOf(shape({})).isRequired,
  handleOpenNotify: PropTypes.func.isRequired
}

export default Item
