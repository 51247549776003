import React, { useState } from 'react'
import { MdVisibilityOff, MdVisibility, MdCheck } from 'react-icons/md'
import { ClipLoader } from 'react-spinners'
import { onlyNumbers } from '../../utils'

import { Header, StyledBasicInput, ModalCancelRegister } from '../../components'

import { history } from '../../services'

import { resetPassword } from './functions'

import './styles.css'

function ForgotPassword() {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorPass, setErrorPass] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const [visible, setVisible] = useState(false)

  const user_forgot = JSON.parse(sessionStorage.getItem('user-forgot'))

  const handleVisible = (hook, setHook) => {
    setHook(!hook)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const key = user_forgot.verification
    await resetPassword({
      password,
      confirmPassword,
      key,
      setErrorPass,
      setLoading
    })
  }

  const handleCancel = () => {
    history.push('/login')
    setModalIsOpen(false)
  }

  return (
    <div className="global-container">
      <ModalCancelRegister
        open={modalIsOpen}
        actionClose={() => setModalIsOpen(false)}
        typeCancel="a edição de senha"
        action={handleCancel}
      />
      <Header />
      <div className="global-content global-content-grey content-md-size">
        <span className="title">Redefinição de senha</span>
        <hr className="title-bar" />

        <form className="form-forgot" onSubmit={handleSubmit}>
          <div className="forgot-inputs">
            <div className="item-forgot-1">
              <StyledBasicInput
                label="Seu código de acesso"
                value={user_forgot.acessCode}
                disabled
              />
            </div>
            <div className="item-forgot-1">
              <div className="input-w-message">
                <StyledBasicInput
                  type={visible ? 'text' : 'password'}
                  label="Senha"
                  value={password}
                  maxLength={4}
                  Icon={visible ? MdVisibilityOff : MdVisibility}
                  onVisible={() => handleVisible(visible, setVisible)}
                  onChange={(e) => setPassword(onlyNumbers(e.target.value))}
                  showIcon={password.length >= 1}
                  error={errorPass}
                />
                <span className="forgot-span-input">*4 digítos numéricos</span>
              </div>
            </div>
            <div className="item-forgot-1">
              <StyledBasicInput
                type={visible ? 'text' : 'password'}
                label="Confirme a senha"
                value={confirmPassword}
                maxLength={4}
                Icon={visible ? MdVisibilityOff : MdVisibility}
                onVisible={() => handleVisible(visible, setVisible)}
                onChange={(e) =>
                  setConfirmPassword(onlyNumbers(e.target.value))
                }
                showIcon={confirmPassword.length >= 1}
                error={errorPass}
                errorMesage="As senhas não coincidem."
              />
            </div>
          </div>

          <div className="forgot-btn-group">
            <button
              className="button-secondary btns-forgot"
              type="button"
              onClick={() => setModalIsOpen(true)}
            >
              Cancelar
            </button>
            <button
              className="button-primary btns-forgot"
              type="submit"
              disabled={(password.length && confirmPassword.length) < 4}
            >
              Salvar
              {loading ? (
                <div className="loader-margin">
                  <ClipLoader size={20} color="#fff" />
                </div>
              ) : (
                <MdCheck size={20} style={{ marginLeft: '8px' }} />
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ForgotPassword
