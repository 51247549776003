import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Timer from '../Timer'

function TitleTimer({ working, item, newItem, actualData, itemResponsive }) {
  const darkmode = useSelector((state) => state.user.darkmode)

  const itemTimer = () => {
    return (
      <div className="title-texts">
        <p className={`${darkmode && 'darkmode'} title-positive`}>
          {newItem[0]}
        </p>
        <p className={`${darkmode && 'darkmode'} title-positive`}>
          {itemResponsive}
        </p>
        <div className="item-center">
          {working && item.date === actualData ? (
            <Timer />
          ) : (
            <p
              className={`${darkmode && 'darkmode'}
              ${
                !item.notDecrease
                  ? `${item.negative ? 'hours-negative' : 'hours-positive'}`
                  : 'hours-not-decrease'
              }
              `}
            >
              {newItem[1]}
            </p>
          )}

          {item.balanceAfterFix && (
            <p className="title-correction">{`(Correção Pendente: Total: ${item.balanceAfterFix})`}</p>
          )}
        </div>
      </div>
    )
  }
  return itemTimer()
}

TitleTimer.defaultProps = {
  item: {}
}

TitleTimer.propTypes = {
  working: PropTypes.bool.isRequired,
  item: PropTypes.oneOfType([PropTypes.object]),
  newItem: PropTypes.oneOfType([PropTypes.array]).isRequired,
  actualData: PropTypes.string.isRequired,
  itemResponsive: PropTypes.string.isRequired
}

export default TitleTimer
