import axios from 'axios'

let url = window.location.href.split('/')
url.length = 3

const newUrl = `https://${url[2]}/`
const newUrl2 = `http://${url[2]}/`

const API_URL =
  newUrl !== process.env.REACT_APP_URL_PROD_HTTPS ||
  newUrl2 !== process.env.REACT_APP_URL_PROD_HTTP
    ? process.env.REACT_APP_API_HOURS_DEV
    : process.env.REACT_APP_API2_URL

export const memorianAPI = axios.create({
  baseURL: API_URL
})
