import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { GridLoader } from 'react-spinners'
import { getInCacheDp } from './functions/getInCacheDpAvatars'

import './styles.css'

function Avatar({ user, userName }) {
  const darkmode = useSelector((state) => state.user.darkmode)

  const [avatarUrl, setAvatarUrl] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    const getAvatar = async () => {
      setLoading(true)
      await getInCacheDp({ name: userName, memberId: user }).then((res) => {
        setAvatarUrl(res.url)
        setLoading(false)
      })
    }
    getAvatar()
  }, [user])

  return loading ? (
    <div style={{ width: '40px', height: '40px' }}>
      <GridLoader size={7} color={darkmode ? '#BFBFBF' : '#00b7ef'} />
    </div>
  ) : (
    <div className="avatar" style={{ backgroundImage: `url(${avatarUrl})` }} />
  )
}

Avatar.propTypes = {
  user: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired
}

export default Avatar
