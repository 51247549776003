const messages = {
  674: 'Você ainda não cumpriu o prazo de 1 hora de horário de almoço, deseja realizar check-in mesmo assim?',
  673: 'Estamos fora do período destinado ao almoço (11h às 14h).\nDeseja iniciar seu horário de almoço mesmo assim?',
  672: 'Ops... Não é possivel realizar uma correção de ponto\npara o dia atual. Realize a solicitação no seu próximo\ndia de trabalho.',
  671: 'Não é possível realizar checkin antes das 07:00 tente novamente.'
}

const textsBtn = {
  674: 'Check-in',
  671: 'Check-in',
  673: 'Check-out'
}

const returnMessages = (code) => {
  return messages[code.toString()]
}

const returnModalBtn = (code) => {
  return textsBtn[code.toString()]
}

export { returnMessages, returnModalBtn }
