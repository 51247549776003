import { useEffect, useMemo, useState } from 'react'

const useDisabled = (items) => {
  const [disabled, setDisabled] = useState(false)

  const requiredFields = useMemo(() => {
    return [...items]
  }, [items])
  useEffect(() => {
    const validaetArr = () => {
      for (let i = 0; i <= requiredFields.length; i += 1) {
        if (requiredFields[i] === '') {
          setDisabled(true)
          break
        } else {
          setDisabled(false)
        }
      }
    }
    validaetArr()
  }, [requiredFields])

  return [disabled]
}

export default useDisabled
