import api from '../../../services/api'

const getFolders = async () => {
  return new Promise((resolve, reject) => {
    api
      .get('/folder')
      .then((res) => resolve(res.data.docs))
      .then((err) => reject(err))
  })
}

export { getFolders }
