import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { MdCheck } from 'react-icons/md'

import './styles.css'
import './darkmode.css'

function StyledCheckBox({ checked, handleClick }) {
  const darkmode = useSelector((state) => state.user.darkmode)

  return (
    <button
      className={`styled-check ${
        checked ? 'styled-check-marked' : 'styled-check-unmarked'
      }`}
      type="button"
      onClick={handleClick}
    >
      {checked && <MdCheck color={darkmode ? '#222222' : '#fff'} />}
    </button>
  )
}

StyledCheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired
}

export default StyledCheckBox
