/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes, { shape } from 'prop-types'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'

import CardTeam from '../CardTeam'

import './styles.css'

function TeamScroll({ teamMembers, handleOpenModal, team, darkmode }) {
  const iconConfig = {
    size: 25,
    color: darkmode ? '#757575' : '#fff'
  }

  const [arrowLeft, setArrowLeft] = React.useState(false)
  const [arrowRigth, setArrowRigth] = React.useState(false)
  const scrolDiv = React.useRef()

  React.useEffect(() => {
    const showArrowRigth = () => {
      const { clientWidth } = scrolDiv.current
      if (
        (clientWidth === 650 && teamMembers.length > 3) ||
        (clientWidth > 800 && teamMembers.length > 4)
      ) {
        setArrowRigth(true)
      }
    }
    showArrowRigth()
  }, [teamMembers])

  const handleScroll = () => {
    const { scrollLeft, clientWidth, scrollWidth } = scrolDiv.current

    const limit = scrollWidth - clientWidth

    scrollLeft < limit ? setArrowRigth(true) : setArrowRigth(false)
    scrollLeft !== 0 ? setArrowLeft(true) : setArrowLeft(false)
  }

  const scrollToRigth = () => {
    const { scrollLeft, clientWidth, scrollWidth } = scrolDiv.current
    const limit = scrollWidth - clientWidth
    if (scrollLeft < limit) {
      scrolDiv.current.scrollLeft += 100
    }
  }

  const scrollToLeft = () => {
    const { scrollLeft } = scrolDiv.current
    if (scrollLeft !== 0) {
      scrolDiv.current.scrollLeft -= 100
    }
  }

  return (
    <div className="arrow-area">
      {arrowLeft && (
        <button
          className="arrows-scroll arrow-scroll-left"
          type="button"
          onClick={scrollToLeft}
        >
          <MdChevronLeft {...iconConfig} />
        </button>
      )}
      <div className="cards-area" ref={scrolDiv} onScroll={handleScroll}>
        {teamMembers.map((item) => (
          <div
            className="card-item"
            key={item.name}
            role="button"
            tabIndex={0}
            onClick={() => handleOpenModal(item, item.avatar, item.coins)}
          >
            <CardTeam item={item} team={team} darkmode={darkmode} />
          </div>
        ))}
      </div>
      {arrowRigth && (
        <button
          className="arrows-scroll arrow-scroll-rigth"
          type="button"
          onClick={scrollToRigth}
        >
          <MdChevronRight {...iconConfig} />
        </button>
      )}
    </div>
  )
}

TeamScroll.defaultProps = {
  team: ''
}

TeamScroll.propTypes = {
  teamMembers: PropTypes.arrayOf(shape([])).isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  team: PropTypes.oneOfType([PropTypes.string]),
  darkmode: PropTypes.bool.isRequired
}

export default TeamScroll
