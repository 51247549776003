/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'
import { MdError } from 'react-icons/md'
import { ClipLoader } from 'react-spinners'

import './style.css'
import './darkmode.css'
/**
 * Styled Input with icon
 * @param {Element} Icon - Icon that will be rendered
 * @param {string} color - Icon color
 * @param {string} labeel - Animated input text
 */
export default function StyledBasicInput({
  label,
  error,
  mask,
  errorMesage,
  loader,
  Icon,
  showIcon,
  onVisible,
  ...rest
}) {
  return (
    <div className="styled-input-basic">
      {Icon && showIcon && (
        <button onClick={onVisible} className="icon-2" type="button">
          {Icon && showIcon && <Icon size={20} color="#757575" />}
        </button>
      )}

      <InputMask
        {...rest}
        mask={mask}
        className={
          error ? 'input-text-basic input-text-error-basic' : 'input-text-basic'
        }
      />
      {label && (
        <label htmlFor="styled-input" className="input-label-basic">
          {label}
        </label>
      )}
      {loader && (
        <div className="spinner-input">
          <ClipLoader size={20} color="#757575" />
        </div>
      )}
      {error && errorMesage && (
        <div className="error-message-input">
          <MdError size={18} />
          <label htmlFor="styled-input" className="error-message-label">
            {errorMesage}
          </label>
        </div>
      )}
    </div>
  )
}
/** ******************
 * Props Validation *
 ******************* */
StyledBasicInput.defaultProps = {
  label: false,
  error: false,
  mask: '',
  errorMesage: '',
  loader: false,
  Icon: null,
  showIcon: false,
  onVisible: null
}
StyledBasicInput.propTypes = {
  label: PropTypes.string,
  error: PropTypes.bool,
  mask: PropTypes.string,
  errorMesage: PropTypes.string,
  loader: PropTypes.bool,
  Icon: PropTypes.element,
  showIcon: PropTypes.bool,
  onVisible: PropTypes.func
}
