import React from 'react'
import PropTypes, { shape } from 'prop-types'

import { MdDelete } from 'react-icons/md'
import { Popconfirm } from 'antd'
import { formatName, formatDateParse } from '../../utils'

import ChatBuble from '../../assets/icons/chatBuble.svg'

import Avatar from '../Depoiments/Avatar'

function Depoiment({
  memberId,
  item,
  handleDelete,
  darkmode,
  handleOpenProfile
}) {
  const [openBtn, setOpenBtn] = React.useState(false)
  const showDelete = (depoiment) => {
    if (memberId === depoiment.to || memberId === depoiment.from) {
      setOpenBtn(true)
    }
  }

  return (
    <div
      className="depoiment"
      role="button"
      tabIndex={0}
      onMouseEnter={() => showDelete(item)}
      onMouseLeave={() => setOpenBtn(false)}
    >
      <div
        role="button"
        tabIndex={0}
        onClick={handleOpenProfile}
        style={{ cursor: 'pointer' }}
      >
        <Avatar user={item.from} userName={item.nameUser} />
      </div>

      <img src={ChatBuble} alt="chat" className="seta-bal" />
      <div className="ballom-and-data">
        <div
          className={`depoiment-balom ${openBtn && 'open'}`}
          role="button"
          tabIndex={0}
        >
          <span
            className={`${item.colorUser}-text span-name-depoiment`}
            role="button"
            tabIndex={0}
            onClick={handleOpenProfile}
          >
            {item.nameUser ? formatName(item.nameUser) : ''}
          </span>
          <p>{item.depoiment}</p>
        </div>
        <span className="span-data">{formatDateParse(item.createdAt)}</span>
      </div>
      {(item.to === memberId || item.from === memberId) && (
        <Popconfirm
          placement="topRight"
          title="Deseja mesmo excluir esse depoimento?"
          onConfirm={handleDelete}
          okText="Sim"
          cancelText="Não"
          overlayClassName={`${darkmode && 'darkmode'} depoiment-popover`}
        >
          {openBtn && (
            <button className="delete-depoiment" type="button">
              <MdDelete size={25} color={darkmode ? '#FF796B' : '#e74c3c'} />
              <div className="tool-tip-button">Excluir</div>
            </button>
          )}
        </Popconfirm>
      )}
    </div>
  )
}

Depoiment.propTypes = {
  item: PropTypes.objectOf(shape({})).isRequired,
  memberId: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
  darkmode: PropTypes.bool.isRequired,
  handleOpenProfile: PropTypes.func.isRequired
}

export default Depoiment
