import React from 'react'
import PropTypes, { shape } from 'prop-types'
import { useSelector } from 'react-redux'

import {
  Stamp1,
  Stamp2,
  Stamp3,
  Stamp4,
  Stamp5,
  Stamp6,
  Stamp7,
  Stamp8,
  Stamp9,
  Stamp10,
  Stamp11
} from './icons'

import StampsModal from '../StampsModal'

import './styles.css'
import './darkmode.css'

function Stamps({ userStamps }) {
  const darkmode = useSelector((state) => state.user.darkmode)
  const [data, setData] = React.useState([])
  const [modalIsOpen, setModalIsOpen] = React.useState(false)

  React.useEffect(() => {
    setData(userStamps || [])
  }, [userStamps])

  const stamps = [
    { value: Stamp1, event: 'textOnBlog', text: 'Texto publicado no blog' },
    { value: Stamp2, event: 'committee', text: 'Participação no comitê' },
    {
      value: Stamp3,
      event: 'blueDirectory',
      text: 'Apresentação no Diretório Azul'
    },
    { value: Stamp4, event: 'lectureUse', text: 'Palestra em nome da Use' },
    {
      value: Stamp5,
      event: 'hiringIndication',
      text: 'Indicou uma nova contratação'
    },
    {
      value: Stamp6,
      event: 'customerIndication',
      text: 'Indicação de um novo cliente'
    },
    {
      value: Stamp7,
      event: 'podcast',
      text: 'Participação no Podcast Debugados'
    },
    { value: Stamp8, event: 'oneYearUse', text: '1 ano de casa na Usemobile' },
    { value: Stamp9, event: 'wonAContest', text: 'Venceu um concurso da Use' },
    { value: Stamp10, event: 'techLead', text: 'Lidera uma área da empresa' },
    { value: Stamp11, event: 'firstInRank', text: 'Chegou ao topo do ranking' }
  ]

  return (
    <>
      {stamps.length > 0 && (
        <div className="stamps-class">
          <StampsModal
            stamps={stamps}
            userStamps={data}
            modalIsOpen={modalIsOpen}
            actionClose={() => setModalIsOpen(false)}
            darkmode={darkmode}
          />
          <span>SELOS</span>
          <div
            className="stamps-area"
            onClick={() => setModalIsOpen(true)}
            role="button"
            tabIndex={0}
          >
            {data.map((item) => (
              <div key={`${item}-${Math.random()}`}>
                {stamps.map((item2) => {
                  return (
                    item.name === item2.event && (
                      <img src={item2.value} alt="stamp" key={item} />
                    )
                  )
                })}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

Stamps.propTypes = {
  userStamps: PropTypes.arrayOf(shape([])).isRequired
}

export default Stamps
