import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Tooltip } from 'antd'
import MemorianLogo from '../../assets/MEMORIAN.svg'

import UserInfosModal from '../UserInfosModal'
import HeaderMenu from '../HeaderMenu'
import DropDown from '../DropDown'
import Notifications from '../Notifications'

import { api } from '../../services'

import './style.css'
import './darkmode.css'

export default function Header() {
  const profile = useSelector((state) => state.user.profile)
  const darkmode = useSelector((state) => state.user.darkmode)
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const [finalized, setFinalized] = React.useState(false)
  const [myProfile, setMyProfile] = React.useState({})
  const [myId, setMyId] = React.useState('')
  const [nameUser, setNameUser] = React.useState('')

  const id = useSelector((state) => state.auth.sessionToken)

  React.useEffect(() => {
    if (profile) {
      setFinalized(profile.finalized)
      if (profile.finalized) {
        setMyId(id)
        setNameUser(profile.name.split(' ')[0])
      }
    }
  }, [])

  const noFirstEdit = sessionStorage.getItem('no-first-edit')
  const handleRemoveItem = () => {
    if (noFirstEdit) {
      sessionStorage.removeItem('no-first-edit')
    }
  }

  const handleOpen = async () => {
    await api.get(`/teamMember/${myId}`).then(({ data }) => {
      setMyProfile(data)
      setModalIsOpen(true)
    })
  }

  return (
    <>
      <header className="header">
        <section className="logo">
          <nav className="logo-nav">
            <Link to="/" onClick={handleRemoveItem}>
              <img src={MemorianLogo} alt="Memorian" />
            </Link>
          </nav>
        </section>
        <span className="name-header">Olá, {nameUser || 'User'}!</span>
        <div className="avatar-and-drop">
          {finalized && (
            <Tooltip placement="bottom" title="Notificações">
              <div>
                <Notifications
                  darkmode={darkmode}
                  setModalIsOpen={setModalIsOpen}
                  handleOpenProfile={handleOpen}
                />
              </div>
            </Tooltip>
          )}
          {finalized && (
            <Tooltip placement="bottom" title="Perfil">
              <div className="notification-icon">
                <HeaderMenu
                  profile={profile}
                  modalIsOpen={modalIsOpen}
                  setModalIsOpen={setModalIsOpen}
                  handleOpenProfile={handleOpen}
                />
              </div>
            </Tooltip>
          )}
          {finalized && (
            <Tooltip placement="bottom" title="Mais">
              <div>
                <DropDown actionRemove={handleRemoveItem} darkmode={darkmode} />
              </div>
            </Tooltip>
          )}
        </div>
      </header>

      <UserInfosModal
        modalIsOpen={modalIsOpen}
        actionClose={() => setModalIsOpen(false)}
        user={myProfile}
      />
    </>
  )
}
