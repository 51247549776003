import moment from 'moment'
import { toast } from 'react-toastify'
import { setToken, getHours } from '../functions'
import { logInSucess, logInFailed } from '../../../store/modules/auth/actions'
import { history } from '../../../services'

const loginHelper = async (data, dispatch, prevPath) => {
  try {
    const { token, profile, notifications, avatar_url } = data
    setToken(token)
    const _profile = {
      avatar_url,
      ...profile
    }
    const hours = await getHours(profile.memberId)
    if (_profile.finalized) {
      sessionStorage.setItem('user', JSON.stringify(_profile))
    }
    const hourEntry = moment(new Date())
    dispatch(
      logInSucess(
        token,
        _profile,
        profile.memberId,
        hours,
        hourEntry,
        notifications
      )
    )
    history.push(prevPath)
  } catch (error) {
    toast.error('Ops... Encontramos um problema por aqui')
    dispatch(logInFailed())
  }
}

export { loginHelper }
