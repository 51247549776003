import { takeLatest, all, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import {
  getWorkingHoursSucess,
  updateUserSucess,
  setDarkMode,
  getNotificationsSuccess,
  markAsRead
} from './actions'

import { api, history, memorianAPI } from '../../../services'

const _ApplicationId = process.env.REACT_APP_APLICATION_ID
/**
 * Saga that updates user data
 * @param {Object} payload - Action Payload object
 */
export function* updateProfile({ payload }) {
  try {
    const { user } = payload
    let toastMsg = ''
    if (user.finalized === false) {
      user.finalized = true
      toastMsg = 'Cadastro realizado com sucesso!'
    } else {
      toastMsg = 'Informações alteradas com sucesso!'
    }
    const response = yield call(api.post, '/profile/update', user)
    const newUser = response.data
    if (newUser.avatar !== '') {
      const get_avatar = yield call(api.get, `/file/${newUser.avatar}`)
      const avatar_url = get_avatar.data
      newUser.avatar_url = avatar_url
    } else {
      newUser.avatar_url = ''
    }
    yield put(updateUserSucess(newUser))
    toast.success(toastMsg)
    history.push('/')
  } catch (err) {
    const { status } = err.response
    status === 422
      ? toast.error(
          'Ops...Você se esqueceu de algumas informações, verifique e tente novamente!'
        )
      : toast.error('Ops...Encontramos um problema por aqui. Tente novamente.')
  }
}
/**
 * Saga that fetches data in the hour bank api and saves it in a global state
 * @param {Object} payload - Payload action
 */
export function* getWorkingH({ payload }) {
  try {
    const { date, id } = payload

    const response = yield call(
      memorianAPI.post,
      '/use/functions/getWorkingHours',
      {
        member: id,
        date,
        _ApplicationId
      }
    )
    const user = response.data.result
    yield put(getWorkingHoursSucess(user))
  } catch (error) {
    toast.error('Ops...Encontramos um problema por aqui. Tente novamente.')
  }
}

export function handleDarkmode() {
  localStorage.setItem('darkmode', true)
}

export function handleDarkModeOf() {
  localStorage.removeItem('darkmode')
}

export function* setDarkModeInLogin() {
  const darkmode = localStorage.getItem('darkmode')
  if (darkmode) {
    yield put(setDarkMode())
  }
}

export function* getNotifications({ payload }) {
  const { id } = payload
  const res = yield call(api.get, `/notification/${id}`)
  const arr = res.data.filter((item) => item.read === false)
  const notRead = arr.length
  yield put(getNotificationsSuccess(res.data, notRead))
}

export function* markAsReadFn({ payload }) {
  const { items } = payload
  const arr = items.filter((item) => item.read === false)
  yield call(api.patch, '/notification', { items: arr })
  yield put(markAsRead())
}

export default all([
  takeLatest('@user/UPDATE_USER', updateProfile),
  takeLatest('@user/GET_WORKING_HOURS', getWorkingH),
  takeLatest('@layout/DARKMODE', handleDarkmode),
  takeLatest('@layout/DARKMODE_OF', handleDarkModeOf),
  takeLatest('@auth/LOG_IN_SUCESS', setDarkModeInLogin),
  takeLatest('@user/GET_NOTIFICATIONS_REQ', getNotifications),
  takeLatest('@user/MARK_NOTIFICATIONS_AS_READ_REQ', markAsReadFn)
])
