import React from 'react'
import PropTypes from 'prop-types'

import StyledRadio from '../StyledRadio'

import './darkmode.css'

function RadiosGP({ title, label1, label2, label3, value, setValue }) {
  return (
    <div className="input-radio-gp-academic">
      <label className="radios-label">{title}</label>
      <div className="radios-1">
        <div className="btn-and-label">
          <StyledRadio
            value={value}
            setValue={() => setValue(label1)}
            markValue={label1}
          />
          <label className="label-radio">{label1}</label>
        </div>
        <div className="btn-and-label">
          <StyledRadio
            value={value}
            setValue={() => setValue(label2)}
            markValue={label2}
          />
          <label className="label-radio">{label2}</label>
        </div>
        <div className="btn-and-label">
          <StyledRadio
            value={value}
            setValue={() => setValue(label3)}
            markValue={label3}
          />
          <label className="label-radio">{label3}</label>
        </div>
      </div>
    </div>
  )
}

RadiosGP.propTypes = {
  title: PropTypes.string.isRequired,
  label1: PropTypes.string.isRequired,
  label2: PropTypes.string.isRequired,
  label3: PropTypes.string.isRequired,
  value: PropTypes.element.isRequired,
  setValue: PropTypes.func.isRequired
}

export default RadiosGP
