import React from 'react'
import Modal from 'react-modal'
import { useSelector } from 'react-redux'
import { MdError } from 'react-icons/md'
import PropTypes from 'prop-types'

import './darkmode.css'
import './styles.css'

function ModalDoc({ modalIsOpen, actionClose, actionSubmit }) {
  const darkmode = useSelector((state) => state.user.darkmode)
  return (
    <Modal
      isOpen={modalIsOpen}
      className={`${darkmode && 'darkmode'} modal`}
      overlayClassName="modal-overlay"
    >
      <>
        <span className="modal-title">Documentação pendente</span>
        <div className="modal-items">
          <MdError size={40} color="#FFBF00" />
          <p className="modal-text-forgot">
            Você está avançando sem anexar todos os
            <br />
            documentos. Não se esqueça de anexá-los depois.
          </p>
        </div>
      </>
      <div className="button-modal-2">
        <button className="button-secondary" onClick={actionClose}>
          Voltar
        </button>
        <button className="button-primary" onClick={actionSubmit}>
          Avançar mesmo assim
        </button>
      </div>
    </Modal>
  )
}

ModalDoc.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  actionClose: PropTypes.func.isRequired,
  actionSubmit: PropTypes.func.isRequired
}

export default ModalDoc
