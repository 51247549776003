import React from 'react'
import { MdCheck, MdArrowDropDown, MdArrowDropUp } from 'react-icons/md'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'

import StyledInput from '../StyledBasicInput'
import StyledRadio from '../StyledRadio'
import ToasCustom from '../ToastCustom'
import ModalCancel from '../ModalCancelRegister'
import AutoComplete from '../InputAutoComplete'

import { checkStatusCode } from '../../utils'
import { api } from '../../services'

import './styles.css'

function NewMember() {
  const dispatch = useDispatch()

  const [departments, setDepartments] = React.useState([])
  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')

  const [department, setDepartment] = React.useState('')
  const [office, setOffice] = React.useState('')
  const [admissionDate, setAdmissionDate] = React.useState('')

  const [contractingModel, setContractingModel] = React.useState('')
  const [hoursPerDay, setHoursPerDay] = React.useState('')
  const [company, setCompany] = React.useState('')

  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    const getDepartments = async () => {
      await api
        .get('/department')
        .then((res) => setDepartments(res.data))
        .catch((reject) => checkStatusCode(reject, dispatch))
    }
    getDepartments()
  }, [])

  const emailAndCode = [
    { value: email, setValue: setEmail, label: 'E-mail' },
    {
      value: admissionDate,
      setValue: setAdmissionDate,
      label: 'Data de admissão',
      mask: '99/99/9999',
      placeholder: '__/__/____'
    }
  ]

  const depOffAd = [{ value: office, setValue: setOffice, label: 'Cargo' }]

  const hiringModels = ['Estágio', 'CLT', 'PJ']
  const hoursPerDayArray = [20, 30, 40]
  const companies = ['iTECOOP', 'Usemobile']

  const resetStates = () => {
    const arr = [
      setName,
      setEmail,
      setDepartment,
      setOffice,
      setAdmissionDate,
      setContractingModel,
      setHoursPerDay,
      setCompany
    ]
    arr.map((item) => item(''))
  }

  const handleCancel = () => {
    resetStates()
    setModalIsOpen(false)
  }

  const checkObjs = () => {
    return {
      intern: contractingModel === 'Estágio',
      requestAcademic: contractingModel === 'Estágio',
      requestPJ: contractingModel === 'PJ'
    }
  }

  const buttons = [
    {
      className: 'button-secondary',
      type: 'button',
      label: 'Cancelar',
      icon: false,
      action: () => setModalIsOpen(true)
    },
    {
      className: 'button-primary',
      type: 'submit',
      label: 'Adicionar',
      icon: true
    }
  ]

  const handleSubmit = async (e) => {
    e.preventDefault()
    const newObjs = checkObjs()
    await api
      .post('/register', {
        name,
        email,
        acessCode: '1234',
        department,
        office,
        admissionDate,
        contractingModel,
        hoursPerDay: hoursPerDay / 5,
        company,
        password: '1234',
        memberId: 'asa45',
        salary: '4545',
        ...newObjs
      })
      .then((res) => {
        toast.success(<ToasCustom text="Cadastro realizado com sucesso!" />)
        resetStates()
      })
      .catch((reject) => checkStatusCode(reject, dispatch))
  }

  return (
    <div>
      <ModalCancel
        open={modalIsOpen}
        action={handleCancel}
        actionClose={() => setModalIsOpen(false)}
        typeCancel="o cadastro"
      />
      <form action="submit" className="form-add-member" onSubmit={handleSubmit}>
        <StyledInput
          value={name}
          onChange={(e) => setName(e.target.value)}
          label="Nome completo"
        />
        <div className="email-accesscode">
          {emailAndCode.map((item) => (
            <StyledInput
              value={item.value}
              onChange={(e) => item.setValue(e.target.value)}
              label={item.label}
              disabled={item.disabled}
              placeholder={item.placeholder}
              mask={item.mask ? item.mask : null}
            />
          ))}
        </div>
        <div className="dept-off-admission">
          <AutoComplete
            data={departments}
            label="Departamento"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
            onChangeAutoComplete={(value) => setDepartment(value)}
            valueAuto={department}
            Icon={open ? MdArrowDropUp : MdArrowDropDown}
            onFocus={() => setOpen(true)}
            onBlur={() => setOpen(false)}
            open={open}
          />
          {depOffAd.map((item) => (
            <StyledInput
              value={item.value}
              onChange={(e) => item.setValue(e.target.value)}
              label={item.label}
            />
          ))}
        </div>

        <section className="radios-new-user">
          <div className="radio-new-user-comp">
            <span>Modelo de contratação</span>
            <div className="radio-user-div">
              {hiringModels.map((item) => (
                <div className="btn-and-label">
                  <StyledRadio
                    value={contractingModel}
                    setValue={setContractingModel}
                    markValue={item}
                  />
                  <label className="label-radio">{item}</label>
                </div>
              ))}
            </div>
          </div>
          <div className="radio-new-user-comp">
            <span>Horas semanais</span>
            <div className="radio-user-div">
              {hoursPerDayArray.map((item) => (
                <div className="btn-and-label">
                  <StyledRadio
                    value={hoursPerDay}
                    setValue={setHoursPerDay}
                    markValue={item}
                  />
                  <label className="label-radio">{item}</label>
                </div>
              ))}
            </div>
          </div>
          <div className="radio-new-user-comp">
            <span>Empresa</span>
            <div className="radio-user-div">
              {companies.map((item) => (
                <div className="btn-and-label">
                  <StyledRadio
                    value={company}
                    setValue={setCompany}
                    markValue={item}
                  />
                  <label className="label-radio">{item}</label>
                </div>
              ))}
            </div>
          </div>
        </section>

        <div className="btn-add-group">
          {buttons.map((item) => (
            <button
              type={item.type}
              className={item.className}
              onClick={item.action ? item.action : null}
            >
              {item.label}
              {item.icon && <MdCheck size={25} />}
            </button>
          ))}
        </div>
      </form>
    </div>
  )
}

export default NewMember
