import React from 'react'
import Proptypes from 'prop-types'

import EmptyWindownImage from '../../assets/emptyw.svg'
import EmptyDark from '../../assets/EmptyDark.svg'

import './style.css'
import './darkmode.css'

export default function EmptyWindown({ text, darkmode }) {
  return (
    <div className="empty-message-cp">
      <img
        src={darkmode ? EmptyDark : EmptyWindownImage}
        alt="empty-window"
        className="empty-img"
      />
      <span className="empty-text-cp">
        Ops... Não temos nenhum
        <br />
        {text} disponível por aqui
      </span>
    </div>
  )
}

EmptyWindown.propTypes = {
  text: Proptypes.string.isRequired,
  darkmode: Proptypes.bool.isRequired
}
