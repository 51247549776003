import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import TeamScroll from '../TeamScroll'
import UserInfosModal from '../UserInfosModal'

import { api } from '../../services'

import './styles.css'
import './darkmode.css'

function TeamsList({ team, divId, company }) {
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const [teamMembers, setTeamMembers] = React.useState([])
  const [user, setUser] = React.useState([])
  const [change, setChange] = React.useState(false)

  const memberId = useSelector((state) => state.auth.sessionToken)
  const darkmode = useSelector((state) => state.user.darkmode)

  React.useEffect(() => {
    const getMembers = async () => {
      await api
        .get(`/teamMember?team=${team.value}&company=${company}`)
        .then((res) => {
          setTeamMembers(res.data)
        })
    }

    const reloadData = async () => {
      getMembers()
      if (change) {
        setChange(false)
      }
      if (modalIsOpen) {
        if (memberId === user.ownerId)
          await api.get(`/teamMember/${memberId}`).then((res) => {
            setUser(res.data)
          })
      }
    }

    reloadData()
  }, [team, change, memberId, modalIsOpen, user.ownerId, company])

  const handleOpenModal = (data) => {
    setUser(data)
    setModalIsOpen(true)
  }

  const handleCloseModal = () => {
    setUser([])
    setModalIsOpen(false)
  }

  return (
    <div className="team-list">
      <UserInfosModal
        modalIsOpen={modalIsOpen}
        actionClose={handleCloseModal}
        user={user}
        onChange={setChange}
        changeIn={change}
      />
      <h2 className="team-list-title" id={divId}>
        {team.title}
      </h2>
      <TeamScroll
        teamMembers={teamMembers}
        handleOpenModal={handleOpenModal}
        team={team}
        darkmode={darkmode}
      />
    </div>
  )
}

TeamsList.defaultProps = {
  team: {},
  divId: ''
}

TeamsList.propTypes = {
  team: PropTypes.oneOfType([PropTypes.object]),
  divId: PropTypes.string,
  company: PropTypes.string.isRequired
}

export default TeamsList
