/**
 * @param {Object} data - user data
 * @param {string} prevPath - where the user will be redirected
 * Login function called by Login page
 */
export function logInRequest(data, prevPath) {
  return {
    type: '@auth/LOG_IN_REQUEST',
    payload: { data, prevPath }
  }
}
/**
 * @param {string} token
 * @param {string} user
 * Login sucess function called by login saga
 */
export function logInSucess(
  token,
  user,
  id,
  working,
  hourEntry,
  notifications
) {
  return {
    type: '@auth/LOG_IN_SUCESS',
    payload: { token, user, id, working, hourEntry, notifications }
  }
}

export function logInFailed() {
  return {
    type: '@auth/LOG_IN_FAILED'
  }
}

export function logOut() {
  return {
    type: '@auth/LOG_OUT'
  }
}

export function rememberLogin() {
  return {
    type: '@auth_REMEMBER_LOGIN'
  }
}

export function rememberLoginOff() {
  return {
    type: '@auth_REMEMBER_LOGIN_OFF'
  }
}

export function updateHourEntry(hourEntry) {
  return {
    type: '@auth/UPDATE_HOUR_ENTRY',
    payload: { hourEntry }
  }
}
