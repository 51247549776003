import styled from 'styled-components'

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 33%);
  gap: 1em;

  @media (max-width: 876px) {
    grid-template-columns: auto;
    justify-content: center;
  }
`
