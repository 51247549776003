import { api } from '../../../services'
import { checkStatusCode } from '../../../utils'

const getProfile = async ({ memberId, dispatch, getData }) => {
  await api
    .get(`/teamMember/${memberId}`)
    .then((res) => {
      if (getData) {
        getData(res.data)
      }
    })
    .catch((reject) => checkStatusCode(reject, dispatch))
}

export { getProfile }
