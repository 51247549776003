import { api } from '../../../services'
import { checkStatusCode } from '../../../utils'

const getDepoiments = async ({ userId, change, dispatch, setDepoiments }) => {
  if (change || !change) {
    await api
      .get(`/depoiments/${userId}`)
      .then(({ data }) => {
        setDepoiments(data)
      })
      .catch((reject) => checkStatusCode(reject, dispatch))
  }
}

export { getDepoiments }
