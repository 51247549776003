/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { MdNavigateBefore, MdCheck, MdChevronRight } from 'react-icons/md'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import {
  Header,
  NavProfile,
  SubNav,
  InputFiles,
  ViewDOC,
  ModalCancelRegister,
  StyledRadio
} from '../../../../components'

import ModalDoc from './Modal'
import { api, history } from '../../../../services'
import { uploadFile, checkStatusCode } from '../../../../utils'
import { logOut } from '../../../../store/modules/auth/actions'
import { updateUser } from '../../../../store/modules/user/actions'

import AddDocumentIcon from '../../../../assets/addDocumentIcon.svg'
import DarkAddDocIcon from '../../../../assets/icons/darkAdd.svg'

import './styles.css'
import './darkmode.css'

function Docs() {
  const darkmode = useSelector((state) => state.user.darkmode)
  const user2 = JSON.parse(sessionStorage.getItem('user'))
  const profile = useSelector((select) => select.user.profile)

  const [docType, setDocType] = useState('')
  const AddDocIcon = darkmode ? DarkAddDocIcon : AddDocumentIcon

  const [modalCancelIsOpen, setModalCancelIsOpen] = useState(false)

  const [rgDocFront, setRgDocFront] = useState(null)
  const [rgDocBack, setRgDocBack] = useState(null)
  const [schollarHystoric, setSchollarHystoric] = useState(null)
  const [comprovant, setComprovant] = useState(null)
  const [cnpjFile, setCnpjFile] = useState(null)

  const [rgFrontView, setRgFrontView] = useState(null)
  const [rgBackView, setRgBackView] = useState(null)
  const [schollarView, setSchollarView] = useState(null)
  const [comprovantView, setComprovantView] = useState(null)
  const [cnpjView, setCpnjView] = useState(null)

  const [rgFrontIsPdf, setRgFrontIsPdf] = useState(false)
  const [rgFrontViewPDF, setRgFrontViewPDF] = useState(
    process.env.REACT_APP_DEFAULT_FILE
  )
  const [rgBackIsPdf, setRgBackIsPdf] = useState(false)
  const [rgBackViewPDF, setRgBackViewPDF] = useState(
    process.env.REACT_APP_DEFAULT_FILE
  )
  const [schollarIsPdf, setSchollarIsPdf] = useState(false)
  const [SchollarViewPDF, setSchollarViewPDF] = useState(
    process.env.REACT_APP_DEFAULT_FILE
  )
  const [comprovantIsPDF, setComprovantIsPDF] = useState(false)
  const [comprovantViewPDF, setComprovantViewPDF] = useState(
    process.env.REACT_APP_DEFAULT_FILE
  )
  const [cpnjIsPDF, setCnpjIsPDF] = useState(false)
  const [cnpjViewPDF, setCnpjViewPDF] = useState(
    process.env.REACT_APP_DEFAULT_FILE
  )

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalEmptyDocs, setModalEmptyDocs] = useState(false)

  const [progress1, setProgres1] = useState(0)
  const [progress2, setProgres2] = useState(0)
  const [progress3, setProgres3] = useState(0)
  const [progress4, setProgres4] = useState(0)
  const [progress5, setProgres5] = useState(0)

  const { contractingModel } = profile

  const dispatch = useDispatch()

  const docTypes = {
    rg: {
      text: 'Anexar RG(frente)',
      textBack: 'Anexar RG(verso)',
      label: 'RG (frente)',
      labelBack: 'RG (verso)'
    },
    cnh: {
      text: 'Anexar CNH(frente)',
      textBack: 'Anexar CNH(verso)',
      label: 'CNH (frente)',
      labelBack: 'CNH (verso)'
    },
    rne: {
      text: 'Anexar RNE (frente)',
      textBack: 'Anexar RNE (verso)',
      label: 'RNE (frente)',
      labelBack: 'RNE (verso)'
    }
  }

  const getFile = async (file, setHook, setHookPDF, setDocIsPDF, setBack) => {
    if (file !== '') {
      const fileArr = file.split('.')
      const ext = [...fileArr].pop()
      await api
        .get(`/file/${file}`)
        .then((response) => {
          if (response.data !== '') {
            if (setBack) {
              setBack(true)
            }
            if (ext !== 'pdf') {
              setHook(response.data)
            } else {
              setHookPDF(response.data)
              setDocIsPDF(true)
            }
          }
        })
        .catch((reject) => checkStatusCode(reject, dispatch))
    }
  }

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem('user'))
    const loadFiles = async () => {
      const rg_url = user.documentRg
        ? await getFile(
            user.documentRg,
            setRgFrontView,
            setRgFrontViewPDF,
            setRgFrontIsPdf
          )
        : ''
      const rgBack_url = user.documentRgBack
        ? await getFile(
            user.documentRgBack,
            setRgBackView,
            setRgBackViewPDF,
            setRgBackIsPdf
          )
        : ''
      const schollar_url = user.documentSchollar
        ? await getFile(
            user.documentSchollar,
            setSchollarView,
            setSchollarViewPDF,
            setSchollarIsPdf
          )
        : ''
      const college_url = user.documentCollege
        ? await getFile(
            user.documentCollege,
            setComprovantView,
            setComprovantViewPDF,
            setComprovantIsPDF
          )
        : ''
      const cnpj_url = user.documentCnpj
        ? await getFile(
            user.documentCnpj,
            setCpnjView,
            setCnpjViewPDF,
            setCnpjIsPDF
          )
        : ''

      if (
        rg_url === '' &&
        rgBack_url === '' &&
        schollar_url === '' &&
        college_url === '' &&
        cnpj_url === ''
      ) {
        setModalIsOpen(true)
      }
    }
    loadFiles()
  }, [])

  const checkInputs = (item1, item2, item3, itemIsPdf) => {
    let erro = false
    if (!itemIsPdf) {
      if (item1 === null && (item2 === null || item2 === '')) {
        erro = true
      }
    } else if (item3 === process.env.REACT_APP_DEFAULT_FILE) {
      erro = true
    }
    return erro
  }

  const checkDocuments = () => {
    let erroInputs = false
    erroInputs = checkInputs(
      rgDocFront,
      rgFrontView,
      rgFrontViewPDF,
      rgFrontIsPdf
    )

    erroInputs = checkInputs(rgDocBack, rgBackView, rgBackViewPDF, rgBackIsPdf)

    if (contractingModel === 'Estágio' && !user2.graduate) {
      const erroSchollar = checkInputs(
        schollarHystoric,
        schollarView,
        SchollarViewPDF,
        schollarIsPdf
      )

      const erroComprovant = checkInputs(
        comprovant,
        comprovantView,
        comprovantViewPDF,
        comprovantIsPDF
      )
      if (erroComprovant || erroSchollar) {
        erroInputs = true
      }
    }
    if (contractingModel === 'PJ') {
      const erroCnpj = checkInputs(cnpjFile, cnpjView, cnpjViewPDF, cpnjIsPDF)
      if (erroCnpj) {
        erroInputs = true
      }
    }
    return erroInputs
  }

  const generateUser = () => {
    let user = JSON.parse(sessionStorage.getItem('user'))
    user.documentRg = rgDocFront || user.documentRg
    user.documentRgBack = rgDocBack || user.documentRgBack
    user.documentSchollar = schollarHystoric || user.documentSchollar
    user.documentCollege = comprovant || user.documentCollege
    user.documentCnpj = cnpjFile || user.documentCnpj

    sessionStorage.setItem('user', JSON.stringify(user))
  }

  const getProfile = async (file) => {
    const response = await api
      .get(`/file/${file}`)
      .catch((reject) => checkStatusCode(reject, dispatch))
    return response.data
  }

  const envFile = async (
    file,
    type,
    setHook,
    setHookView,
    setHookFile,
    setHookViewPDF,
    setHookIsPdf
  ) => {
    const config = {
      onUploadProgress(progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        setHook(percentCompleted)
      }
    }
    if (file) {
      const typeFile =
        file.type !== 'application/pdf' ? `doc_${type}` : `doc_${type}.pdf`
      const key = `memorian_${Date.now()}_${file.name}`
      const res = await uploadFile(file, key)
      const options = {
        typeFile,
        path: res.Location,
        fileName: res.Key
      }
      await api
        .post('/file', options, config)
        .catch((reject) => checkStatusCode(reject, dispatch))
      const url = await getProfile(key)
      if (file.type !== 'application/pdf') {
        setHookView(url)
        setHookIsPdf(false)
      } else {
        setHookViewPDF(url)
        setHookIsPdf(true)
      }
      setHookFile(key)
      setHook(0)
    }
  }

  const redirectBack = () => {
    generateUser()
    if (contractingModel === 'Estágio') {
      history.push('/profile/academic')
    }
    if (contractingModel === 'PJ') {
      history.push('/profile/pj')
    }
    if (contractingModel === 'CLT') {
      history.push('/profile/work')
    }
  }

  const handleCancel = () => {
    setModalCancelIsOpen(false)
    sessionStorage.removeItem('user')
    history.push('/')
  }

  const preSubmit = async (e) => {
    e.preventDefault()
    const erro = checkDocuments()
    if (erro) {
      setModalEmptyDocs(true)
    } else {
      await handleSubmit()
    }
  }

  const handleSubmit = async () => {
    await generateUser()
    if (profile.finalized) {
      const userStorage = JSON.parse(sessionStorage.getItem('user'))
      dispatch(updateUser(userStorage))
    } else {
      history.push('/profile/2')
    }
  }

  return (
    <>
      <ModalCancelRegister
        open={modalCancelIsOpen}
        actionClose={() => setModalCancelIsOpen(false)}
        action={handleCancel}
        typeCancel={profile.finalized ? 'as edições' : 'o cadastro'}
      />
      <ModalDoc
        modalIsOpen={modalEmptyDocs}
        actionClose={() => setModalEmptyDocs(false)}
        actionSubmit={handleSubmit}
      />
      <div className={`${darkmode && 'darkmode'} global-container`}>
        <Modal
          isOpen={modalIsOpen}
          className={`${darkmode && 'darkmode'} modal`}
          overlayClassName="modal-overlay"
        >
          <span className="modal-title">Documento de identificação</span>
          <p className="modal-text">
            Qual documento de identificação você prefere anexar?
          </p>

          <div className="radios-rg">
            <div className="radio-1-rg">
              <StyledRadio
                value={docType}
                setValue={setDocType}
                markValue="rg"
              />
              <label className="radio-label">RG</label>
            </div>
            <div className="radio-1-rg">
              <StyledRadio
                value={docType}
                setValue={setDocType}
                markValue="cnh"
              />
              <label className="radio-label">CNH</label>
            </div>
            <div className="radio-1-rg">
              <StyledRadio
                value={docType}
                setValue={setDocType}
                markValue="rne"
              />
              <label className="radio-label">RNE (Estrangeiro)</label>
            </div>
          </div>

          <div className="button-modal2">
            <button className="button-secondary" onClick={() => redirectBack()}>
              Voltar
            </button>
            <button
              className="button-primary"
              onClick={() => setModalIsOpen(false)}
            >
              Avançar
            </button>
          </div>
        </Modal>
        <Header />
        <div className="global-content global-content-grey">
          {profile.finalized ? (
            <span className="title">MEU PERFIL</span>
          ) : (
            <div>
              {profile.intern ? (
                <span className="title">CADASTRO - ESTAGIÁRIO</span>
              ) : (
                <span className="title">CADASTRO</span>
              )}
            </div>
          )}
          <hr className="title-bar" />
          <NavProfile active={1} generateUser={generateUser} />

          <SubNav
            complete={2}
            select={3}
            generateUser={generateUser}
            contractingModel={contractingModel}
          />
          <form>
            <div className="title-top">
              <span className="title-min">Documentos</span>
              <hr className="title-bar" />
            </div>
            <section className="grid-area-docs">
              <div className="file-input">
                {!rgFrontIsPdf ? (
                  <InputFiles
                    span={
                      docType !== ''
                        ? docTypes[docType].text
                        : 'Anexar documento de identificação'
                    }
                    icon={AddDocIcon}
                    onChange={(e) =>
                      envFile(
                        e.target.files[0],
                        'RG',
                        setProgres1,
                        setRgFrontView,
                        setRgDocFront,
                        setRgFrontViewPDF,
                        setRgFrontIsPdf
                      )
                    }
                    label={docType !== '' ? docTypes[docType].label : ''}
                    avatar_url={rgFrontView}
                    progress={progress1}
                  />
                ) : (
                  <ViewDOC
                    link={rgFrontViewPDF}
                    label={docType !== '' ? docTypes[docType].label : ''}
                    onChange={(e) =>
                      envFile(
                        e.target.files[0],
                        'RG',
                        setProgres1,
                        setRgFrontView,
                        setRgDocFront,
                        setRgFrontViewPDF,
                        setRgFrontIsPdf
                      )
                    }
                    progress={progress1}
                  />
                )}
              </div>
              {(rgBackView !== null ||
                rgBackViewPDF !== process.env.REACT_APP_DEFAULT_FILE ||
                docType !== '') && (
                <div className="file-input">
                  {!rgBackIsPdf ? (
                    <InputFiles
                      span={
                        docType !== ''
                          ? docTypes[docType].textBack
                          : 'Anexar documento de identificação'
                      }
                      icon={AddDocIcon}
                      onChange={(e) =>
                        envFile(
                          e.target.files[0],
                          'RG',
                          setProgres2,
                          setRgBackView,
                          setRgDocBack,
                          setRgBackViewPDF,
                          setRgBackIsPdf
                        )
                      }
                      label={docType !== '' ? docTypes[docType].labelBack : ''}
                      avatar_url={rgBackView}
                      progress={progress2}
                    />
                  ) : (
                    <ViewDOC
                      link={rgBackViewPDF}
                      onChange={(e) =>
                        envFile(
                          e.target.files[0],
                          'RG',
                          setProgres2,
                          setRgBackView,
                          setRgDocBack,
                          setRgBackViewPDF,
                          setRgBackIsPdf
                        )
                      }
                      label="RG (verso)"
                      progress={progress2}
                    />
                  )}
                </div>
              )}
              {contractingModel === 'Estágio' && (
                <div className="file-input">
                  {!schollarIsPdf ? (
                    <InputFiles
                      span="Anexar histórico escolar"
                      icon={AddDocIcon}
                      label="Histórico escolar"
                      onChange={(e) =>
                        envFile(
                          e.target.files[0],
                          'Historic',
                          setProgres3,
                          setSchollarView,
                          setSchollarHystoric,
                          setSchollarViewPDF,
                          setSchollarIsPdf
                        )
                      }
                      avatar_url={schollarView}
                      progress={progress3}
                    />
                  ) : (
                    <ViewDOC
                      link={SchollarViewPDF}
                      label="Histórico escolar"
                      onChange={(e) =>
                        envFile(
                          e.target.files[0],
                          'Historic',
                          setProgres3,
                          setSchollarView,
                          setSchollarHystoric,
                          setSchollarViewPDF,
                          setSchollarIsPdf
                        )
                      }
                      progress={progress3}
                    />
                  )}
                </div>
              )}
              {contractingModel === 'Estágio' && (
                <div className="file-input">
                  {!comprovantIsPDF ? (
                    <InputFiles
                      span="Anexar comprovante de matrícula"
                      icon={AddDocIcon}
                      label="Comprovante de matrícula"
                      onChange={(e) =>
                        envFile(
                          e.target.files[0],
                          'Comprovant',
                          setProgres4,
                          setComprovantView,
                          setComprovant,
                          setComprovantIsPDF,
                          setComprovantIsPDF
                        )
                      }
                      avatar_url={comprovantView}
                      progress={progress4}
                    />
                  ) : (
                    <ViewDOC
                      link={comprovantViewPDF}
                      label="Comprovante de matrícula"
                      onChange={(e) =>
                        envFile(
                          e.target.files[0],
                          'Comprovant',
                          setProgres4,
                          setComprovantView,
                          setComprovant,
                          setComprovantIsPDF,
                          setComprovantIsPDF
                        )
                      }
                      progress={progress4}
                    />
                  )}
                </div>
              )}
              {contractingModel === 'PJ' && (
                <div className="file-input">
                  {!cpnjIsPDF ? (
                    <InputFiles
                      span="Anexar cartão cnpj"
                      icon={AddDocIcon}
                      label="Cartão cnpj"
                      onChange={(e) =>
                        envFile(
                          e.target.files[0],
                          'CNPJ',
                          setProgres5,
                          setCpnjView,
                          setCnpjFile,
                          setCnpjViewPDF,
                          setCnpjIsPDF
                        )
                      }
                      avatar_url={cnpjView}
                      progress={progress5}
                    />
                  ) : (
                    <ViewDOC
                      link={cnpjViewPDF}
                      label="Cartão cnpj"
                      onChange={(e) =>
                        envFile(
                          e.target.files[0],
                          'CNPJ',
                          setProgres5,
                          setCpnjView,
                          setCnpjFile,
                          setCnpjViewPDF,
                          setCnpjIsPDF
                        )
                      }
                      progress={progress5}
                    />
                  )}
                </div>
              )}
            </section>

            <div className="form-bottom">
              <button
                type="button"
                className="button-return"
                onClick={() => redirectBack()}
              >
                <MdNavigateBefore size={20} />
                Voltar
              </button>

              <div className="btn-group">
                <button
                  type="button"
                  className="button-secondary btn-register-w"
                  onClick={() => setModalCancelIsOpen(true)}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="button-primary btn-register-w"
                  onClick={preSubmit}
                >
                  {profile.finalized ? (
                    <>
                      Salvar{' '}
                      <MdCheck
                        color={darkmode ? '#1a1a1a' : '#fff'}
                        size={20}
                      />
                    </>
                  ) : (
                    <>
                      Avançar{' '}
                      <MdChevronRight
                        color={darkmode ? '#1a1a1a' : '#fff'}
                        size={20}
                      />
                    </>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Docs
