/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'
import { PDFReader } from 'react-read-pdf'
import PropTypes from 'prop-types'

import ButtonsPDF from '../ButtonsPDF'

import './style.css'

function PDFComponent({ link }) {
  const readerRef = React.useRef()

  let [page, setPage] = useState(1)
  const [numberPages, setNumberPages] = useState(0)
  const [pdfLoad, setPdfLoad] = useState(false)

  const onLoadPfd = (totalPage) => {
    setNumberPages(totalPage)
    setPdfLoad(true)
  }

  const handlePagePrev = () => {
    if (page > 1) {
      page -= 1
      setPage(page)
    }
    readerRef.current.scrollTop = 0
  }

  const handlePage = () => {
    if (page !== numberPages) {
      page += 1
      setPage(page)
    }
    readerRef.current.scrollTop = 0
  }

  return (
    <div className="pdf-reader-item" ref={readerRef}>
      <div className="pdf-pc">
        <PDFReader url={link} page={page} onDocumentComplete={onLoadPfd} />
      </div>
      <div className="mobile-pdf">
        <PDFReader
          url={link}
          page={page}
          onDocumentComplete={onLoadPfd}
          scale={0.2}
          isShowFooter={false}
        />
      </div>
      {pdfLoad && (
        <div className="navigation-pdf-buttons">
          <ButtonsPDF
            actionPrev={handlePagePrev}
            actionNext={handlePage}
            actionZoom={() => window.open(link)}
            page={page}
            numberPages={numberPages}
          />
        </div>
      )}
      {pdfLoad && (
        <div className="buttons-transparent">
          <button className="btn-transparent" onClick={handlePagePrev}>
            Prev
          </button>
          <button className="btn-transparent" onClick={handlePage}>
            Next
          </button>
        </div>
      )}
    </div>
  )
}

PDFComponent.propTypes = {
  link: PropTypes.string.isRequired
}

export default PDFComponent
