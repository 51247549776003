/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react'
import { MdCreate, MdDelete, MdCheck } from 'react-icons/md'
import { useSelector, useDispatch } from 'react-redux'
import Modal from 'react-modal'
import { toast } from 'react-toastify'
import PropTypes, { shape } from 'prop-types'
import InputMask from 'react-input-mask'
import moment from 'moment'
import { Tooltip } from 'antd'

import useGlobal from '../../hooks/useGlobal'

import NewModal from '../NewModal'
import Select from '../StyledSelect'

import { requestWorkScheduleCorrection } from './functions'

import './styles.css'
import './darkmode.css'

import { ConfirmEditWorkReq, CancelEditWork } from '../ModalsItems'

const _ApplicationId = process.env.REACT_APP_APLICATION_ID

function BlocoHours({ hours, day, dateNow, changeClass, openContent }) {
  const [_global] = useGlobal()
  const { id, darkmode, profile } = _global

  const dispatch = useDispatch()

  const [correct, setCorrect] = useState(false)

  const [arr, setArr] = useState([])
  const [oldHours, setOldHours] = useState([])

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState('')

  const [modalMessage, setModalMessage] = useState('')
  const [modalType, setModalType] = useState('')
  const [errorModal, setErrorModal] = useState(false)

  const inputIn = useRef(null)
  const inputOut = useRef(null)

  useEffect(() => {
    setArr([...hours])
    setOldHours([...hours])
  }, [hours])

  const reasons = [
    { value: 'Esquecimento', name: 'reason' },
    {
      value: 'Falha no sistema',
      name: 'reason'
    },
    {
      value: 'Falha na conexão',
      name: 'reason'
    },
    {
      value: 'Trabalho realizado externamente',
      name: 'reason'
    },
    { value: 'Outro', name: 'reason' }
  ]

  const formatData = (dayForm, dateForm) => {
    if (dayForm < 10) {
      dayForm = `0${day}`
    }
    const newDay = `${dayForm}-${dateForm}`
    return newDay
  }

  const handleCancel = () => {
    setModalIsOpen(true)
    setModalOpen('CANCEL_EDIT')
  }

  const setStates = () => {
    setCorrect(false)
    changeClass(false)
    openContent(false)
  }

  const handleConfirmCancel = () => {
    setModalIsOpen(false)
    setArr([...hours])
    setOldHours([...hours])
    setStates()
  }

  const editHours = (key, item, e) => {
    let { value } = e.target
    setArr(arr.map((a) => (a === item ? { ...a, [key]: value } : a)))
  }

  const handlePushNewObject = () => {
    const newArr = [...arr]
    const old = [...oldHours]
    const obj = { in: '---', out: '---' }
    newArr.push(obj)
    old.push(obj)
    setArr(newArr)
    setOldHours(old)
  }

  const checkHours = (item) => {
    let error = false
    for (let i = 0; i < item.length; i += 1) {
      const varIn = item[i].in.split('h')
      const varOut = item[i].out.split('h')
      const segIn = moment.duration(varIn[0]).asSeconds()
      const segOut = moment.duration(varOut[0]).asSeconds()
      if (segOut < segIn) {
        toast.error('Horas inválidas, verifique e tente novamente')
        error = true
        break
      }
    }
    return error
  }

  const openModal = (type, message) => {
    setModalMessage(message)
    setModalType(type)
    setErrorModal(true)
  }

  const handleSubmit = async () => {
    const newDay = formatData(day, dateNow)
    const error = checkHours(arr)
    if (error) {
      return
    }
    const dataObj = {
      _ApplicationId,
      date: newDay,
      hours: arr,
      member: id,
      offset: 0
    }
    await requestWorkScheduleCorrection({
      dataObj,
      dateNow,
      id,
      profile,
      openModal,
      dispatch,
      setModalIsOpen,
      setModalOpen,
      setStates
    })
  }

  const formatChars = {
    9: '[0-9]',
    a: '[A-Za-z]',
    '*': '[A-Za-z0-9]'
  }

  const RenderModal = (modal) => {
    const modals = {
      CONFIRM_REQ: (
        <ConfirmEditWorkReq
          action={() => setModalIsOpen(false)}
          darkmode={darkmode}
        />
      ),
      CANCEL_EDIT: (
        <CancelEditWork
          action={handleConfirmCancel}
          action2={() => setModalIsOpen(false)}
          darkmode={darkmode}
        />
      )
    }
    return modals[modal]
  }

  const handleOpenCorrect = () => {
    setCorrect(true)
    openContent(true)
  }

  const handleDeleteObject = (index) => {
    const oldArr = [...arr]
    const old = [...oldHours]
    oldArr.splice(index, 1)
    old.splice(index, 1)
    setArr(oldArr)
    setOldHours(old)
  }

  const changeHours = (key, hour, e) => {
    editHours(key, hour, e)
  }

  const changeReason = ({ key, hour, e }) => {
    editHours(key, hour, e)
    if (e.target.value === 'Outro') {
      const newE = {
        target: {
          value: true
        }
      }
      key === 'reasonIn'
        ? editHours('otherIn', hour, newE)
        : editHours('otherOut', hour, newE)
    }
  }

  return (
    <>
      <NewModal
        message={modalMessage}
        isOpen={errorModal}
        title="Correção de ponto"
        actionClose={() => setErrorModal(false)}
        type={modalType}
      />
      <Modal
        isOpen={modalIsOpen}
        className={`${darkmode && 'darkmode'} modal modal-top`}
        overlayClassName="modal-overlay"
        onRequestClose={() => setModalIsOpen(false)}
      >
        {RenderModal(modalOpen)}
      </Modal>

      <div
        className={`${!correct && 'correct-and-tables'} ${correct && 'open'}`}
      >
        <ul className={correct ? 'ul-hours-correct' : ''}>
          <div
            className={
              correct ? 'hours-titles-correct table-correct' : 'hours-titles'
            }
          >
            <span>ENTRADA</span>
            {correct && <span>MOTIVO DA CORREÇÃO</span>}
            <span>SAÍDA</span>
            {correct && <span>MOTIVO DA CORREÇÃO</span>}
          </div>
          {arr.map((hour, index) => (
            <>
              {correct ? (
                <li
                  className={`${darkmode && 'darkmode'} ${
                    index % 2 !== 0
                      ? `${correct ? 'table-correct' : 'table'} table-white`
                      : `${correct ? 'table-correct' : 'table'} table-grey`
                  }`}
                >
                  <InputMask
                    className={`${darkmode && 'darkmode'} ${
                      hour.changedIn
                        ? 'input-correct input-changed'
                        : 'input-correct'
                    }`}
                    formatChars={formatChars}
                    mask="99:99:99h"
                    value={
                      hour.in.length < 9 &&
                      hour.in !== '' &&
                      hour.in !== '---' &&
                      hour.in !== '__:__:__h'
                        ? `0${hour.in}`
                        : hour.in
                    }
                    onChange={(e) => changeHours('in', hour, e)}
                  />
                  {oldHours[index].in !== hour.in ? (
                    <>
                      {hour.otherIn ? (
                        <InputMask
                          onChange={(e) =>
                            changeReason({ key: 'reasonIn', hour, e })
                          }
                          placeholder="Digite"
                          className="table-44"
                        />
                      ) : (
                        <Select
                          data={reasons}
                          onChange={(e) =>
                            changeReason({ key: 'reasonIn', hour, e })
                          }
                          className="select-correct table-44"
                        />
                      )}
                    </>
                  ) : (
                    <div />
                  )}

                  <InputMask
                    className={`${darkmode && 'darkmode'} ${
                      hour.changedIn
                        ? 'input-correct input-changed'
                        : 'input-correct'
                    }`}
                    formatChars={formatChars}
                    mask="99:99:99h"
                    value={
                      hour.out.length < 9 &&
                      hour.out !== '' &&
                      hour.out !== '---' &&
                      hour.out !== '__:__:__h'
                        ? `0${hour.out}`
                        : hour.out
                    }
                    onChange={(e) => changeHours('out', hour, e)}
                  />
                  {oldHours[index].out !== hour.out ? (
                    <>
                      {hour.otherOut ? (
                        <InputMask
                          onChange={(e) =>
                            changeReason({ key: 'reasonOut', hour, e })
                          }
                          className="table-44"
                          placeholder="Digite"
                        />
                      ) : (
                        <Select
                          data={reasons}
                          onChange={(e) =>
                            changeReason({ key: 'reasonOut', hour, e })
                          }
                          className="select-correct table-44"
                        />
                      )}
                    </>
                  ) : (
                    <div />
                  )}
                  <Tooltip placement="bottom" title="Apagar linha">
                    <button
                      type="button"
                      className="btn-trash"
                      onClick={() => handleDeleteObject(index)}
                    >
                      <MdDelete
                        size={24}
                        color={darkmode ? '#FF796B' : '#e74c3c'}
                      />
                    </button>
                  </Tooltip>
                </li>
              ) : (
                <li
                  className={`${darkmode && 'darkmode'} ${
                    index % 2 !== 0 ? 'table table-white' : 'table table-grey'
                  }`}
                >
                  <div className="item-text-hour">
                    <span className={hour.changedIn ? 'table-changed' : ''}>
                      {hour.in}
                    </span>
                  </div>
                  <div className="item-text-hour">
                    <span className={hour.changedOut ? 'table-changed' : ''}>
                      {hour.out}
                    </span>
                  </div>
                </li>
              )}
            </>
          ))}
          {correct && (
            <li
              className={`${darkmode && 'darkmode'} ${
                (arr.length + 2) % 2 !== 0
                  ? 'table-correct table-white table-s'
                  : 'table-correct table-grey table-s'
              }`}
            >
              <InputMask
                type="text"
                className="input-correct inputs-margin"
                placeholder="Adicionar novo item"
                mask="99:99:99h"
                onClick={() => handlePushNewObject('in')}
                ref={inputIn}
              />
              <div />
              <InputMask
                type="text"
                className="input-correct inputs-margin"
                placeholder="Adicionar novo item"
                mask="99:99:99h"
                onClick={() => handlePushNewObject('out')}
                ref={inputOut}
              />
              <div />
            </li>
          )}
        </ul>

        {correct ? (
          <div className="btn-group-correct">
            <button className="button-secondary btns" onClick={handleCancel}>
              Cancelar
            </button>
            <button
              className="button-primary btns btn-check"
              onClick={handleSubmit}
            >
              Finalizar <MdCheck size={21} />
            </button>
          </div>
        ) : (
          <div className="area-correction">
            <button
              className="button-primary btn-correct"
              onClick={handleOpenCorrect}
            >
              <span className="span-btn-correct">Corrigir</span>
              <MdCreate size={20} style={{ marginLeft: '4%' }} />
            </button>
          </div>
        )}
      </div>
    </>
  )
}

BlocoHours.defaultProps = {
  hours: [],
  day: 0,
  dateNow: ''
}

BlocoHours.propTypes = {
  hours: PropTypes.arrayOf(shape([])),
  day: PropTypes.number,
  dateNow: PropTypes.string,
  changeClass: PropTypes.func.isRequired,
  openContent: PropTypes.func.isRequired
}

export default BlocoHours
