/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'

import ItemTile from './ItemTitle'
// import BlocoHours from '../BlocoHours'

import BlocoHours from '../OldHoursItem'

import './styles.css'
import './darkmode.css'

export default function WorkingHours({ dateNow }) {
  const darkmode = useSelector((state) => state.user.darkmode)

  const [isOpen, setOpen] = useState('')
  const [dates, setDates] = useState([])
  const [actualData, setActualData] = useState('')
  const [other, setOther] = useState(false)
  const [openContent, setOpenContent] = useState(false)

  /**
   * Get the global state containing the time bank data
   */
  const workingProfile = useSelector((state) => state.user.profileWorking)
  const { hours, working } = workingProfile
  const data = hours

  useEffect(() => {
    setDates(data)
  }, [data])

  useEffect(() => {
    const returnData = () => {
      const date = new Date()
      const dayOf = date.getDate()
      const month = date.getMonth()
      const year = date.getFullYear()
      const fullDate = `${dayOf < 10 ? `0${dayOf}` : dayOf}-${
        month + 1 < 10 ? `0${month + 1}` : month + 1
      }-${year}`
      setActualData(fullDate)
    }
    returnData()
  }, [working])

  const setClass = (index, item) => {
    let className = ''
    const setClassNoFix = (index) => {
      let className2 = ''
      if (index % 2 !== 0 && !item.pendingFix) {
        className2 = `${darkmode && 'darkmode'} accordion-wrapper-white`
      } else {
        className2 = `${darkmode && 'darkmode'} accordion-wrapper`
      }
      return className2
    }
    item.pendingFix
      ? (className = 'item-fix')
      : (className = setClassNoFix(index))

    return className
  }
  /**
   * Open and Close List Item
   * @param {string} item - item number
   */
  const openCloseItem = (item) => {
    isOpen === item ? setOpen('0') : setOpen(item)
    setOther(false)
    setOpenContent(false)
  }

  return (
    <div className="accordion-container">
      {dates.map((item, ind) => (
        <div className={setClass(ind, item)} key={item.day}>
          {item.notDecrease ? (
            <Tooltip title="Dia abonado">
              <div
                className={`accordion-title ${
                  isOpen === item.day ? 'open' : ''
                }`}
                onClick={() => openCloseItem(item.day)}
              >
                <ItemTile
                  working={working}
                  item={item}
                  isOpen={isOpen}
                  actualData={actualData}
                />
                <div
                  className={`${
                    darkmode && 'darkmode'
                  } status status-not-decrease`}
                >
                  O
                </div>
              </div>
            </Tooltip>
          ) : (
            <div
              className={`accordion-title ${isOpen === item.day ? 'open' : ''}`}
              onClick={() => openCloseItem(item.day)}
            >
              <ItemTile
                working={working}
                item={item}
                isOpen={isOpen}
                actualData={actualData}
              />
              <div
                className={`${darkmode && 'darkmode'} ${
                  item.negative
                    ? 'status status-negative'
                    : 'status status-work'
                }`}
              >
                {item.negative ? '-' : '+'}
              </div>
            </div>
          )}
          <div
            className={`accordion-item ${
              isOpen !== item.day ? 'collapsed' : ''
            }`}
          >
            <div
              className={`${other && 'other-content'} ${
                darkmode && 'darkmode'
              } accordion-content ${openContent && 'open-content'}`}
            >
              {isOpen === item.day && !item.notDecrease ? (
                <BlocoHours
                  hours={item.hours}
                  day={item.date}
                  dateNow={dateNow}
                  changeClass={setOther}
                  openContent={setOpenContent}
                />
              ) : (
                <span className="decrease-span">Dia abonado</span>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

WorkingHours.propTypes = {
  dateNow: PropTypes.string.isRequired
}
