import produce from 'immer'
/**
 * starting the global states that can be used in all components
 */
const INITIAL_STATE = {
  token: null,
  signed: false,
  profile: null,
  profileWorking: null,
  sessionToken: null,
  loading: false,
  hourEntry: null,
  isRemember: false
}

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/LOG_IN_REQUEST': {
        draft.loading = true
        break
      }
      case '@auth/LOG_IN_FAILED': {
        draft.loading = false
        break
      }
      case '@auth/LOG_IN_SUCESS': {
        draft.token = action.payload.token
        draft.signed = true
        draft.profile = action.payload.user
        draft.profileWorking = action.payload.working
        draft.sessionToken = action.payload.id
        draft.loading = false
        draft.hourEntry = action.payload.hourEntry
        break
      }
      case '@working/CHECK_OUT': {
        draft.hourEntry = action.payload.hourEntry
        break
      }
      case '@auth/LOG_OUT': {
        draft.token = null
        draft.signed = false
        draft.profile = null
        draft.sessionToken = null
        draft.profileWorking = null
        break
      }
      case 'auth/REMEMBER_LOGIN': {
        draft.isRemember = true
        break
      }
      case 'auth/REMEMBER_LOGIN_OFF': {
        draft.isRemember = false
        break
      }
      case '@auth/UPDATE_HOUR_ENTRY': {
        draft.hourEntry = action.payload.hourEntry
        break
      }
      default:
    }
  })
}
