import React from 'react'
import { useSelector } from 'react-redux'
import { MdCheckCircle } from 'react-icons/md'
import PropTypes from 'prop-types'

import './styles.css'
import './darkmode.css'

function ToastCustom({ text }) {
  const darkmode = useSelector((state) => state.user.darkmode)

  return (
    <div className="toast-custom">
      <MdCheckCircle size={30} color={darkmode ? '#222222' : '#fff'} />
      <span>{text}</span>
    </div>
  )
}

ToastCustom.propTypes = {
  text: PropTypes.string.isRequired
}

export default ToastCustom
