const handleClickNext = ({ page, setPage }) => {
  const nextPage = parseInt(page, 10) + 1
  setPage(nextPage)
}

const handleClickPrev = ({ page, setPage }) => {
  const prevPage = parseInt(page, 10) - 1
  setPage(prevPage)
}

const handlePage = ({ e, setPage }) => {
  const value = parseInt(e.target.value, 10)
  setPage(value)
}

export { handleClickNext, handleClickPrev, handlePage }
