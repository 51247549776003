import React from 'react'
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md'
import PropTypes from 'prop-types'

import TitleTimer from './TitleTimer'

function ItemTitle({ working, item, isOpen, actualData }) {
  const newItem = item.title.split('-')
  const itemResponsive = newItem[0].split(' ')[0]
  return (
    <div
      className={
        item.balanceAfterFix && !working
          ? 'item-title-correction'
          : 'item-title'
      }
    >
      {isOpen === item.day ? (
        <MdArrowDropUp size={30} color="#757575" />
      ) : (
        <MdArrowDropDown size={30} color="#757575" />
      )}
      <TitleTimer
        working={working}
        item={item}
        newItem={newItem}
        actualData={actualData}
        itemResponsive={itemResponsive}
      />
    </div>
  )
}

ItemTitle.defaultProps = {
  item: {}
}

ItemTitle.propTypes = {
  working: PropTypes.bool.isRequired,
  item: PropTypes.oneOfType([PropTypes.object]),
  isOpen: PropTypes.oneOfType([PropTypes.string]).isRequired,
  actualData: PropTypes.string.isRequired
}

export default ItemTitle
