import React from 'react'
import PropTypes from 'prop-types'

import { getInCache } from '../Avatar/functions/getInCache'
import { saveInCache } from '../Avatar/functions/saveInCache'
import { api } from '../../services'

import './styles.css'
import { setAvatar } from '../../utils'

function Avatar({ user, setUser }) {
  const [avatarUrl, setAvatarUrl] = React.useState('')
  React.useEffect(() => {
    const getAvatar = async () => {
      await api.get(`/teamMember/${user}`).then(async ({ data }) => {
        const _user = { name: data.name, avatar: data.avatar } || {
          name: 'User',
          avatar: ''
        }
        await getInCache(_user)
          .then((cache) => {
            setAvatarUrl(cache.url)
          })
          .catch((_) =>
            setAvatar(_user).then((res) => {
              saveInCache(res, _user)
              setAvatarUrl(res)
            })
          )
        if (setUser) {
          setUser(data)
        }
      })
    }
    getAvatar()
  }, [user, setUser])

  return (
    <div
      className="avatar-feedback "
      style={{ backgroundImage: `url(${avatarUrl})` }}
    />
  )
}

Avatar.propTypes = {
  user: PropTypes.string.isRequired,
  setUser: PropTypes.func.isRequired
}

export default Avatar
