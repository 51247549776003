import React from 'react'
import PropTypes from 'prop-types'

import './styles.css'
import './darkmode.css'

function Tags({ tagColor, tagsOpen, actionClick }) {
  const tagsLabel = [
    { value: 'administrativo', label: 'Administrativo' },
    { value: 'administrativa', label: 'Administrativo' },
    { value: 'android', label: 'Android' },
    { value: 'backend', label: 'Back-end' },
    { value: 'branding', label: 'Branding' },
    { value: 'bemestar', label: 'Bem estar' },
    { value: 'comercial', label: 'Comercial' },
    { value: 'design', label: 'Design' },
    { value: 'frontend', label: 'Front-end' },
    { value: 'fullstack', label: 'FullStack' },
    { value: 'flutter', label: 'Flutter' },
    { value: 'ios', label: 'iOS' },
    { value: 'marketing', label: 'Marketing' },
    { value: 'rh', label: 'R.H' },
    { value: 'projetos', label: 'Projetos' },
    { value: 'suporte', label: 'Suporte' },
    { value: 'ti', label: 'Suporte' },
    { value: 'qualidade', label: 'Qualidade' }
  ]
  return (
    <div className="tag-areas">
      {tagColor.map((tag) => {
        return tagsOpen ? (
          <div
            className={`${tag} tag-open`}
            onClick={actionClick}
            role="button"
            tabIndex={0}
            key={tag}
          >
            {tagsLabel.map((item) => {
              return item.value === tag && <p>{item.label}</p>
            })}
          </div>
        ) : (
          <hr className={tag} onClick={actionClick} key={tag} />
        )
      })}
    </div>
  )
}

Tags.defaultProps = {
  tagColor: []
}

Tags.propTypes = {
  tagColor: PropTypes.oneOfType([PropTypes.array]),
  tagsOpen: PropTypes.bool.isRequired,
  actionClick: PropTypes.func.isRequired
}

export default Tags
