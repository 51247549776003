export const ajuste = (nr, casas) => {
  const og = 10 ** casas
  const number = Math.floor(nr * og) / og
  return number < 10 && number >= 1 ? `0${number}` : number
}

export const ajuste2 = (nr, casas) => {
  const og = 10 ** casas
  const number = Math.floor(nr * og) / og
  return number
}
