import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { MdBookmark } from 'react-icons/md'
import PropTypes, { shape } from 'prop-types'
import Modal from 'react-modal'
import './style.css'
import './darkmode.css'

import { CourseModal } from '../ModalsItems'

import { formatDate } from '../../utils'
import Tags from '../Tags'

/**
 * Component called on the courses page
 * @param {Array} data - Array containing the courses
 */
export default function ListCourses({ data }) {
  const darkmode = useSelector((state) => state.user.darkmode)

  const [courseInModal, setCourseInModal] = useState({
    course: '',
    index: ''
  })
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [tagsOpen, setTagsOpen] = useState(false)

  const [tagsOpenModal, setTagsOpenModal] = useState(false)

  /**
   * Open course modal
   * @param {Object} course - course object
   */
  const openModal = async (course, index) => {
    setCourseInModal({
      course,
      index
    })
    setModalIsOpen(true)
    if (tagsOpenModal) {
      setTagsOpenModal(false)
    }
  }

  const handleTag = () => {
    modalIsOpen ? setTagsOpenModal(!tagsOpenModal) : setTagsOpen(!tagsOpen)
  }

  return (
    <ul className="course">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className={`${darkmode && 'darkmode'} modal modal-top`}
        overlayClassName="modal-overlay"
      >
        <CourseModal
          course={courseInModal.course}
          action={() => setModalIsOpen(false)}
          tagsOpen={tagsOpenModal}
          actionClick={handleTag}
        />
      </Modal>
      {data.map((course, index) => (
        <li key={course._id} className="course-item">
          <header
            onClick={() => openModal(course, index)}
            role="button"
            tabIndex={0}
          >
            <span>{course.title}</span>
          </header>
          <div className="infos-course">
            <div className="course-data-tag">
              <span>Adicionado em: {formatDate(course.createdAt)}</span>
              <Tags
                tagColor={course.tagColor}
                index={index}
                tagsOpen={tagsOpen}
                actionClick={handleTag}
              />
            </div>
            <button
              className="only-text-button"
              onClick={() => openModal(course, index)}
            >
              Ver detalhes
            </button>
          </div>
        </li>
      ))}
    </ul>
  )
}
/**
 * Props validation
 */
ListCourses.defaultProps = {
  data: []
}
ListCourses.propTypes = {
  data: PropTypes.arrayOf(shape([]))
}
