/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react'
import { MdCreate, MdDelete } from 'react-icons/md'
import { useSelector, useDispatch } from 'react-redux'
import Modal from 'react-modal'
import { toast } from 'react-toastify'
import PropTypes, { shape } from 'prop-types'
import InputMask from 'react-input-mask'
import moment from 'moment'
import NewModal from '../NewModal'
import { getWorkingHoursReq } from '../../store/modules/user/actions'
import { memorianAPI } from '../../services'
import useGlobal from '../../hooks/useGlobal'

import { requestWorkScheduleCorrection } from '../BlocoHours/functions'
import './styles.css'
import './darkmode.css'

import { ConfirmEditWorkReq, CancelEditWork } from '../ModalsItems'
import StyledRadio from '../StyledRadio'

const _ApplicationId = process.env.REACT_APP_APLICATION_ID

function BlocoHours({ hours, day, changeClass, openContent }) {
  const [_global] = useGlobal()
  const { id, darkmode, profile, dateNow } = _global

  const dispatch = useDispatch()

  const [correct, setCorrect] = useState(false)
  const [reason, setReason] = useState('')
  const [otherReason, setOherReason] = useState('')
  const [disabledBtn, setDisabledBtn] = useState(true)

  const [arr, setArr] = useState([])
  const [newObj, setNewObj] = useState({ in: '', out: '' })

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState('')

  const [showTextArea, setShowTextArea] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [modalType, setModalType] = useState('')
  const [errorModal, setErrorModal] = useState(false)

  const inputIn = useRef(null)
  const inputOut = useRef(null)

  useEffect(() => {
    setArr([...hours])
  }, [hours])

  const checkJustifyLength = (value) => {
    value.length > 0 ? setDisabledBtn(false) : setDisabledBtn(true)
  }

  const handleChangeReanson = (text) => {
    setReason(text)
    if (text !== 'Outro') {
      setDisabledBtn(false)
      setShowTextArea(false)
      changeClass(false)
    } else {
      setShowTextArea(true)
      setDisabledBtn(true)
      changeClass(true)
    }
  }

  const reasons = [
    { text: 'Esquecimento', name: 'reason', onChange: handleChangeReanson },
    {
      text: 'Falha no sistema',
      name: 'reason',
      onChange: handleChangeReanson
    },
    {
      text: 'Falha na conexão',
      name: 'reason',
      onChange: handleChangeReanson
    },
    {
      text: 'Trabalho realizado externamente',
      name: 'reason',
      onChange: handleChangeReanson
    },
    { text: 'Outro', name: 'reason', onChange: handleChangeReanson }
  ]

  const checkJustifyInput = (value) => {
    setOherReason(value)
    checkJustifyLength(value)
  }

  const handleCancel = () => {
    setModalIsOpen(true)
    setModalOpen('CANCEL_EDIT')
  }

  const setStates = () => {
    setReason('')
    setOherReason('')
    setCorrect(false)
    setDisabledBtn(true)
    setShowTextArea(false)
    changeClass(false)
    openContent(false)
  }

  const handleConfirmCancel = () => {
    setModalIsOpen(false)
    setArr([...hours])
    setStates()
  }

  const handleNewObject = (key) => (e) => {
    const { value } = e.target
    setNewObj((prevState) => ({ ...prevState, [key]: value }))
  }

  const editHours = (key, item) => (e) => {
    let { value } = e.target
    setArr(arr.map((a) => (a === item ? { ...a, [key]: value } : a)))
  }

  const handlePushNewObject = (e) => {
    const oldArr = [...arr]
    oldArr.push(newObj)
    setArr(oldArr)
    setNewObj({ in: '', out: '' })
    e.target.blur()
  }

  const handleDeleteObject = (index) => {
    const oldArr = [...arr]
    oldArr.splice(index, 1)
    setArr(oldArr)
  }

  const checkHours = (item) => {
    let error = false
    for (let i = 0; i < item.length; i += 1) {
      const varIn = item[i].in.split('h')
      const varOut = item[i].out.split('h')
      const segIn = moment.duration(varIn[0]).asSeconds()
      const segOut = moment.duration(varOut[0]).asSeconds()
      if (segOut < segIn) {
        toast.error('Horas inválidas, verifique e tente novamente')
        error = true
        break
      }
    }
    return error
  }

  const openModal = (type, message) => {
    setModalMessage(message)
    setModalType(type)
    setErrorModal(true)
  }

  const handleSubmit = async () => {
    const error = checkHours(arr)
    if (error) {
      return
    }
    const dataObj = {
      _ApplicationId,
      date: day,
      hours: arr,
      member: id,
      reason: `${reason === 'Outro' ? `Outro:${otherReason}` : reason}`,
      offset: 0
    }
    await requestWorkScheduleCorrection({
      dataObj,
      dateNow,
      id,
      profile,
      openModal,
      dispatch,
      setModalIsOpen,
      setModalOpen,
      setStates
    })
  }

  const formatChars = {
    9: '[0-9]',
    a: '[A-Za-z]',
    '*': '[A-Za-z0-9]'
  }

  const RenderModal = (modal) => {
    const modals = {
      CONFIRM_REQ: (
        <ConfirmEditWorkReq
          action={() => setModalIsOpen(false)}
          darkmode={darkmode}
        />
      ),
      CANCEL_EDIT: (
        <CancelEditWork
          action={handleConfirmCancel}
          action2={() => setModalIsOpen(false)}
          darkmode={darkmode}
        />
      )
    }
    return modals[modal]
  }

  const handleOpenCorrect = () => {
    setCorrect(true)
    openContent(true)
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        className={`${darkmode && 'darkmode'} modal modal-top`}
        overlayClassName="modal-overlay"
        onRequestClose={() => setModalIsOpen(false)}
      >
        {RenderModal(modalOpen)}
      </Modal>

      <NewModal
        message={modalMessage}
        isOpen={errorModal}
        title="Correção de ponto"
        actionClose={() => setErrorModal(false)}
        type={modalType}
      />

      <div
        className={
          correct ? 'padding-table table-and-correct' : 'table-and-correct'
        }
      >
        <div
          className={`${darkmode && 'darkmode'} ${
            correct ? 'hours-titles-correct' : 'hours-titles'
          }`}
        >
          <p>Entrada</p>
          <p className="exit-hours">Saída</p>
        </div>
        {arr.map((hour, index) => (
          <div className="tables-inputs" key={index}>
            {correct ? (
              <div className={correct ? 'hours2' : 'hours'}>
                <InputMask
                  className={`${darkmode && 'darkmode'} ${
                    hour.changedIn
                      ? 'input-correct input-changed'
                      : 'input-correct'
                  }`}
                  formatChars={formatChars}
                  mask="99:99:99h"
                  value={
                    hour.in.length < 9 && hour.in !== '' && hour.in !== '---'
                      ? `0${hour.in}`
                      : hour.in
                  }
                  onChange={editHours('in', hour)}
                />
                <InputMask
                  className={`${darkmode && 'darkmode'} ${
                    hour.changedIn
                      ? 'input-correct input-changed'
                      : 'input-correct'
                  }`}
                  formatChars={formatChars}
                  mask="99:99:99h"
                  value={
                    hour.out.length < 9 && hour.out !== '' && hour.out !== '---'
                      ? `0${hour.out}`
                      : hour.out
                  }
                  onChange={editHours('out', hour)}
                />

                <button
                  className="btns-add-remove"
                  onClick={() => handleDeleteObject(index)}
                >
                  <MdDelete
                    size={24}
                    color={darkmode ? '#FF796B' : '#e74c3c'}
                  />
                </button>
              </div>
            ) : (
              <ul className="hours">
                <li
                  className={`${darkmode && 'darkmode'} ${
                    index % 2 !== 0 ? 'table table-white' : 'table table-grey'
                  }`}
                >
                  <div className={hour.changedIn ? 'table-changed' : ''}>
                    {hour.in}
                  </div>
                </li>
                <li
                  className={`${darkmode && 'darkmode'} ${
                    index % 2 !== 0 ? 'table table-white' : 'table table-grey'
                  }`}
                >
                  <div className={hour.changedOut ? 'table-changed' : ''}>
                    {hour.out}
                  </div>
                </li>
              </ul>
            )}
          </div>
        ))}
        {correct && (
          <div className="hours2">
            <InputMask
              type="text"
              className="input-correct inputs-margin"
              placeholder="Adicionar novo item"
              mask="99:99:99h"
              value={newObj.in}
              onClick={handlePushNewObject}
              onChange={handleNewObject('in')}
              ref={inputIn}
            />
            <InputMask
              type="text"
              className="input-correct inputs-margin"
              placeholder="Adicionar novo item"
              mask="99:99:99h"
              value={newObj.out}
              onClick={handlePushNewObject}
              onChange={handleNewObject('out')}
              ref={inputOut}
            />
            <button
              className={`${darkmode && 'darkmode'} add-input btns-add-remove`}
              onClick={handlePushNewObject}
              disabled
            >
              +
            </button>
          </div>
        )}

        <div className="area-correction">
          {correct ? (
            <div className="area-justify">
              <p className="text-justify">MOTIVO DA CORREÇÃO</p>
              {reasons.map((item) => (
                <div className="radio-correction">
                  <div className="size-radio">
                    <StyledRadio
                      value={reason}
                      markValue={item.text}
                      setValue={() => handleChangeReanson(item.text)}
                    />
                  </div>

                  <span>{item.text}</span>
                </div>
              ))}

              {showTextArea && (
                <textarea
                  className={`${darkmode && 'darkmode'} text-area-input`}
                  placeholder="Digite aqui sua justificativa de alteração de ponto."
                  value={otherReason}
                  onChange={(e) => checkJustifyInput(e.target.value)}
                />
              )}

              <div className="btn-group-correct">
                <button
                  className="button-secondary btns"
                  onClick={handleCancel}
                >
                  Cancelar
                </button>
                <button
                  className="button-primary btns"
                  disabled={disabledBtn}
                  onClick={handleSubmit}
                >
                  Confirmar
                </button>
              </div>
            </div>
          ) : (
            <button
              className="button-primary btn-correct"
              onClick={handleOpenCorrect}
            >
              <span className="span-btn-correct">Corrigir</span>
              <MdCreate size={20} style={{ marginLeft: '4%' }} />
            </button>
          )}
        </div>
      </div>
    </>
  )
}

BlocoHours.defaultProps = {
  hours: [],
  day: 0
}

BlocoHours.propTypes = {
  hours: PropTypes.arrayOf(shape([])),
  day: PropTypes.number,
  changeClass: PropTypes.func.isRequired,
  openContent: PropTypes.func.isRequired
}

export default BlocoHours
