import React from 'react'
import PropTypes from 'prop-types'
import { MdPhoto } from 'react-icons/md'
import PdfIcon from '../../assets/icons/PdfFile'
import VideoIcon from '../../assets/icons/VideoIcon'
import DocIcon from '../../assets/icons/DocIcon'
import XlsIcon from '../../assets/icons/XlsIcon'

const icons = {
  pdf: <PdfIcon />,
  video: <VideoIcon />,
  image: <MdPhoto size={30} color="#757575" />,
  doc: <DocIcon />,
  xls: <XlsIcon />
}

function ListFiles({ type }) {
  return <div style={{ padding: '10px' }}>{icons[type]}</div>
}

ListFiles.propTypes = {
  type: PropTypes.string.isRequired
}

export default ListFiles
