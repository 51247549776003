import React from 'react'
import { MdNotifications } from 'react-icons/md'
import { ClickAwayListener } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import io from 'socket.io-client'

import { history } from '../../services'

import Item from './components/Item'

import Avatar from '../Avatar'

import UseLogo from '../../assets/icons/useLogo.svg'
import {
  getNotificationsReq,
  markAsReadReq
} from '../../store/modules/user/actions'
import sound from '../../assets/sounds/1.mp3'

import './styles.css'
import './darkmode.css'

function Notifications({ darkmode, handleOpenProfile }) {
  const ntRead = useSelector((state) => state.user.notRead)
  const notifications = useSelector((state) => state.user.notifications)

  const [open, setOpen] = React.useState(false)

  const [data, setData] = React.useState([])
  const [notRead, setNotRead] = React.useState(0)

  const [render, setRender] = React.useState(null)

  const id = useSelector((state) => state.auth.sessionToken)
  const dispatch = useDispatch()

  React.useEffect(() => {
    setData(notifications)
    setNotRead(ntRead)
  }, [ntRead, notifications])

  React.useEffect(() => {
    if (render !== null) {
      toast(
        <div
          className="toast-notificação"
          role="button"
          tabIndex={0}
          onClick={() => handleOpenNotify(render.type)}
        >
          <div className="avatar-notification">
            {render.from.name && render.from.name !== 'Usemobile' ? (
              <Avatar
                data={render.from}
                sizes={{ w: 32, h: 32 }}
                loader={{ sizes: 2, color: '#fff' }}
              />
            ) : (
              <div
                className="avatar"
                style={{ backgroundImage: `url(${UseLogo})` }}
              />
            )}
          </div>
          <p className="p-notification">{render.notification}</p>
        </div>
      )
    }
  }, [render])

  React.useEffect(() => {
    let url = window.location.href.split('/')
    url.length = 3

    const newUrl = `https://${url[2]}/`
    const newUrl2 = `http://${url[2]}/`

    const API_URL =
      newUrl !== process.env.REACT_APP_URL_PROD_HTTPS ||
      newUrl2 !== process.env.REACT_APP_URL_PROD_HTTP
        ? process.env.REACT_APP_API_STAGING
        : process.env.REACT_APP_API_URL
    const socket = io(API_URL, {
      transports: ['websocket', 'polling', 'flashsocket'],
      query: { user: id }
    })
    socket.on('notification', (nt) => {
      setRender(nt)
      dispatch(getNotificationsReq(id))
      const ntSound = new Audio(sound)
      ntSound.play()
      setTimeout(() => {
        if (Notification.permission === 'granted') {
          const notification = new Notification('Memorian', {
            body: nt.notification
          })
          notification.onclick = (e) => {
            e.preventDefault()
            window.focus()
            notification.close()
          }
        }
      }, 2000)
    })
  }, [])

  const typesActions = {
    newDepoiment: () => handleOpenProfile(),
    newFile: () => history.push('/files'),
    newCourse: () => history.push('/courses'),
    newMember: () => history.push('/centralteam'),
    newStamp: () => handleOpenProfile()
  }

  const handleOpenNotify = (type) => {
    if (type) {
      typesActions[type]()
      setOpen(false)
    }
  }

  const handleOpen = async () => {
    dispatch(markAsReadReq(notifications))
    setOpen(!open)
  }

  const handleClickAway = () => {
    setOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={`${darkmode && 'darkmode'} notifications`}>
        <button
          type="button"
          onClick={handleOpen}
          className="btn-notifications"
        >
          {notRead > 0 && (
            <span className="number-notifications">
              {notRead > 10 ? `10+` : notRead}
            </span>
          )}
          <MdNotifications size={30} color="#fff" />
        </button>
        {open && (
          <div>
            <ul className="notificationsUl">
              {data.map((item) => (
                <Item
                  item={item}
                  handleOpenNotify={() => handleOpenNotify(item.type)}
                />
              ))}
            </ul>
          </div>
        )}
      </div>
    </ClickAwayListener>
  )
}

Notifications.propTypes = {
  darkmode: PropTypes.bool.isRequired,
  handleOpenProfile: PropTypes.func.isRequired
}

export default Notifications
