import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { MdHistory, MdFolder, MdBookmark, MdGroup } from 'react-icons/md'
import PropTypes from 'prop-types'

import './style.css'
import './darkmode.css'
/**
 * Navbar Called By Home
 * @param {number} active -  index of the list component that is active
 */
export default function NavHome({ active, progress }) {
  const darkmode = useSelector((state) => state.user.darkmode)

  return (
    <nav className="nav-home-cp">
      <ul>
        <li
          className={active === 1 ? `${darkmode && 'darkmode'} nav-active` : ''}
        >
          <Link className="link-web" to="/">
            Banco de horas
          </Link>
          <Link className="icon-nav" to="/">
            <MdHistory size={30} />
          </Link>
        </li>
        <li
          className={active === 2 ? `${darkmode && 'darkmode'} nav-active` : ''}
        >
          <Link className="link-web" to="/files">
            Central de arquivos
          </Link>
          <Link className="icon-nav" to="/files">
            <MdFolder size={30} />
          </Link>
        </li>
        <li
          className={active === 3 ? `${darkmode && 'darkmode'} nav-active` : ''}
        >
          <Link className="link-web" to="/courses">
            Cursos
          </Link>
          <Link className="icon-nav" to="/courses">
            <MdBookmark size={30} />
          </Link>
        </li>
        <li
          className={active === 4 ? `${darkmode && 'darkmode'} nav-active` : ''}
        >
          <Link className="link-web" to="/centralteam">
            Nossa equipe
          </Link>
          <Link className="icon-nav" to="/centralteam">
            <MdGroup size={30} />
          </Link>
        </li>
      </ul>
      <div className="bar-page">
        <div className="bar-page-progress" style={{ width: `${progress}%` }} />
      </div>
    </nav>
  )
}

NavHome.defaultProps = {
  active: 1,
  progress: 0
}

NavHome.propTypes = {
  active: PropTypes.number,
  progress: PropTypes.number
}
