import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { MdCancel, MdCheckCircle, MdError } from 'react-icons/md'
import Modal from 'react-modal'
import './styles.css'
import './darkmode.css'

function NewModal({
  title,
  type,
  message,
  isOpen,
  actionClose,
  doubleButton,
  actionConfirm,
  buttonMessage
}) {
  const darkmode = useSelector((state) => state.user.darkmode)
  const icons = {
    success: (
      <MdCheckCircle size={35} color={darkmode ? '#58D68D' : '#2ECC71'} />
    ),
    error: <MdCancel size={35} color={darkmode ? '#FF796B' : '#E74C3C'} />,
    warning: <MdError size={35} color={darkmode ? '#FFDF61' : '#FFBF00'} />
  }
  return (
    <Modal
      isOpen={isOpen}
      className={`${darkmode && 'darkmode'} modal modal-top`}
      overlayClassName="modal-overlay"
      onRequestClose={actionClose}
    >
      <div className={`${darkmode ? 'darkmode' : ''} newModal`}>
        <span className="title-new-modal">{title}</span>

        <hr className="bar-new-modal" />
        <div className={`${darkmode ? 'darkmode' : ''} newModalContent`}>
          {icons[type]}
          {message}
        </div>
      </div>
      {!doubleButton ? (
        <button
          className="button-primary"
          onClick={actionClose}
          style={{ alignSelf: 'center' }}
        >
          Ok
        </button>
      ) : (
        <div className="button-modal-2 btn-alert">
          <button
            className="button-secondary"
            onClick={actionClose}
            style={{ alignSelf: 'center' }}
          >
            Voltar
          </button>
          <button
            className="button-primary"
            onClick={actionConfirm}
            style={{ alignSelf: 'center' }}
          >
            {buttonMessage}
          </button>
        </div>
      )}
    </Modal>
  )
}

NewModal.defaultProps = {
  doubleButton: false,
  actionConfirm: null,
  buttonMessage: ''
}

NewModal.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  actionClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  doubleButton: PropTypes.bool,
  actionConfirm: PropTypes.func,
  buttonMessage: PropTypes.string
}

export default NewModal
