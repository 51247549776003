import { toast } from 'react-toastify'
import { logOut } from '../store/modules/auth/actions'
import { api } from '../services'

export const checkStatusCode = (reject, dispatch) => {
  if (reject.response) {
    const { status } = reject.response
    if (status === 401 || status === 403) {
      toast.error('Sessão expirada, faça login novamente')
      dispatch(logOut())
    } else {
      toast.error('Ops...Encontramos um problema por aqui. Tente novamente.')
    }
  } else {
    toast.error('Ops...Encontramos um problema por aqui. Tente novamente.')
  }
}

export const checkToken = async (dispatch) => {
  await api
    .get('/checkToken')
    .catch((reject) => checkStatusCode(reject, dispatch))
}
