/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes, { shape } from 'prop-types'
import { useSelector } from 'react-redux'
import { AutoComplete } from 'antd'

import './darkmode.css'

function AutoCompleteInput({
  data,
  label,
  error,
  onChangeAutoComplete,
  valueAuto,
  Icon,
  open,
  ...rest
}) {
  const darkmode = useSelector((state) => state.user.darkmode)

  const [focused, setFocused] = React.useState(false)

  return (
    <div className="styled-input-basic">
      {Icon && (
        <div className="icon-2">
          <Icon size={25} color={open ? '#00B7EF' : '#757575'} />
        </div>
      )}
      <AutoComplete
        dropdownClassName={`${
          darkmode && 'darkmode'
        } certain-category-search-dropdown`}
        dropdownMatchSelectWidth="100%"
        style={{ width: '100%' }}
        options={data}
        filterOption={(inputValue, option) =>
          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        onChange={(value) => onChangeAutoComplete(value)}
        value={valueAuto}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      >
        <input
          {...rest}
          className={
            error
              ? 'input-text-basic input-text-error-basic'
              : 'input-text-basic'
          }
          autoComplete
          id="auto-complete-input"
        />
      </AutoComplete>

      {label && (
        <label
          htmlFor="auto-complete-input"
          className={
            focused ? `input-label-basic label-focused` : 'input-label-basic'
          }
        >
          {label}
        </label>
      )}
    </div>
  )
}
/** ******************
 * Props Validation *
 ******************* */
AutoCompleteInput.defaultProps = {
  label: false,
  error: false
}
AutoCompleteInput.propTypes = {
  label: PropTypes.string,
  error: PropTypes.bool,
  data: PropTypes.arrayOf(shape([])).isRequired,
  onChangeAutoComplete: PropTypes.func.isRequired,
  valueAuto: PropTypes.func.isRequired,
  Icon: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired
}

export default AutoCompleteInput
