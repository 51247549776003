import accessData from './AcessData'
import bankData from './BankData'
import infos from './Infos'
import personData from './PersonData'

export const AccessData = accessData
export const BankData = bankData
export const Infos = infos
export const PersonData = personData
export * from './SubPages'
