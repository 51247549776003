/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
import React, { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Popconfirm } from 'antd'
import PropTypes from 'prop-types'

import ButtonCheckin from '../ButtonCheckin'
import StyledRadio from '../StyledRadio'
import Clock from '../Clock'

import './style.css'
import './darkmode.css'

import { setAvatarUrlMain } from '../../utils'

/**
 * Header With The User Information That Is Called In The Home
 */
export default function UserInfos({ handleCheck }) {
  const darkmode = useSelector((state) => state.user.darkmode)
  const profile = useSelector((select) => select.user.profile)
  const dataWork = useSelector((state) => state.user.profileWorking)

  const [url, setUrl] = useState('')
  const [reasonCheck, setReasonCheck] = useState('')
  // Get the global state profile object defined by redux

  const listReasonsOut = [
    {
      value: reasonCheck,
      setValue: setReasonCheck,
      markValue: 'checkout',
      label: 'Check-out'
    }
  ]

  const listReasons = useMemo(() => {
    const obj = {
      value: reasonCheck,
      setValue: setReasonCheck,
      markValue: 'lunch',
      label: 'Horário de almoço'
    }

    if (!dataWork.alreadyHadLunch) {
      listReasonsOut.push(obj)
    }

    return listReasonsOut
  }, [dataWork, listReasonsOut])

  useEffect(() => {
    setAvatarUrlMain(profile, setUrl)
  }, [profile])

  const check = () => {
    handleCheck(reasonCheck)
    setReasonCheck('')
  }

  // Render
  return (
    <div className={darkmode ? `darkmode infos-container` : 'infos-container'}>
      <div className="infos-content">
        <div className="user-on-infos">
          <picture>
            <source srcSet={url} type="image/jpg" />
            <source srcSet={url} type="image/png" />
            <source srcSet={url} type="image/svg+xml" />
            <img src={url} className="profile-avatar-infos" alt="avatar" />
          </picture>
        </div>

        <section className={`${darkmode && 'darkmode'} user-data-infos`}>
          <span className="user-name-infos">{profile.name}</span>
          <span>{`${profile.acessCode} | ${profile.office}`}</span>
          {dataWork.working ? (
            <span className="status-user-connect">Usuário conectado</span>
          ) : (
            <span className="status-user-desconnect">Usuário desconectado</span>
          )}
          <div className="saldo-area">
            <span>Saldo total: </span>
            <span
              className={
                dataWork.negativeTotal ? 'negative-user' : 'positive-user'
              }
            >
              {dataWork.balanceTotal}
            </span>
          </div>
          {dataWork.totalBalanceFix && (
            <span className="totalBalanceFix">{`Após correções pendentes: ${dataWork.totalBalanceFix}`}</span>
          )}
        </section>
      </div>
      <div className="clock">
        <Clock darkmode={darkmode} />
        {!dataWork.alreadyHadLunch && dataWork.working ? (
          <Popconfirm
            placement="bottomRight"
            overlayClassName={darkmode ? 'darkmode pop-style' : 'pop-style'}
            overlayStyle={{ position: 'absolute', top: '380px' }}
            title={
              <div className="options-area">
                <span className="title-checks">
                  Selecione o tipo de registro:
                </span>
                {listReasons.map((item) => (
                  <div className="options-check">
                    <StyledRadio
                      value={item.value}
                      setValue={item.setValue}
                      markValue={item.markValue}
                    />
                    <span>{item.label}</span>
                  </div>
                ))}
              </div>
            }
            onConfirm={check}
            okText="Ok"
            icon=""
          >
            <div className="area-check-in">
              <ButtonCheckin working={dataWork.working} />
            </div>
          </Popconfirm>
        ) : (
          <div className="area-check-in">
            <ButtonCheckin working={dataWork.working} onClick={check} />
          </div>
        )}
      </div>
    </div>
  )
}

UserInfos.propTypes = {
  handleCheck: PropTypes.func.isRequired
}
