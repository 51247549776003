const handleClick = ({ index, scrollableDiv, setCurrent }) => {
  setCurrent(index)
  scrollableDiv.current.scrollTop = index * 320 + index * 5
}

const handleScroll = ({ teams, scrollableDiv, setCurrent }) => {
  const { scrollTop } = scrollableDiv.current
  teams.forEach((item, index) => {
    if (index < 10) {
      if (scrollTop >= index * 300) {
        setCurrent(index)
      }
    } else if (scrollTop >= index * 300 + index * 5) {
      setCurrent(index)
    }
  })
}

export { handleClick, handleScroll }
