import React from 'react'
import PropTypes from 'prop-types'

import './styles.css'

function ButtonsTopSelect({ actionSelect, actionClear }) {
  return (
    <div className="clear-and-select-all">
      <button onClick={actionSelect} className="select-btn">
        Selecionar todos
      </button>
      <span className="select-bar-span">/</span>
      <button onClick={actionClear} className="select-btn">
        Limpar seleção
      </button>
    </div>
  )
}

ButtonsTopSelect.propTypes = {
  actionSelect: PropTypes.func.isRequired,
  actionClear: PropTypes.func.isRequired
}

export default ButtonsTopSelect
