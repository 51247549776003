import { toast } from 'react-toastify'
import { api } from '../../../services'
import { encryptKey } from '../../../utils'
import { loginHelper } from '../helpers/loginHelper'

const loginFN = async ({
  acessCode,
  password,
  dispatch,
  messages,
  setInvalidACLogin,
  setLoginError,
  setLoading,
  setAcessCodeToForgot,
  logInFailed,
  prevPath,
  acessCodeToForgot
}) => {
  await api
    .post('/login', {
      acessCode: encryptKey(acessCode),
      password: encryptKey(password)
    })
    .then(
      ({ data }) => {
        const userData = {
          password,
          confirmPassword: password
        }
        sessionStorage.setItem('user-data', JSON.stringify(userData))
        loginHelper(data, dispatch, prevPath)
      },
      (reject) => {
        const { msg } = reject.response
          ? reject.response.data.errors
          : { msg: 'DEFAULT' }
        const message = messages[msg]
        setInvalidACLogin(true)
        setLoginError(true)
        toast.error(message)
        if (acessCodeToForgot) {
          setAcessCodeToForgot(false)
        }
        dispatch(logInFailed())
      }
    )
    .finally(() => setLoading(false))
}

export { loginFN }
