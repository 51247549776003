const date = new Date()
const now = new Date()
const numberOfMonths = 6
let dateArr = []
const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
]

const PushDate = (month, year) => {
  dateArr.push({
    value: `${month}-${year}`,
    label: `${months[date.getMonth()]}/${year}`
  })
  date.setMonth(date.getMonth() - 1)
}

const atualMonth = now.getMonth() + 1
for (let i = 0; i < atualMonth; i += 1) {
  if (now.getFullYear() > date.getFullYear()) break
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  if (month < 10) month = `0${month}`
  PushDate(month, year)
}
for (let i = 0; i < numberOfMonths; i += 1) {
  let month = date.getMonth() + 1
  let year = date.getFullYear()
  if (month < 10) month = `0${month}`
  PushDate(month, year)
}

export const dates = dateArr
