import React from 'react'
import PropTypes from 'prop-types'

import { formatName } from '../../../utils'
import { getInCache } from '../../Avatar/functions/getInCache'
import { saveInCache } from '../../Avatar/functions/saveInCache'
import { api } from '../../../services'
// import { Container } from './styles';

function User({ user, actionClick }) {
  const [url, setUrl] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const getAvatar = async (name, avatar) => {
    const nameUser = name.split(' ')
    const data = {
      name,
      avatar
    }
    setLoading(true)
    getInCache(data)
      .then((cache) => setUrl(cache.url))
      .catch((_) => {
        if (!avatar || avatar === '') {
          setUrl(
            `https://avatar.oxro.io/avatar.svg?name=${nameUser[0]}+${nameUser[1]}&background=f6f6f6&length=2&color=00b7ef`
          )
        } else {
          api
            .get(`/file/${avatar}`)
            .then((res) => {
              saveInCache(res.data, data)
              setUrl(res.data)
            })
            .catch((err) => console.error(err))
        }
      })
    setLoading(false)
  }

  React.useEffect(() => {
    getAvatar(user.name, user.avatar)
  }, [user.avatar, user.name])

  return (
    <li
      className="item-async-search with-hover"
      onClick={() => actionClick(user)}
    >
      <div
        style={{
          backgroundImage: `url(${url})`,
          width: '32px',
          height: '32px',
          backgroundSize: 'cover',
          borderRadius: '50%'
        }}
      />
      <span>{user.name ? formatName(user.name) : ''}</span>
    </li>
  )
}

User.defaultProps = {
  user: {}
}

User.propTypes = {
  user: PropTypes.oneOfType(PropTypes.object),
  actionClick: PropTypes.func.isRequired
}

export default User
