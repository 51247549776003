/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes, { shape } from 'prop-types'

import './styles.css'
import './darkmode.css'

function StyledSelect({ data, label, holder, error, ...rest }) {
  return (
    <div className="styled-select">
      <select
        id="styled-select"
        className={error ? 'styled-select-field-error' : 'styled-select-field'}
        {...rest}
      >
        <option value="" disabled selected hidden>
          {holder}
        </option>
        {data.map((item) => (
          <option value={item.value} key={item.value}>
            {item.label ? item.label : item.value}
          </option>
        ))}
      </select>
      {label && (
        <label htmlFor="styled-select" className="styled-select-label">
          {label}
        </label>
      )}
    </div>
  )
}

StyledSelect.defaultProps = {
  data: [],
  label: '',
  holder: '',
  error: false
}

StyledSelect.propTypes = {
  data: PropTypes.arrayOf(shape([])),
  label: PropTypes.string,
  holder: PropTypes.string,
  error: PropTypes.bool
}

export default StyledSelect
