/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { MdEdit, MdAddAPhoto, MdDelete } from 'react-icons/md'
import { ClickAwayListener } from '@material-ui/core'

import IconUpload from '../../assets/icons/upload.svg'
import './styles.css'
import './darkmode.css'

function InputFiles({
  file,
  avatar_url,
  icon,
  span,
  alt,
  label,
  progress,
  type_file,
  actionRemove,
  actionOpenModal,
  buttonGalery,
  adjustPhoto,
  ...rest
}) {
  const inputRef = useRef(null)
  const [dropDown, setDropDown] = useState(false)
  const [buttonRemove, setButtonRemove] = useState(true)
  const profile = useSelector((select) => select.user.profile)
  const darkmode = useSelector((state) => state.user.darkmode)

  const preview = useMemo(() => {
    return file ? URL.createObjectURL(file) : avatar_url
  }, [file, avatar_url])

  const handleOpenInput = () => {
    if (type_file !== 'avatar') {
      inputRef.current.click()
    } else {
      setDropDown(!dropDown)
    }
  }

  const clickBtn = (item) => {
    if (item === '1') {
      inputRef.current.click()
      setDropDown(false)
    }
    if (item === '2') {
      actionOpenModal()
      setDropDown(false)
    }
    if (item === '3') {
      adjustPhoto()
      setDropDown(false)
    }
    if (item === '4') {
      actionRemove()
      setDropDown(false)
    }
  }

  const handleDropDown = () => {
    setDropDown(!dropDown)
    setButtonRemove(false)
  }

  return (
    <>
      <div className="input-file-div">
        <label
          className={
            file || preview ? 'input-file has-thumbnail' : 'input-file'
          }
          style={{ backgroundImage: `url(${preview})` }}
        >
          <div className="icon-input-file">
            <img src={icon} alt={alt} />
          </div>
          {!profile.finalized && !preview && (
            <input type="file" className="item-input-file" {...rest} />
          )}
          {profile.finalized && !preview && (
            <button
              className="item-input-file"
              onClick={handleDropDown}
              type="button"
            />
          )}
          <span className="input-file-span">{span}</span>
        </label>
        {progress > 0 && (
          <div className="upload-bar">
            <div className="uploadProgess" style={{ width: `${progress}%` }} />
          </div>
        )}
        {(file || avatar_url) && (
          <div className="item-edit">
            <div className="cirle-edit" onClick={handleOpenInput}>
              <MdEdit size={15} color="#757575" />
            </div>
          </div>
        )}
        {dropDown && (
          <ClickAwayListener onClickAway={() => setDropDown(false)}>
            <div className="dropdown-avatar">
              <div role="button" tabIndex={0} onClick={() => clickBtn('1')}>
                <img src={IconUpload} alt="upload-icon" />
                <span>Carregar uma nova foto</span>
              </div>
              {buttonGalery && (
                <div role="button" tabIndex={0} onClick={() => clickBtn('2')}>
                  <MdAddAPhoto
                    size={24}
                    color={darkmode ? '#bfbfbf' : '#000'}
                  />
                  <span>Selecionar uma foto existente</span>
                </div>
              )}
              {(file || avatar_url) && adjustPhoto && (
                <div role="button" tabIndex={0} onClick={() => clickBtn('3')}>
                  <MdEdit size={24} color={darkmode ? '#bfbfbf' : '#000'} />
                  <span>Ajustar Foto</span>
                </div>
              )}
              {buttonRemove && actionRemove && (
                <div role="button" tabIndex={0} onClick={() => clickBtn('4')}>
                  <MdDelete size={24} color={darkmode ? '#bfbfbf' : '#000'} />
                  <span>Remover foto</span>
                </div>
              )}
            </div>
          </ClickAwayListener>
        )}
        {label && (file || avatar_url) && (
          <label className="input-file-label">{label}</label>
        )}
      </div>
      <input type="file" className="item-input-file" ref={inputRef} {...rest} />
    </>
  )
}

InputFiles.defaultProps = {
  progress: 0
}

InputFiles.propTypes = {
  file: PropTypes.string.isRequired,
  avatar_url: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  span: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  progress: PropTypes.number,
  type_file: PropTypes.string.isRequired,
  actionRemove: PropTypes.func.isRequired,
  actionOpenModal: PropTypes.func.isRequired,
  buttonGalery: PropTypes.bool.isRequired,
  adjustPhoto: PropTypes.func.isRequired
}

export default InputFiles
