import React from 'react'
import Modal from 'react-modal'
import { MdError, MdChevronLeft } from 'react-icons/md'
import PropTypes, { string, bool } from 'prop-types'

import './styles.css'
import './darkmode.css'

function MoreInfos({ item, index, action, showMore, darkmode }) {
  return (
    <Modal
      isOpen={showMore}
      className={`${darkmode && 'darkmode'} modal modal-top`}
      overlayClassName="modal-overlay"
    >
      <div className="more-infos-modal">
        <div className="title-more title-flex">
          <MdError size={30} color={darkmode ? '#FF796B' : '#e74c3c'} />
          <div className="title-more-text title-flex">
            <p className="text-red">{`Ajuste ${index + 1}:`}</p>
            <p>{item.date}:</p>
            <p>Solicitação invalidada.</p>
          </div>
        </div>

        <p>
          <strong>RH.:</strong>
          {item.reason}. Entre em contato com:
          <p className="email-memorian">memorian@usemobile.com</p>
        </p>

        <button
          className="btn-return-more"
          style={{
            alignSelf: 'flex-start',
            marginBottom: '2%',
            marginTop: '5%'
          }}
          onClick={action}
        >
          <MdChevronLeft size={20} />
          Voltar
        </button>
      </div>
    </Modal>
  )
}

MoreInfos.propTypes = {
  item: PropTypes.objectOf(
    PropTypes.shape({
      date: string,
      reason: string,
      valid: bool
    })
  ).isRequired,
  index: PropTypes.number.isRequired,
  action: PropTypes.func.isRequired,
  showMore: PropTypes.bool.isRequired,
  darkmode: PropTypes.bool.isRequired
}

export default MoreInfos
