import produce from 'immer'

const INITIAL_STATE = {
  profile: null,
  profileWorking: null,
  inVocation: false,
  darkmode: false,
  notifications: [],
  notRead: 0,
  _selectDate: null
}

export default function user(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/LOG_IN_SUCESS': {
        draft.profile = action.payload.user
        draft.profileWorking = action.payload.working
        draft.notifications = action.payload.notifications
        break
      }
      case '@user/UPDATE_USER_SUCESS': {
        draft.profile = action.payload.user
        break
      }
      case '@user/GET_WORKING_HOURS_SUCESS': {
        draft.profileWorking = action.payload.user
        break
      }
      case '@working/IN_VOCATION': {
        draft.inVocation = true
        break
      }
      case '@auth/LOG_OUT': {
        draft.profile = null
        draft.profileWorking = null
        draft.inVocation = false
        draft.notifications = []
        draft.notRead = 0
        break
      }
      case '@layout/DARKMODE': {
        draft.darkmode = true
        break
      }
      case '@layout/DARKMODE_OF': {
        draft.darkmode = false
        break
      }
      case '@user/GET_NOTIFICATIONS_SUCCESS': {
        draft.notifications = action.payload.notifications
        draft.notRead = action.payload.notRead
        break
      }
      case '@user/MARK_NOTIFICATIONS_AS_READ': {
        draft.notRead = 0
        break
      }
      case '@user/SELECT_DATE': {
        draft._selectDate = action.payload.date
        break
      }
      default:
    }
  })
}
