/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { MdPerson, MdLock } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { GridLoader } from 'react-spinners'
import { get } from 'lodash'

import { onlyNumbers } from '../../utils'
import { logInRequest, logInFailed } from '../../store/modules/auth/actions'

import { ErrorMessages, StyledInput } from '../../components'
import ModalForgot from './MoldalForgot'
import { messages } from './messages'

import { loginFN, forgotPassword } from './functions'

import MemorianLogo from '../../assets/MEMORIAN.svg'

import './style.css'

export default function Login(props) {
  const [acessCode, setAcessCode] = useState('')
  const [password, setPassword] = useState('')

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [emailUser, setEmailUser] = useState('')

  const [loginError, setLoginError] = useState(false)
  const [invalidACLogin, setInvalidACLogin] = useState(false)
  const [acessCodeToForgot, setAcessCodeToForgot] = useState(false)

  const [loading, setLoading] = useState(false)

  const prevPath = get(props, 'location.state.prevPath', '/')

  const dispatch = useDispatch()

  const handleForgotPassword = async () => {
    await forgotPassword({
      acessCode,
      loginError,
      setEmailUser,
      setModalIsOpen,
      setAcessCodeToForgot,
      setInvalidACLogin,
      setLoginError
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    await loginFN({
      acessCode,
      password,
      dispatch,
      logInRequest,
      messages,
      setInvalidACLogin,
      setLoginError,
      setAcessCodeToForgot,
      setLoading,
      logInFailed,
      prevPath,
      acessCodeToForgot
    })
  }

  return (
    <div className="container-lg .container-fluid">
      <ModalForgot
        modalIsOpen={modalIsOpen}
        actionClose={() => setModalIsOpen(false)}
        actionSubmit={handleForgotPassword}
        emailUser={emailUser}
      />
      <div className="login-content col-md-10">
        <div className="register-now-and-login">
          <section className="form">
            <div className="login-title">
              <span id="login">LOGIN</span>
              <hr className="blue-bar" />
            </div>
            <form onSubmit={handleSubmit}>
              <StyledInput
                Icon={MdPerson}
                type="text"
                placeholder="Código de acesso"
                maxLength={4}
                value={acessCode}
                onChangeCapture={(e) =>
                  setAcessCode(onlyNumbers(e.target.value))
                }
                error={invalidACLogin}
                required
              />
              <StyledInput
                Icon={MdLock}
                type="password"
                placeholder="Senha"
                maxLength={4}
                value={password}
                onChangeCapture={(e) =>
                  setPassword(onlyNumbers(e.target.value))
                }
                error={loginError}
                required
              />
              {/** Check for errors and render message * */}
              {acessCodeToForgot && (
                <ErrorMessages message="Código de acesso inválido. Verifique-o e tente novamente." />
              )}
              {loginError && (
                <ErrorMessages message=" Código de acesso e/ou senha inválidos. Verifique-os e tente novamente." />
              )}
              <button
                className="forgot-button"
                type="button"
                onClick={handleForgotPassword}
              >
                Redefinir senha
              </button>

              <div className="buttons">
                <button type="submit" className="button-primary login-btn">
                  {loading ? <GridLoader color="#fff" size={5} /> : 'Entrar'}
                </button>
              </div>
            </form>
          </section>
        </div>

        <img src={MemorianLogo} alt="Memorian" className="logo" />
      </div>

      <footer>
        <a className="use-marca" href="https://usemobile.com.br/">
          Desenvolvido por Usemobile.
        </a>
      </footer>
    </div>
  )
}
