import React, { useEffect, useState } from 'react'
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdFullscreen
} from 'react-icons/md'
import PropTypes from 'prop-types'

import './style.css'

function ButtonsPDF({ actionPrev, actionNext, page, numberPages, actionZoom }) {
  const [disabledPrev, setDisabledPrev] = useState(false)
  const [disabledNext, setDisabledNext] = useState(false)

  useEffect(() => {
    const checkPages = () => {
      page === numberPages ? setDisabledNext(true) : setDisabledNext(false)
      page === 1 ? setDisabledPrev(true) : setDisabledPrev(false)
    }
    checkPages()
  }, [page, numberPages])

  return (
    <div className="pdf-navigation">
      <button
        onClick={actionPrev}
        className="button-pdf"
        disabled={disabledPrev}
      >
        <MdKeyboardArrowLeft size={25} color="#fff" />
      </button>
      <p className="number-pages">{`${page}/${numberPages}`}</p>
      <button
        onClick={actionNext}
        className="button-pdf"
        disabled={disabledNext}
      >
        <MdKeyboardArrowRight size={25} color="#fff" />
      </button>

      <button className="button-pdf" onClick={actionZoom}>
        <MdFullscreen size={25} color="#fff" />
      </button>
    </div>
  )
}

ButtonsPDF.defaultProps = {
  page: 1,
  numberPages: 0
}

ButtonsPDF.propTypes = {
  actionPrev: PropTypes.func.isRequired,
  actionNext: PropTypes.func.isRequired,
  actionZoom: PropTypes.func.isRequired,
  page: PropTypes.number,
  numberPages: PropTypes.number
}

export default ButtonsPDF
