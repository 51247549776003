/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes, { shape } from 'prop-types'

import './styles.css'

function StyledSelect2({ data, label, holder, error, ...rest }) {
  return (
    <div className="styled-select-2">
      <select
        className={
          error ? 'styled-select-2-field-error' : 'styled-select-2-field'
        }
        {...rest}
      >
        <option value="" disabled selected hidden>
          {holder}
        </option>
        {data.map((item) => (
          <option value={item.value}>
            {item.label ? item.label : item.value}
          </option>
        ))}
      </select>
    </div>
  )
}

StyledSelect2.defaultProps = {
  data: [],
  label: '',
  holder: '',
  error: false
}

StyledSelect2.propTypes = {
  data: PropTypes.arrayOf(shape([])),
  label: PropTypes.string,
  holder: PropTypes.string,
  error: PropTypes.bool
}

export default StyledSelect2
