import React, { useState, useEffect } from 'react'
import { MdChevronLeft } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import Proptypes, { shape } from 'prop-types'
// import Members from './components/Members'
import { ListFiles } from '../../../../components'

import { Container, TopInfos } from './styled-components'
import { api } from '../../../../services'
import { checkStatusCode } from '../../../../utils'

function OpenFolder({ folder, returnAction, darkmode }) {
  const dispatch = useDispatch()
  const { name } = folder

  const [data, setData] = useState([])

  useEffect(() => {
    const loadFiles = async () => {
      await api
        .get(`/file?folder=${name}`)
        .then((res) => setData(res.data.docs))
        .catch((reject) => checkStatusCode(reject, dispatch))
    }
    loadFiles()
  }, [])

  return (
    <Container>
      <button type="button" className="only-text-button" onClick={returnAction}>
        <MdChevronLeft size={25} />
        Voltar
      </button>
      <TopInfos>
        <span className="title">{name}</span>
        {/* {folder.members.length && folder.admins.length  > 0 && (
          <Members members={folder.members} admins={folder.admins} />
        )} */}
      </TopInfos>
      <ListFiles darkmode={darkmode} data={data} />
    </Container>
  )
}

OpenFolder.propTypes = {
  folder: Proptypes.objectOf(shape({})).isRequired,
  returnAction: Proptypes.func.isRequired,
  darkmode: Proptypes.bool.isRequired
}

export default OpenFolder
