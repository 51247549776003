import { toast } from 'react-toastify'
import { api, history } from '../../../services'

const verifyPass = (item1, item2) => {
  return item1 !== item2
}

const resetPassword = async ({
  password,
  confirmPassword,
  key,
  setErrorPass,
  setLoading
}) => {
  const errorPassword = verifyPass(password, confirmPassword)
  if (errorPassword) {
    setErrorPass(true)
    toast.error('Ops...Encontramos um problema por aqui, tente novamente.')
    return
  }
  setLoading(true)
  await api.post('/reset', { password, key }).then(
    (response) => {
      history.push('/login')
      toast.success('Senha alterada com sucesso!')
    },
    (error) => {
      toast.error('Ops...Encontramos um problema por aqui, tente novamente.')
    }
  )
  setLoading(false)
}

export { resetPassword }
